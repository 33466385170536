<div class="help-center">
    <div class="container-fluid">
        <header class="help-center__header">
            <div class="container-fluid">
                <div class="help-center__header-wrapper">
                    <h1 class="help-center__title">Centro de ayuda</h1>
                    <div class="form-group">
                        <label class="help-center__subtitle" for="help-center-search">Hola ¿en qué podemos ayudarte?</label>
                        <div class="input-group input-group-lg">
                            <input type="text" class="form-control" id="help-center-search" (input)="searchQuestions($event.target.value)" placeholder="Escribe tu pregunta o busca por palabra clave">
                            <div class="input-group-append">
                              <button app-button styling="link" icon="search"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <section class="faqs">
            <h1 class="help-center__title">Preguntas Frecuentes</h1>
            <div class="faq-group" *ngFor="let category of categoriesFiltered; let i = index;">
                <h2 class="faq-group__title">{{ category.name }}</h2>
                <ngb-accordion #acc="ngbAccordion" [activeIds]="activeQuestions">
                    <ngb-panel *ngFor="let q of category.questions" title="{{ q.question }}" id="ngb-panel-{{ q.id }}">
                        <ng-template ngbPanelContent>
                            <div [innerHtml]="q.answer"></div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </section>

        <section class="video-tutorial" *ngIf="showVideoTutorial">
            <h1 class="help-center__title">Video tutoriales</h1>
            <div class="video-tutorial__wrapper" *ngFor="let video of videoTutoriales;">
                <div class="video-tutorial__group">
                    <a href="javascript:void()" class="video-tutorial__title" (click)="openVideo(video)" >
                        <img src="{{ video.pic }}" alt="Video tutoriales" width="80">
                    </a>
                    <div class="video-tutorial__content">
                      <a href="javascript:void()" class="video-tutorial__title" (click)="openVideo(video)">{{ video.title }}</a>
                      <p class="video-tutorial__description"></p>
                    </div>
                </div>
            </div>
        </section>

        <aside class="contactform" [ngClass]="{'contactform--loading': loading}">
            <div class="contactform__wrapper">
                <div *ngIf="!contactFormSubmited">
                    <h2 class="help-center__subtitle">¿Quieres comunicarte con nosotros?</h2>
                    <div class="help-center__user-contact-data" *ngIf="!hiddenInputs">
                        <div>{{currentUser.name}} {{currentUser.lastName}}</div>
                        <div>{{currentUser.fiscalId | rut }}</div>
                        <div>{{currentUser.email}}</div>
                        <!-- Estos datos deben ser cargados a través del servicio companySelected -->
                        <div class="help-center__user-contact-data-company">{{ companyNameRefresh }}</div>
                        <div class="help-center__user-contact-data-company">{{ companyrefresh | rut }}</div>
                    </div>
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmitContactForm()">
                        <div class="form-group">
                            <label>Selecciona un motivo:</label>
                            <div class="help-center__contact-type">
                                <div class="form-check form-check-inline">
                                    <input type="radio" class="form-check-input" id="contact_type_consulta" value="consulta" formControlName="subject">
                                    <label class="form-check-label" for="contact_type_consulta"><span></span>Consulta</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input type="radio" class="form-check-input" id="contact_type_solicitud" value="solicitud" formControlName="subject">
                                    <label class="form-check-label" for="contact_type_solicitud"><span></span>Solicitud</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input type="radio" class="form-check-input" id="contact_type_reclamo" value="reclamo" formControlName="subject">
                                    <label class="form-check-label" for="contact_type_reclamo"><span></span>Reclamo</label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="hiddenInputs">
                            <div class="form-group">
                                <label for="name">Nombre *</label>
                                <input type="text" id="name" name="name" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" required>
                            </div>
                            <div class="form-group">
                                <label for="fiscalId">Rut *</label>
                                <input type="text" id="fiscalId" name="fiscalId" class="form-control" formControlName="fiscalId" [ngClass]="{ 'is-invalid': submitted && f.fiscalId.errors }" required>
                            </div>
                            <div class="form-group">
                                <label for="email">Email *</label>
                                <input type="email" id="email" name="email" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" required>
                            </div>
                            <div class="form-group">
                                <label for="company_name">Nombre de la empresa</label>
                                <input type="text" id="company_name" name="company_name" class="form-control" formControlName="company_name" [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }" required>
                            </div>
                            <div class="form-group">
                                <label for="company_fiscalId">Rut de tu empresa</label>
                                <input type="text" id="company_fiscalId" name="company_fiscalId" class="form-control" formControlName="fiscalId" [ngClass]="{ 'is-invalid': submitted && f.company_fiscalId.errors }" required>
                            </div>
                        </div>
                        <div class="form-group mb-1">
                            <textarea id="message" name="message" rows="5" class="form-control" formControlName="message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }" placeholder="Mensaje" required></textarea>
                        </div>
                        <div class="form-group contactform__upload-file">
                            <input (change)="selecctedFile($event)" class="file-input" id="document" type="file">
                            <label for="document">Adjuntar documento</label>
                        </div>
                        <div class="text-center mt-2">
                            <button app-button size="lg" type="submit" [loading]="loading" class="help-center__submit" [disabled]="!contactForm.valid || !isValid">
                                Enviar
                            </button>
                        </div>
                    </form>
                </div>
                <div class="contactform__response" *ngIf="contactFormSubmited">
                    <h2 class="help-center__subtitle">Tu mensaje ha sido enviado</h2>
                    <p>Recibirás una respuesta en un plazo máximo de 48 horas.</p>
                </div>
            </div>
        </aside>
    </div>
</div>
