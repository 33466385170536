<header class="tracking__header">
  <span class="tracking__label">ID del pedido</span>
  <h3 class="tracking__title">{{ tracking.idOrderPlataform }}</h3>
</header>
<ul class="tracking-steps" *ngIf="tracking.step !== 5">
  <li class="tracking-step" [ngClass]="{ 'tracking-step--completed': step.step < tracking.step, 'tracking-step--active': step.step <= tracking.step }" *ngFor="let step of trackingSteps;">
    <span class="tracking-step__index">Estado {{ step.step }}</span>
    <span class="tracking-step__name">{{ step.name }}</span>
    <span class="tracking-step__marker">
      <app-icon icon="check-tracking" *ngIf="step.step <= tracking.step"></app-icon>
    </span>
  </li>
</ul>
<div class="tracking-step tracking-step--canceled" *ngIf="tracking.step === 5">
  <span class="tracking-step__index">Estado 1</span>
  <span class="tracking-step__name">Este pedido se encuentra cancelado</span>
  <span class="tracking-step__marker">
    <app-icon icon="close-thick"></app-icon>
  </span>
</div>