import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { RutValidator } from 'ng9-rut';
import { countries } from 'node_modules/countries-list';
import { BeneficiaryBenefits } from 'src/app/core/model/beneficiary-benefits.model';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-form-beneficiary-benefits',
  templateUrl: './form-beneficiary-benefits.component.html',
  styleUrls: ['./form-beneficiary-benefits.component.scss']
})
export class FormBeneficiaryBenefitsComponent implements OnInit, OnChanges {
  @Input() editMode: false;
  @Input() beneficiaries: any = [];
  @Input() beneficiary: any = {};
  @Output() changeBeneficiary: EventEmitter<BeneficiaryBenefits> = new EventEmitter();

  model: BeneficiaryBenefits = new BeneficiaryBenefits();
  isUnique = true;
  isUniqueEmail = true;
  countries: any;
  branchOffices: any[];
  
  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.countries = this.getCountries();
    this.model.countryDial = '56';
    // this.branchOffices = this.dataService.getBranchOffices();
    // this.branchOffices.forEach(bo => {
    //   this.branchOffice.push({
    //     name: bo.address,
    //     id: bo.id
    //   });
    //   bo.costCenters.forEach(cc => {
    //     this.costCenter.push({
    //       name: cc.name,
    //       id: cc.id
    //     });
    //   });
    // });
  }

  onSubmit(beneficiary) {
    const entries = Object.entries(beneficiary);
    for (const [key, value] of entries) {
      this.model[key] = value;
    }
    this.changeBeneficiary.emit(this.model);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.beneficiary ) {
      this.model = new BeneficiaryBenefits(changes.beneficiary.currentValue);
    }
  }

  checkUnique(rut) {
    const rutList = this.beneficiaries.map(be => be.fiscalId);
    const santizeRut = rut.replace(/[^0-9kK]+/g, '').toUpperCase();
    this.isUnique = rutList.includes(santizeRut) ? false : true;
  }

  checKUniqueEmail(email) {
      const emailList = this.beneficiaries.map(be => be.email);
      const santizeEmail = email.replace(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, '').toLowerCase();
      this.isUniqueEmail = emailList.filter(e => e === email).length > 0 ? false : true;
    }



    getCountryCode(countryDial: string) {
        return Object.keys(countries).filter(key => countries[key].phone === countryDial)[0];
      }

    getCountries() {
        return Object.keys(countries).map(key => countries[key]).sort((a, b) => {
          return a.name - b.name;
        });
      }


    setCountryCode(countryDial: string) {
        this.model.countryDial = countryDial;
      }

}
