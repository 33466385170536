import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';

const routes = {
  refresh: env.ciam.baseApi + env.ciam.refresh
};

@Injectable({
  providedIn: 'root'
})
export class CiamService {

  constructor(private httpClient: HttpClient) { }

  refreshToken(token: any): Observable<any> {
    return this.httpClient
      .post<any>(routes.refresh, JSON.stringify({ token }), {
        observe: 'response'
      });
  }

  isValidToken(token: any) {
    if (token === null || token === undefined || token === '' || token === 'null') {
      return false;
    }
    return true;
  }
}
