import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { DataService } from '../data.service';
import { HttpParams } from '@angular/common/http';
import { UpdateBranchesCompany } from '../../model/update-branches.model';

const routes = {
  getExecutive: (fiscalId: string) => `/company/executive/${fiscalId}`,
  getMealCompayAccessPlatform: '/company-card/access/platform',
  getClientService: (fiscalId: string, serviceId: number) => `/company-card/client-service?fiscalId=${fiscalId}&serviceId=${serviceId}`,
  getCommercialGenerationDocument: (agreementId: number) => `/agreement/commercial-generation-moment-type/${agreementId}`,
  getdefaultingAndVip: (fiscalId: string) => `/company/defaulting/${fiscalId}`,
  getCustomerSegmentation: (fiscalId: string, isPublic: boolean) => `/company/segmentation?fiscalId=${fiscalId}&isPublic=${isPublic}`,
  getCompanyCondition:(fiscalId: string, clientServId: Number) => `/company-condition/condition?fiscalId=${fiscalId}&clientServId=${clientServId}`,
  getBranchesCompanyCondition:(fiscalId: string,fiscalIdInterlocutor:string, clientServId: Number,userProfile:String) => `/company-condition/branches-all?fiscalId=${fiscalId}&fiscalIdInterlocutor=${fiscalIdInterlocutor}&clientServId=${clientServId}&userProfile=${userProfile}`,
  updateBranchesCompany: '/company-condition/update-data',
  updateCompanyData: '/company-data/update-data',  
  getCompanyData: (companyFiscalId: string) => `/company-data/get?companyFiscalId=${companyFiscalId}`,
  goToModificationHistory: (companyFiscalId: string) => `/company-data/goToModificationHistory/${companyFiscalId}`,
};

@Injectable({
  providedIn: 'root'
})

export class CompanyService {

  constructor(private apiService: ApiService, private dataService: DataService) { }

  getExecutive(fiscalId: string) {
    return this.apiService.get(
      routes.getExecutive(fiscalId));
  }

  getMealCompayAccessPlatform(companyFiscalId: any) {
    let params = new HttpParams();

    if (companyFiscalId && companyFiscalId !== null) {
      params = params.set('companyFiscalId', companyFiscalId);
    }

    return this.apiService.get(routes.getMealCompayAccessPlatform, params);
  }

  getClientService(fiscalId: string, serviceId: number) {
    return this.apiService.get(
      routes.getClientService(fiscalId, serviceId));
  }


  getCommercialGenerationDocument(agreementId: number) {
    return this.apiService.get(
      routes.getCommercialGenerationDocument(agreementId));
  }

  getCompanyCondition(fiscalId: string, clientServId: Number) {
    return this.apiService.get(
      routes.getCompanyCondition(fiscalId, clientServId));
  }

  getdefaultingAndVip(fiscalId: string) {
    return this.apiService.get(
      routes.getdefaultingAndVip(fiscalId));
  }

  getCustomerSegmentation(fiscalId: string, isPublic: boolean) {
    return this.apiService.get(
      routes.getCustomerSegmentation(fiscalId, isPublic));
  }

  getBranchesCompanyCondition(fiscalId: string,fiscalIdInterlocutor:string, clientServId: Number,userProfile:String){
    return this.apiService.get(
      routes.getBranchesCompanyCondition(fiscalId,fiscalIdInterlocutor,clientServId,userProfile));
  }

  updateBranchesCompany(updateBranchesCompany:any){
    return this.apiService.post(routes.updateBranchesCompany,updateBranchesCompany);
  }

  getCompanyData(fiscalId: string) {
    return this.apiService.get(
      routes.getCompanyData(fiscalId));
  }
  updateCompanyData(data: any){
    return this.apiService.post(routes.updateCompanyData, data);
  }

  goToModificationHistory(fiscalId: string) {
    return this.apiService.get(routes.goToModificationHistory(fiscalId));
  }
}
