<footer class="app-footer">
  <div class="container-fluid">
      <div class="app-footer__body">
        <span class="app-logo">
            <img alt="Sodexo" src="assets/images/sodexo_white.png" class="logo logo-sodexo" >
        </span>
      </div>

      <ul class="footer-contact">
        <li class="footer-contact__item">
          <a href="https://www.sodexo.cl/politicas/" target="_blank" rel="noopener" class="footer-link">Políticas de Sodexo BRS</a>
        </li>
        <li class="footer-contact__item">
          <a href="https://www.sodexo.cl/politica-de-cookies-sodexo-chile/" target="_blank" rel="noopener" class="footer-link">Políticas de cookies</a>
        </li>
        <li class="footer-contact__item">
          <a href="#" class="footer-link ot-sdk-show-settings">Configuración de cookies</a>
        </li>
        <li class="footer-contact__item separator">Pérez Valenzuela 1635, Providencia, Santiago</li>
      </ul>
  </div>
</footer>
