<div class="modal-content video-modal">
  <div class="modal-header">
      <h5 class="modal-title">{{ video.title }}</h5>
      <p>{{ video.description }}</p>
      <button type="button" (click)="closeModal()" class="close" data-dismiss="modal"><app-icon icon="cancel"></app-icon></button>
  </div>
  <video height="500" controls>
    <source [src]="safeVideoUrl" type="video/mp4">
    Tu navegador no soporta la reproducción de video
  </video>
</div>
