<div class="upload-file">
  <div class="upload-file__dropzone" *ngIf="status == 'empty'">
    <input type="file" name="files[]" id="upload-file" class="upload-file__control" value="Subir Archivo" (change)="importFile(inputFile.files)" [accept]="accept" [multiple]="multiple" #inputFile>
    <button app-button type="button" icon="plus" styling="secondary" (click)="inputFile.click()">{{ buttonText }}</button>
    <span class="form-text">
      <ng-content></ng-content>
    </span>
  </div>
  <div class="upload-file__response" *ngIf="status == 'loading'">
    <div class="upload-file__content">
      <span class="icon-loading"></span>
      <span class="form-text">Validando archivo</span>
    </div>
  </div>
  <div class="upload-file__response" *ngIf="status == 'error'">
    <div class="upload-file__content">
      <span class="form-text mb-3">
        <app-icon icon="error"></app-icon>
        Se han identificado algunos errores.
      </span>
      <input type="file" name="files[]" id="upload-file-replace" class="upload-file__control" value="Subir Archivo" (change)="importFile(inputFileRetry.files)" [accept]="accept" [multiple]="multiple" #inputFileRetry>
      <button app-button type="button" icon="plus" styling="secondary" (click)="inputFileRetry.click()">{{ buttonRetryText }}</button>
    </div>
    <ul class="upload-file__errors" *ngIf="errors.length > 0">
      <li *ngFor="let error of errors | slice:0:5;">{{ error }}</li>
      <li *ngIf="errors.length > 5">
        {{ errors.length - 5 }} Errores mas.
        <a *ngIf="downloadFileErrors" (click)="downloadFile()" href="javascript:void(0)">
          {{ downloadFileErrors }}
        </a>
      </li>
    </ul>
  </div>
</div>
