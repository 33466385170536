import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (event.headers.get('content-disposition')) {
          const contentDispositionHeader = event.headers.get('content-disposition');
          const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
          localStorage.setItem('nameFile', result.replace(/"/g, ''));
        }
      }
      return event;
    }));
  }

}
