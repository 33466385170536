import {CostCenter} from './costcenter.model';

export class BranchOffice {
  id: number;
  address: string;
  communeId: number;
  commune: string;
  stateId: number;
  state: string;
  nBeneficiaries: number;
  totalAmount: number;
  dailyAmount: number;
  selected: boolean;
  showCostCenters: boolean;
  deliveryPointId: number;
  deliveryPointDescription: string;
  costCenters: CostCenter[];
  deliveryDate: string;
  newBeneficiaries: number;
  newCharges: number;
  hasNewBeneficiaries: boolean;
  orderId: number;
  name?: string;
  distribute?: number;
  balance?: number;
}

