<ng-container
  *ngTemplateOutlet="optionsTemplate; context:{ list: options, parent: null }"
></ng-container>
<ng-template #optionsTemplate let-list="list" let-parent="parent">
  <ul class="checkbox-list-component">
    <li *ngFor="let item of list">
      <label class="form-check">
        <input
          (change)="clickOption($event, item.children, parent, list)"
          [checked]="selected.indexOf(item.id.toString()) > -1"
          class="form-check-input"
          [value]="item.id"
          type="checkbox"
        />
        <span class="form-check-label">{{ item.value }}</span>
      </label>
      <ng-container *ngIf="item.children">
        <ng-container
          *ngTemplateOutlet="optionsTemplate; context:{ list: item.children, parent: item.id }"
        ></ng-container>
      </ng-container>
    </li>
  </ul>
</ng-template>
