import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpParams } from '@angular/common/http';
import { DataService } from '../data.service';
import { EmissionLoadOrder } from '../../model/emissionloadorder';
import { UpdateOrderStatus } from '../../model/updateOrderStatus.model';
import { EmissionOrderDeliveryDate } from '../../model/EmissionOrderDeliveryDate';
import { CreateMealOrderModel } from 'src/app/core/model/createMealOrder.model';
import {OrderMovementModel} from "../../model/meal/order/orderMovementModel";
import {OrderRequestMovementModel} from "../../model/meal/order/requestMovement";
import { ConfirmationRequestModel } from 'src/app/core/model/confirmationRequest.model';
import { OrderRequestModel } from 'src/app/core/model/orderRequest.model';
import { OrderPrepareModel } from 'src/app/core/model/orderPrepare.model';

const routes = {
  mealOrders: () => `/meal-orders/company`,
  createNewMealOrder: () => `/meal-orders`,
  updateMealOrder: (orderId: number) => `/meal-orders?orderId=${orderId}`,
  cancelMealOrder: () => `/meal-orders/cancel`,
  addCardsToOrder: (companyFiscalId: string) => `/meal-orders/card-items/${companyFiscalId}/customized`,
  updateMealStatusOrder: () => `/meal-orders/update-status`,
  processCardOrder: (companyFiscalId: string) => `/meal-orders/${companyFiscalId}/customized`,
  balanceRecovery: () => `/meal-orders/balance-recovery`,
  loadBalance: (billingCenterId: number, branchId: number, userProfile: number) =>  `/meal-orders/load-balance?billingCenterId=${billingCenterId}&branchId=${branchId}&userProfile=${userProfile}`,
  onlineDistribution: () => `/meal-orders/online-distribution`,
  onlineDistributionMassive: () => `/meal-orders/online-distribution-massive`,
  orderMovement: () =>`/meal-orders/order-movement`,
  addCacheMultipleOrder: (companyFiscalId: string,interlocutorFiscalId:string) => `/meal-orders/add-cache-multiple-order?companyFiscalId=${companyFiscalId}&interlocutorFiscalId=${interlocutorFiscalId}`,
  prepareOrder: (companyFiscalId: string) => `/meal-orders/${companyFiscalId}/prepare-order`,
  getGraphInformation: (graphType: string, companyFiscalId: string, initDate: string, endDate: string) =>
    `/meal-reports/graph-information?graphType=${graphType}&companyFiscalId=${companyFiscalId}&initDate=${initDate}&endDate=${endDate}`,
  downloadExcelMassive: (companyFiscalId: String, fiscalIdInterlocutor: String, serviceId: number, userProfile: number) => `/meal/balance-manager/download-excel-massive?companyFiscalId=${companyFiscalId}&fiscalIdInterlocutor=${fiscalIdInterlocutor}&serviceId=${serviceId}&userProfile=${userProfile}`,
  uploadExcelMassive: (companyFiscalId: String, userProfile: number, interlocutorFiscalId: String) => `/meal/balance-manager/upload-excel-massive?companyFiscalId=${companyFiscalId}&userProfile=${userProfile}&interlocutorFiscalId=${interlocutorFiscalId}`,
  countOrders: () => `/meal-orders/count`,
};

@Injectable({
  providedIn: 'root'
})
export class MealOrderService {
  uploadExcelMassive(file: File, companyFiscalId: String, userProfile: number, interlocutorFiscalId: String) {
    const body = new FormData();
    // Add file content to prepare the request
    body.append('uploadFile', file);
    return this.apiService.postMultipartFile(routes.uploadExcelMassive(companyFiscalId,userProfile,interlocutorFiscalId), body);
  }

  downloadExcelMassive(companyFiscalId: String, fiscalIdInterlocutor: String, serviceId: number, userProfile: number) {
    return this.apiService.download(routes.downloadExcelMassive(companyFiscalId,fiscalIdInterlocutor,serviceId,userProfile));
  }


  constructor(private apiService: ApiService, private dataService: DataService) { }

  getMealOrders(orderFromUserRequest : any) {
    return this.apiService.post(routes.mealOrders(),orderFromUserRequest);
  }

  createNewCardsOrder(createOrderinfo: any) {
    return this.apiService.post(routes.createNewMealOrder(), createOrderinfo);
  }

  updateMealOrder(updateOrderData: any, orderId: number) {
    return this.apiService.put(routes.updateMealOrder(orderId), updateOrderData);
  }

  cancelMealOrder(companyFiscalId: string, orderRequestId: number) {
    const data = {
      companyFiscalId,
      orderRequestId
    };
    return this.apiService.put(routes.cancelMealOrder(), data);
  }

  addCardsToOrder(companyFiscalId: string,confirmation: ConfirmationRequestModel) {
    return this.apiService.post(routes.addCardsToOrder(companyFiscalId), confirmation);
  }

  updateMealStatusOrder(updateOrderStatus: UpdateOrderStatus) {
    return this.apiService.put(routes.updateMealStatusOrder(), updateOrderStatus);
  }

  processCardOrder( companyFiscalId: string,orderRequestModel: OrderRequestModel) {
    return this.apiService.post(routes.processCardOrder(companyFiscalId), orderRequestModel);
  }

  balanceRecovery(data: any) {
    return this.apiService.post(routes.balanceRecovery(), data);
  }

  loadBalance(data: any, billingCenterId: number, branchId: number, userProfile: number) {
      return this.apiService.post(routes.loadBalance(billingCenterId, branchId, userProfile), data);
    }

  onlineDistribution(createMealOrderModel: CreateMealOrderModel) {
      return this.apiService.post(routes.onlineDistribution(), createMealOrderModel);
    }

  onlineDistributionMassive(createMealOrderModel: CreateMealOrderModel) {
    return this.apiService.post(routes.onlineDistributionMassive(), createMealOrderModel);
  }

  getAllOrderMovements(orderRequestMovementModel: OrderRequestMovementModel) {
      return this.apiService.post(routes.orderMovement(), orderRequestMovementModel);
  }
  addCacheMultipleOrder(companyFiscalId: string,interlocutorFiscalId:string,data:any) {
    return this.apiService.post(routes.addCacheMultipleOrder(companyFiscalId,interlocutorFiscalId), data);
  }

  prepareOrder(companyFiscalId: string,orderPrepareModel: OrderPrepareModel) {
    return this.apiService.post(routes.prepareOrder(companyFiscalId), orderPrepareModel);
  }
  getGraphInformation(graphType: string, companyFiscalId: string, initDate: string, endDate: string) {
      return this.apiService.get(routes.getGraphInformation(graphType, companyFiscalId, initDate, endDate));
  }

  getMealCountOrders(orderFromUserRequest : any) {
    return this.apiService.post(routes.countOrders(),orderFromUserRequest);
  }
}
