import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-prev-next',
  templateUrl: './prev-next.component.html',
  styleUrls: ['./prev-next.component.scss']
})
export class PrevNextComponent implements OnInit {

  @Input() prevBtnTxt: string;
  @Input() nextIsEnabled: boolean;
  @Input() nextBtnTxt: string;
  @Input() hasCheckbox = false;
  @Input() nextSpanTxt = '';
  @Input() checkboxTxt = '¿Estás seguro que quieres cursar este pedido?';

  @Output() prevBtnOnClick = new EventEmitter();
  @Output() nextBtnOnClick = new EventEmitter();
  @Output() checkboxChange = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  handleClickPrevBtn() {
    this.prevBtnOnClick.emit();
  }

  handleClickNextBtn() {
    this.nextBtnOnClick.emit();
  }

  handleCheck() {
    this.checkboxChange.emit();
  }
}
