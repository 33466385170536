<ng-container *ngIf="data | async; else loading">
  <ng-container *ngIf="results.length > 0; else empty">
    <ng-container *ngIf="localType === 'pie'">
      <ngx-charts-pie-chart
        (window:resize)="onResize($event)"
        [results]="results"
        [view]="view"
        [scheme]="colorScheme"
        [gradient]="gradient"
        [labels]="showLabels">
        <ng-template #tooltipTemplate let-model="model">
          <span class="tooltip-label">{{ model.name }}</span>
          <span class="tooltip-val">{{ formatValue ? formatValue(model.value) : model.value }}</span>
        </ng-template>
    </ngx-charts-pie-chart>
    </ng-container>
    <ng-container *ngIf="localType === 'horizontal'">
      <ngx-charts-bar-horizontal
        (window:resize)="onResize($event)"
        [results]="results"
        [view]="view"
        [scheme]="colorScheme"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel">
        <ng-template #tooltipTemplate let-model="model">
          <span class="tooltip-label">{{ model.name }}</span>
          <span class="tooltip-val">{{ formatValue ? formatValue(model.value) : model.value }}</span>
        </ng-template>
      </ngx-charts-bar-horizontal>
    </ng-container>
    <ng-container *ngIf="localType === 'vertical'">
      <ngx-charts-bar-vertical
        (window:resize)="onResize($event)"
        [results]="results"
        [view]="view"
        [scheme]="colorScheme"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel">
        <ng-template #tooltipTemplate let-model="model">
          <span class="tooltip-label">{{ model.name }}</span>
          <span class="tooltip-val">{{ formatValue ? formatValue(model.value) : model.value }}</span>
        </ng-template>
      </ngx-charts-bar-vertical>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #loading>
  <div class="chart-loading">
    <span class="icon-loading"></span>
    <span>Cargando información</span>
  </div>
</ng-template>
<ng-template #empty>
  <div class="chart-empty">
    <span class="icon-empty-chart"></span>
    <span>Sin resultados</span>
  </div>
</ng-template>
