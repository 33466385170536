<div class="modal-content">
    <div class="modal-body text-center">
      <h2 class="modal-title mb-2">¿Estás seguro que quieres salir?</h2>
      <ng-container *ngIf="totalDraftOrders >= 5">
        <p class="mb-5 lead">Has alcanzado el número máximo de pedidos en borrador, al salir éste será anulado</p>
        <div class="mb-3">
          <button app-button (click)="discardOrder()">Salir del pedido</button>
        </div>
        <button app-button type="button" styling="link" (click)="cancelOrder()">Continuar con el pedido</button>
      </ng-container>
      <ng-container *ngIf="totalDraftOrders < 5">
        <p class="mb-5 lead">Puedes guardar este pedido como borrador o anularlo</p>
        <div class="mb-3">
            <button app-button class="mr-1" (click)="saveDrafOrder()">Guardar pedido</button>
            <button app-button class="ml-1" styling="secondary" (click)="discardOrder()">Anular pedido</button>
        </div>
        <button app-button type="button" styling="link" (click)="cancelOrder()">Cancelar</button>
      </ng-container>
      <button type="button" (click)="cancelOrder()" class="close">
        <app-icon icon="cancel"></app-icon>
      </button>
    </div>
</div>
