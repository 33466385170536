import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.scss']
})
export class OrderCardComponent implements OnInit {

  @Input() title: string;
  @Input() value: string;
  @Input() statusName: string;
  @Input() statusClass: string;
  @Input() type: string;
  @Input() subtitle: string;
  @Input() amount: string;
  @Input() amountTitle: string;
  @Input() date: string;
  @Input() expDate: string;
  @Input() data: any[];
  @Input() isDraft = false;
  @Input() continueOrder = false;
  @Input() cancelOrder= false;

  @Output() readOnClick = new EventEmitter();
  @Output() editOnClick = new EventEmitter();
  @Output() cancelOnClick = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  handleClickReadBtn() {
    this.readOnClick.emit();
  }

  handleClickEditBtn(event) {
    this.editOnClick.emit(event);
  }

  handleClickCancelBtn(event) {
    this.cancelOnClick.emit(event);
  }
}
