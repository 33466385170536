import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dropdown-date',
  templateUrl: './dropdown-date.component.html'
})
export class DropdownDateComponent implements OnInit {
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  cleanSubject: Subject<void> = new Subject<void>();

  @Input() placement: string;

  @Output() from = new EventEmitter<NgbDate | null>();
  @Output() to = new EventEmitter<NgbDate | null>();

  constructor() {
    this.fromDate = null;
    this.toDate = null;
  }

  ngOnInit() {
  }

  emitCleanToChild() {
    this.cleanSubject.next();
  }

  onDateSelect(date: NgbDate) {
    if ( ! this.fromDate && !this.toDate ) {
      this.fromDate = date;
    } else if ( this.fromDate && !this.toDate && date.after(this.fromDate) ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.emit();
  }

  resetDate() {
    this.toDate = null;
    this.fromDate = null;
    this.emitCleanToChild();
    this.emit();
  }

  emit() {
    this.from.emit( this.fromDate );
    this.to.emit( this.toDate );
  }
}
