import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DraftOrder } from '../model/draftOrder.model';
import { ClientServiceMealModel } from '../model/meal/clientService.model';
import { DocumentReferenceTypeModel } from '../model/documentReferenceType.model';
import { Beneficiary } from '../model/beneficiary.model';
import { BeneficiaryBenefits } from 'src/app/core/model/beneficiary-benefits.model';
import { CompanyCustom } from 'src/app/core/model/corepass/companyCustom.model';
import {OrderMovementModel} from "../model/meal/order/orderMovementModel";


@Injectable({
  providedIn: 'root'
})

export class DataService {
  private companySelectedSubject = new Subject<string>();
  companyIdObs = this.companySelectedSubject.asObservable();

  private companySelectedNameSubject = new Subject<string>();
  companyNameObs = this.companySelectedNameSubject.asObservable();

  // private navigationMenu: any;
  private navigationMenu = new BehaviorSubject<any>(undefined);

  private fiscalIdSelected: any;
  private customerDTO: any;
  private orderId: number;
  private proceedingType: any;
  private currentUser: any;
  private orderDTO: any;
  private companySelected: any;
  private orderConfirmationOrDone: boolean;
  private quotationCartSize: any = new BehaviorSubject(0);
  private proceedingTypeId: any;
  private proceedingActQuestion: boolean;
  private currentAct: any;
  private nroPedido: number;
  private totalAmount: number;
  private activeOrder: any;
  private userProfile: any;
  private userProfileModules: any;
  private users: any;
  private lastUserChangeOrder: any;
  private branchOffices: any;
  private executive: any;
  private restriction: any;
  private onlineOrder: boolean;
  private loadBalanceOrder: any;
  private amountOrder: number;
  private billingCenter: any;
  private clientAccountBalance: any;
  private originViewOrder: any;
  private onlineOrderId: number;
  private companyCustom: CompanyCustom;
  private segment: any;
  private dataBeneficiaryExport: any;
  private nroPedidoZero: number;
  private totalAmountZero: number;
  private orderTypeCustom : number;
  private additionalEmail :string;
  private initialiseInvite : boolean;
  private orders: any;
  constructor() { }

  setToken(token: any) {
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  setProceedingType(proceedingType: any) {
    this.proceedingType = proceedingType;
    if (proceedingType == null) {
      localStorage.setItem('proceedingType', null);
      return;
    }
    localStorage.setItem('proceedingType', JSON.stringify(this.proceedingType));
  }

  getProceedingType() {
    this.proceedingType = JSON.parse(localStorage.getItem('proceedingType'));
    return this.proceedingType;
  }

  setProceedingTypeId(proceedingTypeId: any) {
    this.proceedingTypeId = proceedingTypeId;
    if (proceedingTypeId == null) {
      localStorage.setItem('proceedingTypeId', null);
      return;
    }
    localStorage.setItem('proceedingTypeId', JSON.stringify(this.proceedingTypeId));
  }

  getProceedingTypeId() {
    this.proceedingTypeId = JSON.parse(localStorage.getItem('proceedingTypeId'));
    return this.proceedingTypeId;
  }

  setCustomerDTO(customerDTO: any) {
    this.customerDTO = customerDTO;
    if (customerDTO == null) {
      localStorage.setItem('currentCustomer', null);
      return;
    }
    localStorage.setItem('currentCustomer', JSON.stringify(this.customerDTO));
  }

  getCustomerDTO() {
    this.customerDTO = JSON.parse(localStorage.getItem('currentCustomer'));
    return this.customerDTO;
  }

  setOrderId(orderId: number) {
    this.orderId = orderId;
  }

  getOrderId() {
    return this.orderId;
  }

  setOrder(orderDTO: any) {
    this.orderDTO = orderDTO;
    if (orderDTO == null) {
      localStorage.setItem('orderDTO', null);
      return;
    }
    localStorage.setItem('orderDTO', JSON.stringify(this.orderDTO));
  }

  getOrder() {
    this.orderDTO = JSON.parse(localStorage.getItem('orderDTO'));
    return this.orderDTO;
  }

  setCurrentUser(currentUser: any) {
    this.currentUser = currentUser;
    localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
  }

  getCurrentUser() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.currentUser;
  }

  getCompany(serviceId: number) {
    return this.currentUser.companies.find(c => c.agreements.find(a => a.serviceId === serviceId));
  }

  getCompanySelected() {
    this.companySelected = JSON.parse(localStorage.getItem('companySelected'));
    return this.companySelected;
  }

  setCompanySelected(company: any) {
    this.companySelected = company;
    if (company == null) {
      localStorage.setItem('companySelected', null);
      return;
    }
    localStorage.setItem('companySelected', JSON.stringify(this.companySelected));
  }

  getCompanies() {
    return this.currentUser.companies;
  }

  getQuotationCartSize() {
    return this.quotationCartSize.asObservable();
  }

  setQuotationCartSize(step) {
    if (step == null) {
      return;
    }
    this.quotationCartSize.next(step);
  }

  setOrderConfirmationOrDone(orderConfirmationOrDone) {
    this.orderConfirmationOrDone = orderConfirmationOrDone;
    if (orderConfirmationOrDone == null) {
      localStorage.setItem('orderConfirmationOrDone', null);
      return;
    }
    localStorage.setItem('orderConfirmationOrDone', JSON.stringify(this.orderConfirmationOrDone));
  }

  getOrderConfirmationOrDone() {
    this.orderConfirmationOrDone = JSON.parse(localStorage.getItem('orderConfirmationOrDone'));
    return this.orderConfirmationOrDone;
  }

  setCurrentAct(currentAct: any) {
    this.currentAct = currentAct;
    if (currentAct == null) {
      localStorage.setItem('currentAct', null);
      return;
    }
    localStorage.setItem('currentAct', JSON.stringify(this.currentAct));
  }

  getCurrentAct() {
    this.currentAct = JSON.parse(localStorage.getItem('currentAct'));
    return this.currentAct;
  }

  setProceedingActQuestion(proceedingActQuestion: boolean) {
    this.proceedingActQuestion = proceedingActQuestion;
  }

  getProceedingActQuestion() {
    return this.proceedingActQuestion;
  }

  setLastOrderId(lastOrderId: number) {
    localStorage.setItem('lastOrderId', JSON.stringify(lastOrderId));
  }

  getLastOrderId() {
    return JSON.parse(localStorage.getItem('lastOrderId'));
  }

  getFiscalIdSelected() {
    return this.fiscalIdSelected;
  }

  setFiscalIdSelected(fiscalId: number) {
    this.fiscalIdSelected = fiscalId;
  }

  getSendMailOtherUser() {
    const dataSendMailOtherUser = {
      nroPedido: this.nroPedido,
      totalAmount: this.totalAmount
    };
    return dataSendMailOtherUser;
  }

  setSendMailOtherUser(nroPedido: number, totalAmount: number) {
    this.nroPedido = nroPedido;
    this.totalAmount = totalAmount;
  }

  getActiveOrder() {
    this.activeOrder = JSON.parse(localStorage.getItem('activeOrder'));
    return this.activeOrder;
  }

  setActiveOrder(activeOrder: any) {
    this.activeOrder = activeOrder;
    localStorage.setItem('activeOrder', JSON.stringify(this.activeOrder));
  }

  setService(serviceName: any) {
    localStorage.setItem('serviceName', serviceName);
  }

  getService() {
    return localStorage.getItem('serviceName');
  }

  setLoginDate(date = new Date()) {
    localStorage.setItem('date', date.toString());
  }

  getLoginDate() {
    return localStorage.getItem('date');
  }

  setUserProfile(userProfile) {
    this.userProfile = userProfile;
    localStorage.setItem('profiles', JSON.stringify(userProfile));
  }

  getUserProfile() {
    this.userProfile = JSON.parse(localStorage.getItem('profiles'));
    return this.userProfile;
  }

  setUserProfileModules(userProfileModules) {
    this.userProfileModules = userProfileModules;
    localStorage.setItem('userProfile', JSON.stringify(this.userProfileModules));
  }

  getUserProfileModules() {
    this.userProfile = JSON.parse(localStorage.getItem('userProfileModules'));
    return this.userProfileModules;
  }

  setDraftMealOrder(data: DraftOrder) {
    localStorage.setItem('draftMealOrder', JSON.stringify(data));
  }

  getDraftMealOrder() {
    const draftOrder: DraftOrder = JSON.parse(localStorage.getItem('draftMealOrder'));
    return draftOrder;
  }

  setClientServiceMeal(data: ClientServiceMealModel) {
    localStorage.setItem('clientServiceMeal', JSON.stringify(data));
  }

  setDefaultingAndVip(data: CompanyCustom) {
    this.companyCustom = data;
    localStorage.setItem('defaultingAndVip', JSON.stringify(this.companyCustom));
  }

  getDefaultingAndVip() {
    this.companyCustom = JSON.parse(localStorage.getItem('defaultingAndVip'));
    return this.companyCustom;
  }

  getClientServiceMeal() {
    const clientService: ClientServiceMealModel = JSON.parse(localStorage.getItem('clientServiceMeal'));
    return clientService;
  }

  setMealDocumentReference(data: DocumentReferenceTypeModel[]) {
    localStorage.setItem('mealDocumentReference', JSON.stringify(data));
  }

  getMealDocumentReference() {
    const data: DocumentReferenceTypeModel[] = JSON.parse(localStorage.getItem('mealDocumentReference'));
    return data;
  }

  setUsers(users) {
    this.users = users;
    localStorage.setItem('users', JSON.stringify(this.users));
  }

  getUsers() {
    this.users = JSON.parse(localStorage.getItem('users'));
    return this.users;
  }

  setLastUserChangeOrder(user: any) {
    this.lastUserChangeOrder = user;
    localStorage.setItem('lastUserChangeOrder', JSON.stringify(this.lastUserChangeOrder));
  }

  getLastUserChangeOrder() {
    this.lastUserChangeOrder = JSON.parse(localStorage.getItem('lastUserChangeOrder'));
    return this.lastUserChangeOrder;
  }

  setBeneficiarySelected(beneficiary: Beneficiary) {
    localStorage.setItem('beneficiarySelected', JSON.stringify(beneficiary));
  }

  getBeneficiarySelected() {
    const beneficiary = new Beneficiary(JSON.parse(localStorage.getItem('beneficiarySelected')));
    return beneficiary;
  }

  setBranchOffices(branchOffices) {
    this.branchOffices = branchOffices;
    localStorage.setItem('branchOffices', JSON.stringify(this.branchOffices));
  }

  getBranchOffices() {
    this.branchOffices = JSON.parse(localStorage.getItem('branchOffices'));
    return this.branchOffices;
  }

  setOrders(orders) {
    this.orders = orders;
    localStorage.setItem('orders', JSON.stringify(this.orders));
  }
  getOrders() {
    this.orders = JSON.parse(localStorage.getItem('orders'));
    return this.orders;
  }

  removeItemFromLocalStorage(key: string) {
    return localStorage.removeItem(key);
  }

  setExecutive(executive: any) {
    this.executive = executive;
    localStorage.setItem('executive', JSON.stringify(this.executive));
  }

  getExecutive() {
    this.executive = JSON.parse(localStorage.getItem('executive'));
    return this.executive;
  }

  setRestriction(restriction: any) {
    this.restriction = restriction;
    localStorage.setItem('restriction', JSON.stringify(this.restriction));
  }

  getRestriction() {
    this.restriction = JSON.parse(localStorage.getItem('restriction'));
    return this.restriction;
  }

  setOnlineOrder(onlineOrder) {
    this.onlineOrder = onlineOrder;
    localStorage.setItem('onlineOrder', JSON.stringify(this.onlineOrder));
  }

  getOnlineOrder() {
    this.onlineOrder = JSON.parse(localStorage.getItem('onlineOrder'));
    return this.onlineOrder;
  }

  setLoadBalanceOrder(loadBalanceOrder) {
     this.loadBalanceOrder = loadBalanceOrder;
     localStorage.setItem('loadBalanceOrder', JSON.stringify(this.loadBalanceOrder));
  }

  getLoadBalanceOrder() {
     this.loadBalanceOrder = JSON.parse(localStorage.getItem('loadBalanceOrder'));
     return this.loadBalanceOrder;
  }

  setAmountOrder(amountOrder) {
    this.amountOrder = amountOrder;
    localStorage.setItem('amountOrder', JSON.stringify(this.amountOrder));
  }

  getAmountOrder() {
    this.amountOrder = JSON.parse(localStorage.getItem('amountOrder'));
    return this.amountOrder;
  }

  setBillingCenter(billingCenter) {
    this.billingCenter = billingCenter;
    localStorage.setItem('billingCenter', JSON.stringify(this.billingCenter));
  }

  getBillingCenter() {
    this.billingCenter = JSON.parse(localStorage.getItem('billingCenter'));
    return this.billingCenter;
  }

  setClientAccountBalance(clientAccountBalance) {
    this.clientAccountBalance = clientAccountBalance;
    localStorage.setItem('clientAccountBalance', JSON.stringify(this.clientAccountBalance));
  }

  getClientAccountBalance() {
    this.clientAccountBalance = JSON.parse(localStorage.getItem('clientAccountBalance'));
    return this.clientAccountBalance;
  }



  setOriginViewOrder(originViewOrder) {
    this.originViewOrder = originViewOrder;
    localStorage.setItem('originViewOrder', JSON.stringify(this.originViewOrder));
  }

  getOriginViewOrder() {
    this.originViewOrder = JSON.parse(localStorage.getItem('originViewOrder'));
    return this.originViewOrder;
  }

  setNavigationMenu(menu) {
    this.navigationMenu.next(menu);
  }

  getNavigationMenu() {
    return this.navigationMenu.getValue();
  }

  setBeneficiaryBenefitsSelected(beneficiary: BeneficiaryBenefits) {
      localStorage.setItem('beneficiaryBenefitsSelected', JSON.stringify(beneficiary));
    }

  getBeneficiaryBenefitsSelected() {
      const beneficiary = new BeneficiaryBenefits(JSON.parse(localStorage.getItem('beneficiaryBenefitsSelected')));
      return beneficiary;
    }



  setCurrentOnlineMovement(currentOnlineMovement: OrderMovementModel) {
    localStorage.setItem('currentOnlineMovement', JSON.stringify(currentOnlineMovement));
  }

  getCurrentOnlineMovement() {
    const currentOnlineMovement = JSON.parse(localStorage.getItem('currentOnlineMovement'));
    return currentOnlineMovement;
  }

  setCustomerSegmentation(data: any) {
    this.segment = data;
    localStorage.setItem('customerSegmentation', JSON.stringify(this.segment));
  }

  getCustomerSegmentation() {
    this.segment = JSON.parse(localStorage.getItem('customerSegmentation'));
    return this.segment;
  }

  getDataBeneficiaryExport() {
    this.dataBeneficiaryExport = JSON.parse(localStorage.getItem('dataBeneficiaryExport'));
    return this.dataBeneficiaryExport;
  }

  setDataBeneficiaryExport(dataBeneficiaryExport: any) {
    this.dataBeneficiaryExport = dataBeneficiaryExport;
    localStorage.setItem('dataBeneficiaryExport', JSON.stringify(this.dataBeneficiaryExport));
  }

  setSendMailZeroOtherUser(nroPedidoZero: number, totalAmountZero: number) {
    this.nroPedidoZero = nroPedidoZero;
    this.totalAmountZero = totalAmountZero;
  }
  getSendMailZeroOtherUser() {
    const dataSendMailZeroOtherUser = {
      nroPedido: this.nroPedidoZero,
      totalAmount: this.totalAmountZero
    };
    return dataSendMailZeroOtherUser;
  }

  setOrderTypeCustom(orderTypeCustom: any) {
    localStorage.setItem('orderTypeCustom', orderTypeCustom);
  }

  getOrderTypeCustom() {
    return localStorage.getItem('orderTypeCustom');
  }

  setAdditionalEmail(additionalEmail: any) {
    localStorage.setItem('additionalEmail', additionalEmail);
  }

  getAdditionalEmail() {
    return localStorage.getItem('additionalEmail');
  }

  setListActionsPlatform(idActions: any){
    return localStorage.setItem('listActions',JSON.stringify(idActions));
  }

  getListActionsPlatform(){
    return JSON.parse(localStorage.getItem('listActions'));
  }
  setInitialiseInvite(initialiseInvite: any){
    return localStorage.setItem('initialiseInvite',JSON.stringify(initialiseInvite));
  }

  getInitialiseInvite(){
    return JSON.parse(localStorage.getItem('initialiseInvite'));
  }

}
