import { Component, OnInit, Input, Output, HostBinding, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-items-per-page',
  templateUrl: './items-per-page.component.html',
  styleUrls: ['./items-per-page.component.scss']
})
export class ItemsPerPageComponent implements OnInit {
  @Input() results: any[] = [];
  @Input() itemsPerPage = 10;
  @Output() itemsPerPageChange = new EventEmitter();
  @Input() options: any = [];
  @Input() class = '';

  constructor() { }

  ngOnInit(): void {
    for ( let index = 0; index < this.results.length && index < 50; index += 10 ) {
      this.options.push( index + 10 );
    }
  }

  onChangeSelected(itemsPerPage: number) {
    this.itemsPerPage = itemsPerPage;
    this.itemsPerPageChange.emit(this.itemsPerPage);
  }

  @HostBinding('class')
  get hostClasses(): string {
    return [
      'items-per-page',
      this.class, // include our new one
    ].join(' ');
  }

}
