<div ngbDropdown placement="bottom-right" display="static">
  <button type="button"
    [id]="id"
    [disabled]="isDisabled"
    [ngClass]="{ 'dropdown-toggle--filled': selected != undefined, 'dropdown-toggle--disabled': isDisabled }"
    [style.--placeholder-width]="placeholder.toString().length < 12 ? ( placeholder.toString().length + 4 ) + 'ch' : ''"
    class="btn btn-outline-secondary dropdown-toggle"
    aria-haspopup="true"
    aria-expanded="false"
    ngbDropdownToggle
  >
    <span class="dropdown__placeholder" *ngIf="selected == undefined">
      <span class="dropdown__text">{{ placeholder }}</span>
      <app-icon icon="chevron-down" class="dropdown__chevron"></app-icon>
    </span>
    <span class="dropdown__value" *ngIf="selected != undefined">
      <span class="dropdown__text">{{ selected }}</span>
      <span class="dropdown__reset" (click)="clean()" id="selectFilterCleaner">
        <span class="sr-only">Limpiar filtro</span>
        <app-icon icon="close"></app-icon>
      </span>
    </span>
  </button>
  <div ngbDropdownMenu>
    <button type="button" class="dropdown-item" [id]="getOptionId(option)"
      ngbDropdownItem *ngFor="let option of options" (click)="selectOption(option)">
      {{ option }}
    </button>
  </div>
</div>
