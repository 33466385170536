import { Component, Input, OnInit, EventEmitter, Output  } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() breadcrumbs: any [];
  @Output() step = new EventEmitter<boolean>();


  constructor() { }

  ngOnInit() {
  }

  stepClicked(i) {
    this.step.emit(i);
  }
}
