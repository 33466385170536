export class BeneficiaryBenefits {
    
   id: number;
   fiscalId: string;
   phoneNumber: number;
   fullName: string;
   active: boolean;
   email: string
   name: string;
   lastName: string;
   secondLastName: string;
   unavailable: boolean;
   countryDial: string;
   branchOffice: string;
   costCenter: string;

  public constructor(init?: Partial<BeneficiaryBenefits>) {
    Object.assign(this, init);
  }

}
