export const environment = {
  production: false,
  baseUrl: 'https://development.sodexo.cl/api/clientes/sercotec/customers-web',
  ciam: {
    baseUrl: 'https://development.sodexo.cl/acceso',
    login: '/?platform=portal-clientes-sercotec',
    logout: '/logout?platform=portal-clientes-sercotec',
    lostPassword: '/olvide-mi-clave',
    signUp: 'https://www.sodexo.cl/nuestros-productos/sercotec/cheque-restaurant-tarjeta',
    baseApi: 'https://development.sodexo.cl/api',
    refresh: '/clientes/alimentacion/ciam-integration/jwt/refresh',
    account: '/mi-cuenta/perfil',
    services: '/mi-cuenta/mis-servicios'
  },



  businessSearchUrl: {
    frameworkAgreement: 'https://development.sodexo.cl/personas/buscador#convenio-marco',
  },
  userIdle: {
    idle: 3600, // inactivo es 3600 (1 horas)
    timeout: 60, // tiempo de espera es 60 segundos
    ping: 120 // ping es 120 (2 minutos)
  },
  session: {
    expire: 3600 // duración de la sesión (1 hora x defecto)
  },
  multipartUrl: [
    '/orders/meal/uploadExcel',
    '/orders/massive/giftcard/validateExcel',
    '/orders/massive/giftcard/rechargeValidateExcel',
    '/beneficiary-meal/upload-excel',
    '/meal/balance-manager/upload-excel-massive',
    '/recovery-manager/uploadExcelRecovery'
  ],
  status: {
    orders: {
      pending: 1,
      inProgress: 2,
      finalized: 3,
      joined: 10,
      tested: 11,
      requestInProcess: 12,
      availableForRetirement: 13,
      inOffice: 14,
      delivered: 15,
      undelivered: 16,
      sendToSupplier: 19,
      acepted: 20,
      canceled: 21,
      compliantReception: 22,
      compliantReceptionDescription: 'Recepcion Conforme',
      pendingToReceive: 23,
      partiallyReceive: 24,
      compliantReceptionIncomplete: 25,
    },
    quotations: {
      pendingApproval: 4,
      reject: 5,
      approval: 6,
      requestInProcess: 7,
      availableForRetirement: 8,
      inOffice: 9,
      delivered: 10,
      undelivered: 11,
      draft: 17,
      delete: 18
    },
    mealOrder: [
      {
        id: 1,
        name: 'Borrador'
      },
      {
        id: 2,
        name: 'Ingresado'
      },
      {
        id: 3,
        name: 'En proceso'
      },
      {
        id: 4,
        name: 'Entregado'
      },
      {
        id: 5,
        name: 'Facturado'
      },
      {
        id: 6,
        name: 'Por confirmar'
      },
      {
        id: 12,
        name: 'Procesado en Sodexho'
      }
    ],
    cardBackoffice: {
      joined: 10,
      tested: 11,
      processed: 50,
      inBillingProcess: 24,
      invoiced: 25,
      canceled: 19,
      list: [
        {
          id: 10,
          name: 'Ingresado'
        },
        {
          id: 11,
          name: 'Testeado'
        },
        {
          id: 50,
          name: 'Procesado'
        },
        {
          id: 24,
          name: 'En proceso de facturación'
        },
        {
          id: 25,
          name: 'Facturado'
        },
        {
          id: 19,
          name: 'Anulado'
        }
      ]
    }
  },
  orders: {
    orderTypesMeal: {
      orderTypeCharge: 16,
      orderTypeChargeDescription: 'Carga de pedido',
      orderTypeDistribution: 17,
      orderTypeDistributionDescription: 'Distribución de saldo',
      orderTypeRescue: 18,
      orderTypeRescueDescription: 'Rescate de saldo',
      orderTypeBranchDistribution: 26,
      orderTypeBranchDistributionDescription: 'Distribución de saldo por sucursal',
      orderTypeChargeBranch: 27,
      orderTypeChargeBranchDescription: 'Carga pedido sucursal'
    },
    home: {
      howManyMerchants: 2,
      howManyBeneficiaries: 2
    },
    createNewOrder: {
      serviceId: 16,
      orderType: 15,
      digitateOrderExcel: 'N',
      digitateOrderManual: 'S',
      userAuditId: 12
    },
    orderExpirationType: {
      weekly: 1,
      monthly: 2,
      undefined: 3,
      flexible: 4
    },
    agreementType: {
      client: 'C'
    },
    invoiceType: {
      facturaAfecta: 33,
      facturaExenta: 34,
      notaDeCredito: 61
    },
    orderTypes: {
      rescue: 'rescue',
      recharge: 'recharge'
    },
    rutSodexo: '96556930',
    rutAlmacenesDeBarrio: '76.828.790-2',
    mailOperaciones: 'pedidos.ms.cl@endtest-mail.io'
  },
  rejectionType: [
    { id: 1, name: 'Excede el Monto', description: 'Excede el Monto', available: true },
    { id: 2, name: 'No corresponde', description: 'No corresponde', available: true },
    { id: 3, name: 'Otro', description: 'Otro', available: true }
  ],
  serviceIds: {
    sercotec: {
      serviceId: 33
    },
    beneficios: {
      service: {
        name: 'Beneficios',
        serviceId: 34,
        disabled: false
      }
    },
    junaeb: {
      serviceId: 15
    },
    alimentacion: {
      name: 'Alimentacion',
      serviceId: 16,
      platformId: 2,
      activeMultipleDocumentReference: false
    }
  },
  services: [
    {
      serviceId: 33,
      serviceName: 'Sercotec'
    }, {
      serviceId: 34,
      serviceName: 'Beneficios'
    }, {
      serviceId: 15,
      serviceName: 'Junaeb'
    }, {
      serviceId: 16,
      serviceName: 'Tarjeta'
    }
  ],
  userProfile: {
    sercotecAos: 'sercotec-aos',
    sercotecBeneficiary: 'sercotec-beneficiary',
    beneficios: 'beneficios',
    alimentacion: 'alimentacion',
    junaeb: 'junaeb'
  },
  deliveryTypes: {
    homeDelivery: 1,
    pickUpStore: 2
  },
  tax: 0.19,
  increment: 0.022,
  customerType: {
    aos: 1,
    beneficiary: 2,
    customer: 3
  },
  menuBeneficiaryDefault: '/sercotec/productos',
  junaebProceedings: {
    typeAct: {
      baes: 'BAES',
      casino: 'CASINO',
      cardDelivery: 'CARD_DELIVERY'
    },
    report: {
      // tslint:disable-next-line: max-line-length
      url: 'https://app.powerbi.com/groups/me/reports/5cc20225-7e39-4145-87ca-1f8c5618ecf6/ReportSection1aa921a1059c1cbb6735?ctid=a289d6c2-3b1f-4bc4-8fa0-6866ff300052',
      // tslint:disable-next-line:max-line-length
      urlConsumption: 'https://app.powerbi.com/reportEmbed?reportId=b60a023f-46b7-48ba-8326-63f893358cee&autoAuth=true&ctid=a289d6c2-3b1f-4bc4-8fa0-6866ff300052&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D'
    }
  },
  errors: {
    typeErrors: {
      invalidRut: 2
    }
  },
  actions: {
    beneficios: {
      massiveExcel: true
    }
  },
  configs: {
    trackingUi: {
      enable: true,
      reportingUrl: 'https://eum-red-saas.instana.io',
      apiKey: 'UF105UbKSH2xN0hU8RzBUw'
    }
  },
  profile: {
    alimentacion: {
      defaultProfileId: 2,
      all: [
        {
          id: 1,
          name: 'Super Administrador'
        },
        {
          id: 2,
          name: 'Administrador'
        },
        {
          id: 3,
          name: 'Operativo'
        }
      ]
    }
  },
  typesReferences: [
    {
      id: 1,
      ref: 'Orden de compra'
    }, {
      id: 2,
      ref: 'Hes'
    }, {
      id: 3,
      ref: 'Contrato'
    }, {
      id: 4,
      ref: 'Nota de pedido'
    }
  ],
  billingTypes: {
    automaticConsolidation: 3
  },
  videoTutoriales: [
    {
      title: 'Carga manual + nuevo beneficiario',
      description: 'Aprende a hacer un pedido de carga manual',
      url: 'https://cdn-public-development.sodexo.cl/carga_manual_mas_nuevo_beneficiario.mp4',
      pic: 'https://cdn-public-development.sodexo.cl/carga_manual_mas_nuevo_beneficiario.jpg'
    },
    {
      title: 'Carga masiva + nuevo beneficiario',
      description: 'Aprende a hacer un pedido de carga masiva vía excel',
      url: 'https://cdn-public-development.sodexo.cl/carga_masiva_mas_nuevo_beneficiario.mp4',
      pic: 'https://cdn-public-development.sodexo.cl/carga_masiva_mas_nuevo_beneficiario.jpg'
    },
    {
      title: 'Centro de ayuda',
      description: 'Centro de ayuda',
      url: 'https://cdn-public-development.sodexo.cl/centro_de_ayuda.mp4',
      pic: 'https://cdn-public-development.sodexo.cl/centro_de_ayuda.jpg'
    },
    {
      title: 'Detalle de pedido',
      description: 'Detalle de pedido',
      url: 'https://cdn-public-development.sodexo.cl/detalle_de_pedido.mp4',
      pic: 'https://cdn-public-development.sodexo.cl/detalle_de_pedido.jpg'
    },
    {
      title: 'Filtro por Id',
      description: 'Filtro por Id',
      url: 'https://cdn-public-development.sodexo.cl/filtro_id.mp4',
      pic: 'https://cdn-public-development.sodexo.cl/filtro_por_id.jpg'
    }
  ],
  cardStatus: {
    emitted: 21,
    activate: 22,
    bloked: 23
  },
  cardChangeStatusReasonId: {
      lostCard: 1,
      notMovements: 10,
      lockByClient: 11,
      unlockByClient: 12
  },
  beneficiaryStatus: {
    active: 1,
    inactive: 2
  },
  beneficiaryChangeStatusReasonId: {
    temporaryRetirement: 1,
    definitiveRetirement: 2,
    reincorporation: 3
  },
  beneficiaryRecoverBalance: {
    minAmount: 100
  },
  reportJunaeb: {
    url: 'https://app.powerbi.com/reportEmbed?reportId=b60a023f-46b7-48ba-8326-63f893358cee&autoAuth=true&ctid=a289d6c2-3b1f-4bc4-8fa0-6866ff300052&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D'
  },
  sinInformacion:'Sin información.'

};
