import { Component, Input, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  @Input() icon = '';
  className = '';

  constructor() { }

  ngOnInit() {
    this.className = 'svg-' + this.icon + '-dims';
  }
}
