import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent implements OnInit {

  @Input() searchInputPlaceholder: string;
  @Input() searchInputId: string;
  @Input() allSelectorLabel: string;
  @Input() options = [];
  @Input() selectButtonId: string;
  @Input() expandable = true;
  @Input() withConfirm = true;
  @Output() submit = new EventEmitter();

  @Input()
  get selected(): any { return this.localSelected; }
  set selected(selected: any) {
    this.localSelected = selected;
    this.outputSelected = selected;
    this.isAllChecked();
  }
  private localSelected: any = [];

  showBody = false;
  outputSelected: any = [];
  optionsFiltered: any = [];
  allChecked = false;

  constructor() { }

  ngOnInit() {
    this.showBody = !this.expandable;
    this.optionsFiltered = this.options;
    this.outputSelected = this.selected;
    this.isAllChecked();
  }

  isAllChecked() {
    this.allChecked = this.options.length === this.selected.length;
  }

  isDisabled() {
    return this.outputSelected.length > 0;
  }

  toggleBody() {
    this.showBody = !this.showBody;
  }

  handleOpenSearchBody() {
    this.showBody = true;
  }

  toggleCheckAll(all) {
    this.outputSelected = [];
    if (all) {
      this.options.forEach(option => {
        this.outputSelected.push(option.id);
      });
    }
    if(!this.withConfirm) {
      this.submit.emit( this.outputSelected );
    }
  }

  search(value) {
    this.optionsFiltered = this.options.filter(
      option => option.value.toString().toLowerCase().includes(value.toLowerCase())
    );
  }

  selectOption(value) {
    const parsedValue = parseInt(value, 10);
    if (this.outputSelected.indexOf(parsedValue) === -1) {
      this.outputSelected.push(parsedValue);
    } else {
      const index = this.outputSelected.indexOf(parsedValue);
      if (index > -1) {
        this.outputSelected.splice(index, 1);
      }
    }
    this.allChecked = this.outputSelected.length === this.options.length ? true : false;
    if(!this.withConfirm) {
      this.submit.emit( this.outputSelected );
    }
  }

  handleSubmit() {
    this.submit.emit( this.outputSelected );
    this.showBody = false;
  }
}
