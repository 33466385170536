import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

import { HomeComponent } from './features/home/home.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { HelpCenterComponent } from './features/dashboard/alimentacion/help-center/help-center.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  }, {
    path: 'centro-de-ayuda', component: DashboardComponent,
    children: [
      { path: '', component: HelpCenterComponent },
    ],
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  }, {
    path: 'sercotec', component: DashboardComponent,
    loadChildren: () => import('./features/dashboard/sercotec/sercotec.module').then(m => m.SercotecModule)
  }, {
    path: 'beneficios', component: DashboardComponent,
    loadChildren: () => import('./features/dashboard/beneficios/beneficios.module').then(m => m.BeneficiosModule)
  }, {
    path: 'junaeb', component: DashboardComponent,
    loadChildren: () => import('./features/dashboard/junaeb/junaeb.module').then(m => m.JunaebModule)
  }, {
    path: 'alimentacion', component: DashboardComponent,
    loadChildren: () => import('./features/dashboard/alimentacion/alimentacion.module').then(m => m.AlimentacionModule)
  }, {
    path: 'mipluxee', component: DashboardComponent,
    loadChildren: () => import('./features/dashboard/mipluxee/mipluxee.module').then(m => m.MiPluxeeModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot([]),
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
