import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-summary-item',
  templateUrl: './summary-item.component.html',
  styleUrls: ['./summary-item.component.scss']
})
export class SummaryItemComponent implements OnInit {

  @Input() label = '';
  @Input() value = '';
  @Input() iconClass: string = null;

  constructor() { }

  ngOnInit() {
  }

}
