import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

// declare function ineum(command: string, ...data: Array<any>): void;

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request && request.headers && request.headers.get('x-instana-s')) {
      console.log('request to map traceId: ', request.headers.get('x-instana-s'));
      // ineum('traceId', request.headers.get('x-instana-s'));
    }

    return next.handle(request).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // llamar a instana a que guarde el request realizado con su id trace
        if (event.headers.get('x-instana-s')) {
          console.log('response to map traceId: ', event.headers.get('x-instana-s'));
          // ineum('traceId', event.headers.get('x-instana-s'));
        }
      }
      return event;
    }));
  }
}
