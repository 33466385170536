import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() title = '';
  @Input() iconTitle = '';
  @Input() description = '';
  @Input() linkUrl = '';
  @Input() linkText = '';
  @Input() clickable = false;
  @Input() class = '';

  constructor() { }

  ngOnInit() {
  }

  @HostBinding('class')
  get hostClasses(): string {
    return [
      this.class, // include our new one
      this.clickable && this.linkUrl ? 'card--access' : '',
    ].join(' ');
  }

}
