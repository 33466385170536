import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment as env } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { AuthService } from './auth.service';
import { DataService } from '../data.service';
import { IndexService } from '../index/index.service';
import { UtilitiesService } from '../utilities/utilities.service';
import { LoginService } from './login.service';
import { CompanyService } from '../company/company.service';
import { OrderRestrictionService } from '../orders/order-restriction.service';

@Injectable()
export class LoginCiamService implements LoginService {

  constructor(
    private utilService: UtilitiesService,
    private router: Router,
    private authService: AuthService,
    private dataService: DataService,
    private indexService: IndexService,
    private spinner: NgxSpinnerService,
    private companyService: CompanyService,
    private orderRestrictionService: OrderRestrictionService) {
  }

  login(token: any): void {
    this.spinner.show();
    this.dataService.setLoginDate();
    if (token !== null && token !== undefined) {
      this.loadCompany(token, null);
    } else {
      this.spinner.hide();
      Swal.fire('Inicio de sesión', 'Email o Contraseña incorrectos.', 'error');
    }
  }

  loadCompany(token: any, companySelected: any): Promise<any> {
    this.spinner.show();
    const serviceName = this.dataService.getService() || '';
    return this.indexService.accessControl(token, serviceName.toLowerCase() || null).then(async (data: any = null) => {
      const user = data.user;
      const customers = data.customers;
      if (serviceName !== undefined && serviceName !== null) {
        const service = env.services.find(srv => srv.serviceName.toLowerCase() === serviceName.toLowerCase());
        if (service) {
          user.companies = this.deleteAnotherService(user.companies, service.serviceId);
        }
      }
      const howManyServices = this.companiesHasManyService(user.companies);
      if (howManyServices.length === 0) { // Si el listado de acuerdos no tiene elementos, entonces debe hacer logout de la plataforma
        this.spinner.hide();
        Swal.fire('Lo sentimos', 'Tu cuenta no está relacionada a la plataforma de clientes.', 'info')
          .then((result) => {
            this.authService.logout();
          });
      } else if (howManyServices.length > 1) { // Si el listado de acuerdos tiene más de un servicio, debe redireccionarlo a CIAM
        this.redirectToServicesCIAM();
      } else {
        user.companies = this.deleteAnotherService(user.companies, howManyServices[0]);
        const result = await this.checkMealServiceHasPlatform(user);
        if (result === undefined) {
          this.redirectToServicesCIAM();
        } else {
          this.dataService.setCurrentUser(result);
          const companies = this.dataService.getCompanies();
          if (companies !== null && companies !== undefined && companies.length > 0) {
            const company = this.utilService.orderCompanyByAgreement(companies);
            if (company != null) {
              const companyUser = companySelected !== null && companySelected !== undefined ? companySelected : company;
              this.loadUserProfile(customers, user, companyUser);
              this.spinner.hide();
            } else {
              console.warn('La empresa no posee ningún servicio habilitado en la plataforma.');
              Swal.fire('Inicio de sesión', 'No existen servicios asociados a tu cuenta.', 'error')
                .then(() => {
                  this.authService.logout();
                });
            }
          } else {
            console.error('La empresa no se encuentra registrada en el portal de clientes.');
            Swal.fire('Inicio de sesión', 'No existen compañias asociadas a tu cuenta.', 'error')
              .then(() => {
                this.authService.logout();
              });
          }
        }
      }
    }).catch(error => {
      if (error.status === 403 && error.error.message === 'User not has the role to access this resource') {
        Swal.fire('Lo sentimos', 'Tu cuenta no está relacionada a la plataforma de clientes.', 'info')
          .then(() => {
            this.authService.logout();
          });
      } else {
        this.authService.logout();
      }
    });
  }

  async loadUserProfile(customers: any[], user: any, companyUser: any = null): Promise<void>  {
    const serviceId: number = companyUser.agreements[0].serviceId;
    let module = '';

    switch (serviceId) {
      case env.serviceIds.sercotec.serviceId: // SERCOTEC
        if (customers && customers.some(c => c.customerId === null)) { // AOS
          user.userProfile = env.userProfile.sercotecAos;
          module = '/sercotec/beneficiarios';
        } else { // BENEFICIARIO
          user.userProfile = env.userProfile.sercotecBeneficiary;
          module = '/sercotec/productos';
        }
        break;
      case env.serviceIds.beneficios.service.serviceId: // BENEFICIOS
        await this.getBenefitsRestriction();
        user.userProfile = env.userProfile.beneficios;
        module = '/beneficios/inicio';
        break;
      case env.serviceIds.junaeb.serviceId: // JUNAEB
        user.userProfile = env.userProfile.junaeb;
        module = '/junaeb/home';
        break;
      case env.serviceIds.alimentacion.serviceId: // ALIMENTACION
        user.userProfile = env.userProfile.alimentacion;
        module = '/alimentacion/inicio';
        break;
      default:
        user.userProfile = null;
        break;
    }

    // Asignamos el cliente
    if (customers && customers.length > 0) {
      this.dataService.setCustomerDTO(customers[0]);
    }

    // Añadimos la compañia seleccionada
    this.dataService.setCompanySelected(companyUser);

    // Actualizamos usuario con su perfil
    this.dataService.setCurrentUser(user);

    // Redireccionamos al modulo correspondiente
    this.router.navigateByUrl(module);
  }

  companiesHasManyService(companies: any) {
    const servicesArray = [];
    for (const company of companies) {
      for (const agreement of company.agreements) {
        if (env.services.some(el => agreement.serviceId === el.serviceId) && agreement.agreementType === 'client') {
          servicesArray.push(agreement);
        }
      }
    }
    const uniquesServices = Array.from(new Set(servicesArray.map(service => service.serviceId)));

    return uniquesServices;
  }

  deleteAnotherService(companies: any, serviceId: number) {
    for (const company of companies) {
      const agreementCompany = [];
      company.agreements.forEach(agreement => {
        if (agreement.serviceId === serviceId) {
          agreementCompany.push(agreement);
        }
      });
      company.agreements = agreementCompany;
    }
    companies = companies.filter((v, i, a) => a.findIndex(t => (t.fiscalId === v.fiscalId)) === i);
    return companies;
  }

  async checkMealServiceHasPlatform(user: any) {
    if (user.companies[0].agreements[0].serviceId === env.serviceIds.alimentacion.serviceId) {
      const companyFiscalIdList = user.companies.map(company => company.fiscalId);
      const userFilter = await this.companyService.getMealCompayAccessPlatform(companyFiscalIdList).toPromise().then(data => {
        const companyList = [];
        user.companies.forEach(company => {
          if (data.some(el => company.fiscalId === el.companyFiscalId && el.platformId === env.serviceIds.alimentacion.platformId)) {
            companyList.push(company);
          }
        });
        if (companyList.length !== 0) {
          // Se obtiene la empresa con acceso a la plataforma unificada
          user.companies = companyList;
          return user;
        }
      }, error => {
        console.log('[ERROR] checkMealServiceHasPlatform => code: ' + error.status + ' - message: ' + error.message);
        this.authService.logout();
      });
      return userFilter;
    } else {
      return user;
    }
  }

  redirectToServicesCIAM() {
    window.location.href = env.ciam.baseUrl + env.ciam.services;
  }

  async getBenefitsRestriction() {
    const restriction = await this.orderRestrictionService.getOrderRestriction().toPromise()
      .catch(error => {
        console.log('[ERROR] loadUserProfile => code: ' + error.status + ' - message: ' + error.message);
      });

    if (restriction != null) {
      this.dataService.setRestriction(restriction);
    }
  }

}
