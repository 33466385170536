import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api/api.service';

const routes = {
  orderRestriction: '/orders/restriction',
};

@Injectable({
  providedIn: 'root'
})
export class OrderRestrictionService {

  constructor(private apiService: ApiService) { }

  getOrderRestriction() {
    return this.apiService.get(routes.orderRestriction);
  }
}
