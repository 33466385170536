import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/internal/Subscription';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-select-date',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.scss']
})
export class SelectDateComponent implements OnInit, OnDestroy {
  hoveredDate: NgbDate;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  private cleanSubscription: Subscription;

  @Input() displayMonths = '1';
  @Input() outsideDays: string;
  @Input() isRanged = false;
  @Input() markDisabled: boolean;
  @Input() minDate: any;
  @Input() clean: Observable<void>;

  @Output() selectDateOnClick = new EventEmitter<NgbDate | null>();

  constructor() {
    this.fromDate = null;
    this.toDate = null;
  }

  ngOnInit() {
    if (this.clean) {
      this.cleanSubscription = this.clean.subscribe(() => this.resetDate());
    }
  }

  ngOnDestroy() {
    if (this.clean) {
      this.cleanSubscription.unsubscribe();
    }
  }

  resetDate() {
    this.toDate = null;
    this.fromDate = null;
  }

  handleSelectDate(date: NgbDate) {
    if ( ! this.fromDate && !this.toDate ) {
      this.fromDate = date;
    } else if ( this.fromDate && !this.toDate && date.after(this.fromDate) ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.selectDateOnClick.emit(date);
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }
  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }
  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }
  isFirst(date: NgbDate) {
    return this.fromDate && date.equals(this.fromDate);
  }
  isLast(date: NgbDate) {
    return this.toDate && date.equals(this.toDate);
  }
}
