import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

// declare function ineum(command: string, ...data: Array<any>): void;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private spinner: NgxSpinnerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError(error => {
        this.spinner.hide();
        // ineum('reportError', error.error);
        console.error('Se captura error: ', error.error);
        return throwError(error);
      }));
  }
}
