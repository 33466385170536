import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HomeRoutingModule } from './home-routing.module';

import { HomeComponent } from './home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeHelpCenterComponent } from './help-center/help-center.component';

@NgModule({
  declarations: [HomeComponent, HeaderComponent, FooterComponent, HomeHelpCenterComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HomeRoutingModule
  ],
  entryComponents: [HomeComponent, HeaderComponent, FooterComponent, HomeHelpCenterComponent ]
})
export class HomeModule { }
