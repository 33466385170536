import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() ngOnLogin = new EventEmitter();
  @Output() ngOnSignUp = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  login() {
    this.ngOnLogin.emit();
  }

  signUp() {
    this.ngOnSignUp.emit();
  }

  resetPassword() {
    window.location.href = env.ciam.baseUrl + env.ciam.lostPassword;
  }

}
