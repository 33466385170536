// tslint:disable: max-line-length
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhonePipe } from './../../core/pipes/phone.pipe';
import { NgPipesModule } from 'ngx-pipes';
import { FilterByImpurePipe } from './../../core/pipes/filter-by-impure.pipe';
import { Ng9RutModule } from 'ng9-rut';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { DashboardComponent } from './dashboard.component';
import { HeaderDashboardComponent } from './components/header/header-dashboard.component';
import { FooterDashboardComponent } from './components/footer/footer-dashboard.component';
import { NavDashboardComponent } from './components/user-menu/nav-dashboard.component';
import { NavigationMenuComponent } from './components/navigation-menu/navigation-menu.component';
import { QRCodeModule } from 'angularx-qrcode';
import { DashboardRoutingModule } from './dashboard-routing.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdownDateComponent } from './components/dropdown-date/dropdown-date.component';
// import { Ng9RutModule } from 'ng9-rut';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from './../../core/services/ngb-date-custom-parser-formatter/ngb-date-custom-parser-formatter.service';
import { DigitOnlyDirective } from './../../core/directives/digit-only.directive';
import { NgxSpinnerModule } from 'ngx-spinner';

import { TooltipComponent } from './components/tooltip/tooltip.component';
import { VideoModalComponent } from './components/video-modal/video-modal.component';
import { FormBeneficiaryComponent } from './components/form-beneficiary/form-beneficiary.component';
import { FormBeneficiaryBenefitsComponent } from './components/form-beneficiary-benefits/form-beneficiary-benefits.component';
import { SelectFilterComponent } from './components/select-filter/select-filter.component';
import { PrevNextComponent } from './components/prev-next/prev-next.component';
import { OrderCardComponent } from './components/order-card/order-card.component';
import { CargaSaldoCardComponent } from './components/carga-saldo-card/carga-saldo-card.component';
import { SelectDateComponent } from './components/select-date/select-date.component';
import { ConfirmLeaveComponent } from './alimentacion/orders/new/components/confirm-leave/confirm-leave.component';
import { GroupButtonComponent } from './components/group-button/group-button.component';
import { FeedbackBlockComponent } from './components/feedback-block/feedback-block.component';
import { SummaryItemComponent } from './components/summary-item/summary-item.component';
import { ListGroupItemComponent } from './components/list-group-item/list-group-item.component';
import { ButtonComponent } from './components/button/button.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { SelectSearchComponent } from './components/select-search/select-search.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { CheckboxListComponent } from './components/checkbox-list/checkbox-list.component';
import { IconComponent } from './components/icon/icon.component';
import { TrackingComponent } from './components/tracking/tracking.component';

import { CardComponent } from './components/card/card.component';
import { ChartComponent } from './components/chart/chart.component';
import { ItemsPerPageComponent } from './components/items-per-page/items-per-page.component';

@NgModule({
  declarations: [
    DashboardComponent,
    HeaderDashboardComponent,
    FooterDashboardComponent,
    NavDashboardComponent,
    NavigationMenuComponent,
    PhonePipe,
    FilterByImpurePipe,
    DropdownDateComponent,
    DigitOnlyDirective,
    VideoModalComponent,
    FormBeneficiaryComponent,
    FormBeneficiaryBenefitsComponent,
    SelectFilterComponent,
    TooltipComponent,
    PrevNextComponent,
    OrderCardComponent,
    CargaSaldoCardComponent,
    SelectDateComponent,
    ConfirmLeaveComponent,
    GroupButtonComponent,
    FeedbackBlockComponent,
    SummaryItemComponent,
    ListGroupItemComponent,
    ButtonComponent,
    BreadcrumbComponent,
    SelectSearchComponent,
    UploadFileComponent,
    CheckboxListComponent,
    IconComponent,
    TrackingComponent,
    CardComponent,
    ChartComponent,
    ItemsPerPageComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FormsModule,
    NgPipesModule,
    NgbModule,
    Ng9RutModule,
    NgxPaginationModule,
    NgxChartsModule,
    ModalModule.forRoot()
  ],
  exports: [
    ModalModule,
    NgxPaginationModule,
    NgbModule,
    NgPipesModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownDateComponent,
    PhonePipe,
    FilterByImpurePipe,
    QRCodeModule,
    NgxSpinnerModule,
    VideoModalComponent,
    FormBeneficiaryComponent,
    FormBeneficiaryBenefitsComponent,
    SelectFilterComponent,
    TooltipComponent,
    PrevNextComponent,
    OrderCardComponent,
    CargaSaldoCardComponent,
    SelectDateComponent,
    GroupButtonComponent,
    FeedbackBlockComponent,
    SummaryItemComponent,
    ListGroupItemComponent,
    ButtonComponent,
    BreadcrumbComponent,
    SelectSearchComponent,
    UploadFileComponent,
    CheckboxListComponent,
    IconComponent,
    TrackingComponent,
    CardComponent,
    ChartComponent,
    ItemsPerPageComponent
  ],
  entryComponents: [
    DashboardComponent,
    HeaderDashboardComponent,
    FooterDashboardComponent,
    NavDashboardComponent,
    NavigationMenuComponent,
    DropdownDateComponent,
    VideoModalComponent,
    FormBeneficiaryComponent,
    FormBeneficiaryBenefitsComponent,
    SelectFilterComponent,
    TooltipComponent,
    PrevNextComponent,
    OrderCardComponent,
    CargaSaldoCardComponent,
    SelectDateComponent,
    ConfirmLeaveComponent,
    IconComponent,
    TrackingComponent,
    CardComponent,
    ChartComponent,
    ItemsPerPageComponent
  ],
  providers: [
    BsModalService,
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class DashboardModule { }
