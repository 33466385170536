import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback-block',
  templateUrl: './feedback-block.component.html',
  styleUrls: ['./feedback-block.component.scss']
})
export class FeedbackBlockComponent implements OnInit {
  @Input() title: string;
  @Input() description: string = null;
  @Input() iconClass: string = null;

  constructor() { }

  ngOnInit() {
  }

}
