import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Beneficiary } from 'src/app/core/model/beneficiary.model';
// import { RutValidator } from 'ng9-rut';
import { BranchOffice } from 'src/app/core/model/branchoffice.model';
import { CostCenter } from 'src/app/core/model/costcenter.model';

@Component({
  selector: 'app-form-beneficiary',
  templateUrl: './form-beneficiary.component.html',
  styleUrls: ['./form-beneficiary.component.scss']
})
export class FormBeneficiaryComponent implements OnInit, OnChanges {
  @Input() editMode: false;
  @Input() beneficiaries: any = [];
  @Input() beneficiary: any = {};
  @Input() branchOffices: any = [];
  @Output() changeBeneficiary: EventEmitter<Beneficiary> = new EventEmitter();

  model: Beneficiary = new Beneficiary();
  branchOffice: BranchOffice = new BranchOffice();
  costCenters: CostCenter[] = [];
  isUnique = true;

  constructor() { }

  ngOnInit() {
    // Valores predeterminados nulos
    this.model.branchOffice = null;
    this.model.costCenter = null;
    // Si el usuario sólo tiene una sucursal, se agrega el dato directamente
    if (this.branchOffices.length === 1) {
      this.model.branchOffice = this.branchOffices[0].address;
      this.selectBranchOffice(this.model.branchOffice);
      if (this.costCenters.length === 1) {
        this.model.costCenter = this.costCenters[0].name;
      }
    }
  }

  onSubmit(beneficiary) {
    const entries = Object.entries(beneficiary);
    for (const [key, value] of entries) {
      this.model[key] = value;
    }
    this.changeBeneficiary.emit(this.model);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.beneficiary ) {
      this.model = new Beneficiary(changes.beneficiary.currentValue);
      if ( this.model.branchOffice ) {
        this.selectBranchOffice(this.model.branchOffice);
      }
    }
  }

  selectBranchOffice(branchOfficeName) {
    // tslint:disable-next-line: variable-name
    const _branchOffice = this.branchOffices.find(bo => bo.address === branchOfficeName );
    if (typeof _branchOffice !== 'undefined') {
      if (_branchOffice.costCenters.length === 1) {
        this.model.costCenter = _branchOffice.costCenters[0].name;
      } else {
        this.costCenters = _branchOffice.costCenters;
      }
    }
  }

  checkUnique(rut) {
    const rutList = this.beneficiaries.map(be => be.legalId);
    const santizeRut = rut.replace(/[^0-9kK]+/g, '').toUpperCase();
    if (rutList.includes(santizeRut)) {
      this.isUnique = false;
    } else {
      this.isUnique = true;
    }
  }

}
