import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartModel } from 'src/app/core/model/meal/reportsDashboard/chart.model';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {
  // Opciones
  // tslint:disable-next-line: no-input-rename
  @Input('type') localType: keyof 'pie' | 'horizontal' | 'vertical' = 'horizontal';
  // tslint:disable-next-line: no-input-rename
  @Input() data: Observable<ChartModel[]>;
  @Input() view: any = [ 450, 220 ];
  @Input() xAxisLabel = '';
  @Input() yAxisLabel = '';
  @Input() formatValue: any | null = null;
  @Input() simpleColor: boolean = false;
  // Se quitan las gradientes predeterminadas de la librería
  gradient = false;
  // Definición de colores y orden de aparición
  colorScheme: any;
  // Legenda y etiquetas
  showLabels = true;
  showLegend = false;
  // Para gráficos horizontales y verticales
  showXAxis = true;
  showYAxis = true;
  showXAxisLabel = true;
  showYAxisLabel = true;
  // Valor inicial del view
  initView: any[] = [];
  // Results
  results: ChartModel[] = [];

  constructor() {
    this.calcView(window.innerWidth);
  }

  ngOnInit() {
    if(this.view[0] >= window.innerWidth - 100)
      this.calcView(window.innerWidth);
    this.colorScheme = {
      domain: this.simpleColor ? [
        '#8282dc',
        '#d4d5f4'] : [
        '#2a295c',
        '#8282dc',
        '#d4d5f4',
        '#ea444d',
        '#ff9673',
        '#3cdcc8',
        '#999',
      ],
    };
      this.data.subscribe(response => {
        if ( response && response.length > 0 ) {
          this.results = response;
        }
      }, (error: any) => {
        this.results = [];
      });
      this.initView = this.view;
  }

  onResize(event) {
    this.calcView(event.target.innerWidth);
  }

  calcView(windowWidth) {
    if ( ( windowWidth <= 400 ) ) {
      this.view = [260, 220];
    } else if (
      ( windowWidth >= 768 && windowWidth <= 890 ) ||
      ( windowWidth <= 460 )
    ) {
      this.view = [300, 220];
    } else {
      this.view = this.initView;
    }
  }

}
