import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})

export class TooltipComponent implements OnInit {

  @Input() id = '';
  @Input() closeBtn = true;
  @Input() iconClass = 'help';
  @Input() label = '';
  @Input() popTitle = '';
  @Input() type = '';

  constructor() { }

  ngOnInit() {
  }

}
