import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/services/data.service';
import { LoginService } from 'src/app/core/services/auth/login.service';
import { BusinessPlanService } from 'src/app/core/services/customers/business-plan.service';
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/core/services/utilities/utilities.service';
import { environment as env } from 'src/environments/environment';
import { ProfilesService } from 'src/app/core/services/profiles/profiles.service';
import {Module} from '../../../../core/model/module';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../../core/services/auth/auth.service';
import Swal from 'sweetalert2';
import {Profile} from '../../../../core/model/profile';
import {PlatformAction} from '../../../../core/model/platformAction.model';
import {NavigationActionService} from 'src/app/core/services/navigation-action/navigation-action.service';
import { CompanyService } from 'src/app/core/services/company/company.service';
import { ExecutiveService } from 'src/app/core/services/executive/executive.service';
@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html'
})
export class NavigationMenuComponent implements OnInit {

  currentUser: any;
  userProfile = '';
  serviceMenu: any;
  modules: Module[] = [];
  currentMenu = '';
  quotationsCartSize = 0;
  companySelected: any;
  env = environment;
  discardAlert: false;
  listActions = [];
  platformActions: PlatformAction[] = [];
  executive: any;

  services = [
    {
      name: 'sercotec-aos',
      menuItems: [
        {
          order: 1,
          title: 'Beneficiarios',
          url: '/sercotec/beneficiarios',
          itemsIncluded: ['/sercotec/beneficiarios', '/sercotec/beneficiarios/editar-beneficiario',
            '/sercotec/beneficiarios/ver-beneficiario', '/sercotec/beneficiarios/nuevo-beneficiario'],
          show: true
        },
        {
          order: 2,
          title: 'Pedidos',
          url: '/sercotec/pedidos',
          itemsIncluded: ['/sercotec/pedidos', '/sercotec/pedidos/nuevo-pedido', '/sercotec/pedidos/ver-pedido'],
          show: true
        },
        {
          order: 3,
          title: 'Marketplace',
          url: '/sercotec/marketplace',
          itemsIncluded: ['/sercotec/marketplace'],
          show: true
        }
      ]
    },
    {
      name: 'sercotec-beneficiary',
      menuItems: [
        {
          order: 1,
          title: 'Productos',
          url: '/sercotec/productos',
          itemsIncluded: ['/sercotec/productos'],
          show: true
        },
        {
          order: 2,
          title: 'Carro de cotización',
          url: '/sercotec/carro-de-cotizacion',
          itemsIncluded: ['/sercotec/carro-de-cotizacion'],
          show: true
        }
      ]
    },
    {
      name: 'beneficios',
      menuItems: [
        {
          order: 1,
          title: 'Gestión Tarjeta',
          url: '/beneficios/pedidos',
          itemsIncluded: [
            '/beneficios/pedidos',
            '/beneficios/carga-saldo',
            '/beneficios/nueva-carga-saldo',
            '/beneficios/rescate-saldo'
          ],
          childrens: [
            {
              title: 'Recarga de saldo',
              url: '/beneficios/carga-saldo',
            },
            {
              title: 'Rescate de saldo',
              url: '/beneficios/rescate-saldo',
            },
            {
              title: 'Pedidos',
              url: '/beneficios/pedidos'
            },
          ],
          show: true
        },
        {
          order: 2,
          title: 'Beneficiarios',
          url: '/beneficios/beneficiarios',
          itemsIncluded: [
            '/beneficios/beneficiarios',
            '/beneficios/beneficiarios/detalle-beneficiario',
            '/beneficios/beneficiarios/nomina'
          ],
          childrens: [
            {
              title: 'Listado de Beneficiarios',
              url: '/beneficios/beneficiarios'
            }, {
              title: 'Nómina de tarjetas',
              url: '/beneficios/beneficiarios/nomina'
            }
          ],
          show: true
        },
        {
          order: 3,
          title: 'Reporteria',
          url: '/beneficios/reportes',
          itemsIncluded: ['/beneficios/reportes', '/beneficios/reportes/compra-oc', '/beneficios/reportes/consumo'],
          show: true
        },
        {
          order: 4,
          title: 'Seguimiento',
          url: '/beneficios/seguimiento',
          itemsIncluded: ['/beneficios/seguimiento'],
          show: true
        }
      ]
    },
    {
      name: 'junaeb',
      menuItems: [
        {
          order: 1,
          title: 'Supervisión',
          url: '/junaeb/emision-reposicion',
          itemsIncluded: [
            '/junaeb/emision-reposicion',
            '/junaeb/informe-trimestral',
            '/junaeb/punto-canje',
            '/junaeb/actas-supervision',
            '/junaeb/tableau',
            '/junaeb/nueva-acta'
          ],
          show: true
        }
      ]
    },
    {
      name: 'alimentacion',
      menuItems: [
        {
          order: 1,
          title: 'pedidos',
          url: '/alimentacion/pedidos',
          show: true
        },
        {
          order: 2,
          title: 'Beneficiarios',
          url: '/alimentacion/beneficiarios',
          itemsIncluded: [
            '/beneficios/beneficiarios',
            '/beneficios/beneficiarios/detalle-beneficiario',
            '/beneficios/beneficiarios/nomina'
          ],
          childrens: [
            {
              title: 'Listado de Beneficiarios',
              url: '/beneficios/beneficiarios'
            }, {
              title: 'Nómina de tarjetas',
              url: '/beneficios/beneficiarios/nomina'
            }
          ],
          show: true
        },
        {
          order: 3,
          title: 'Reporteria',
          url: '/beneficios/reportes',
          itemsIncluded: ['/beneficios/reportes', '/beneficios/reportes/compra-oc', '/beneficios/reportes/consumo'],
          show: true
        },
        {
          order: 4,
          title: 'Seguimiento',
          url: '/beneficios/seguimiento',
          itemsIncluded: ['/beneficios/seguimiento'],
          show: true
        }
      ]
    },
    {
      name: 'mipluxee',
      menuItems: [
        {
          order: 1,
          title: 'Mi Tarjeta',
          url: '/mipluxee/mi-tarjeta',
          itemsIncluded: [],
          childrens: [],
          show: true
        },
        {
          order: 2,
          title: 'Buscador de comercios',
          url: '/mipluxee/buscador-de-comercios',
          itemsIncluded: [],
          childrens: [],
          show: true
        },
        {
          order: 3,
          title: 'Tarjeta Giftcard',
          url: '/mipluxee/tarjeta-giftcard',
          itemsIncluded: ['/mipluxee/tarjeta-giftcard/'],
          childrens: [],
          show: true
        },
      ]
    }
  ];

  constructor(
    private utilService: UtilitiesService,
    private router: Router,
    private dataService: DataService,
    private loginService: LoginService,
    private businessPlanService: BusinessPlanService,
    private profilesService: ProfilesService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private companyService: CompanyService,
    private navigationActionService: NavigationActionService,
    private executeService: ExecutiveService,
    ) {
    this.router.events.subscribe(e => {
      this.currentMenu = this.router.url;
      this.businessPlanService.setCurrentMenu(this.currentMenu);
    });
  }

  async ngOnInit() {
    this.loadNavigation();
    if (this.userProfile === 'junaeb' ) {
      this.getDynamicMenu(this.companySelected.fiscalId);
    } else if (this.userProfile === 'alimentacion') {
      this.getMealNavigationMenu();
    }

//    else if (this.userProfile === 'beneficios') {
//      const restriction = this.dataService.getRestriction();
//      if (restriction && restriction.increment && restriction.increment.viewMenu) {
//        this.services.filter(t => t.name === this.userProfile)
//          .map(t => {
//            t.menuItems.push(
//              {
//                order: 2,
//                title: 'Incremento',
//                url: '/beneficios/incremento',
//                itemsIncluded: ['/beneficios/incremento', '/beneficios/incremento/ver-pedido'],
//                show: true
//              });
//
//            t.menuItems.sort((a, b) => a.order - b.order);
//            return t;
//          });
//      }
//    }
  }

  getMealNavigationMenu(){
    this.modules = [
      {
          "id": 37,
          "name": "Pedidos",
          "status": true,
          "path": "alimentacion/pedidos",
          "subModules": [],
          "platformActionsModule": []
      },
      {
          "id": 39,
          "name": "Beneficiarios",
          "status": true,
          "path": "alimentacion/beneficiarios",
          "subModules": [],
          "platformActionsModule": []
      },
      {
          "id": 42,
          "name": "Reportes",
          "status": true,
          "path": "alimentacion/reportes",
          "subModules": [],
          "platformActionsModule": []
      },
    ];
  }

  getDynamicMenu(company){
    this.platformActions = [];
    this.profilesService.getDynamicMenu(this.currentUser.fiscalId, company).subscribe(data => {
      data?.modules?.forEach(m => {
        m.platformActionsModule?.forEach(a => {
          this.platformActions.push(a.platformActions.buttonId);
        });
      });
      this.dataService.setListActionsPlatform(this.platformActions);
      // tslint:disable-next-line:triple-equals
      if (data.idResponse != 404 && data.modules != null && data.modules.length > 0 &&
         data.roleId != null && data.nameRole != null ) {

        // tslint:disable-next-line:triple-equals
        if (data.uid == null || data.uid == '') {
          this.profilesService.updateCiam().subscribe();
        }

        const profile = new Profile();

        profile.roleId = data.roleId;
        profile.nameRole = data.nameRole;

        if (data.restrictions != null && data.restrictions.length > 0) {
            profile.restrictions = data.restrictions;
        }

        this.dataService.setUserProfile(profile);

        this.modules = data.modules;
        this.dataService.setNavigationMenu(this.modules);
        this.navigationActionService.setListModule(this.modules);

      } else {
        this.spinner.hide();
        Swal.fire('Lo sentimos', data.messageError, 'info')
          .then((result) => {
            this.authService.logout();
          });
      }

    }	, error => {
      console.log('[ERROR] loadNavigation => code: ' + error.status + ' - message: ' + error.message);
      this.spinner.hide();
      Swal.fire('Lo sentimos', 'Ha ocurrido un error, inténtelo nuevamente o contacte a área de soporte.', 'error')
        .then((result) => {
          this.authService.logout();
        });
    });
  }


  changeCompany(companySelected) {
    const company = JSON.parse(companySelected);
    const param = this.dataService.getToken();
    this.getExecutiveCompany(company.fiscalId);
    this.loginService.loadCompany(param, company);
    if (this.userProfile === 'alimentacion') {
      this.dataService.setCompanySelected(company);
      this.dataService.removeItemFromLocalStorage('listActions');
      this.dataService.removeItemFromLocalStorage('initialiseInvite');
      this.getDynamicMenu(company.fiscalId);
    }
  }

  getExecutiveCompany(fiscalId){
  this.companyService.getExecutive(fiscalId).subscribe((data: any[]) => {
    if (data !== null && data.length > 0) {
      const phone = data[0].people[0].phone.toString().replace(/ /g, '').replace(/-/g, '');
      this.executive = {
        fullname: `${data[0].people[0].name} ${data[0].people[0].lastName}`,
        email: data[0].people[0].email,
        phone: phone.length === 8 ? '9' + phone : phone,
        fiscalId
      };
      this.dataService.setExecutive(this.executive);
    }else{

        this.executive =null;

    }
    this.executeService.updateExecute(this.executive);
  }, error => {
    console.log('[ERROR] getExecutive => code: ' + error.status + ' - message: ' + error.message);
  });
}

  loadNavigation() {
    this.companySelected = this.dataService.getCompanySelected();
    this.currentUser = this.dataService.getCurrentUser();
    const companiesWithService = this.utilService.orderCompaniesByAgreementJust(this.currentUser.companies);
    this.currentUser.companies = companiesWithService;

    this.userProfile = this.currentUser.userProfile;
    this.serviceMenu = this.services.find(t => t.name === this.userProfile);

    this.dataService.getQuotationCartSize().subscribe(
      quantity => {
        this.quotationsCartSize = quantity;
      }
    );
  }

  redirectToProfile() {
    window.open(env.ciam.baseUrl + env.ciam.account, '_blank');
  }

  clickMenu(url) {
    this.router.navigateByUrl(url);
  }

  isActive(menu) {
    if ( this.currentUser.userProfile !== env.userProfile.junaeb && this.currentUser.userProfile !== env.userProfile.alimentacion) {
      return menu.url === this.currentMenu ||
        ( menu.childrens && menu.childrens.find(submenu => submenu.url === this.currentMenu) ) ||
        ( menu.itemsIncluded !== undefined && menu.itemsIncluded.length > 0 && (
          ( this.currentMenu.indexOf('?') !== -1 && menu.itemsIncluded.indexOf( this.currentMenu.split('?')[0] ) !== -1 ) ||
          ( menu.itemsIncluded.indexOf( this.currentMenu ) !== -1 )
        ) );
    } else {
      return menu.path === this.currentMenu ||
        ( menu.subModules && menu.subModules.find(submenu => submenu.path === this.currentMenu) );
    }
  }

  hasSubmodules(menu) {
    if ( this.currentUser.userProfile !== env.userProfile.junaeb && this.currentUser.userProfile !== env.userProfile.alimentacion) {
      return menu.childrens && menu.childrens.length > 0;
    } else {
      return menu.subModules && menu.subModules.length > 0;
    }
  }

}
