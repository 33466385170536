<header class="card__header">
  <h3 class="card__title"><app-icon *ngIf="iconTitle != ''" [icon]="iconTitle"></app-icon> {{ title }}</h3>
  <ng-content select=[tooltip]></ng-content>
</header>
<p class="card__description">{{ description }}</p>
<ng-content select=[chart]></ng-content>
<a *ngIf="linkUrl" [routerLink]="linkUrl" class="card__link" [ngClass]="{ 'card__link--empty' : clickable && ! linkText  }">
  <ng-container *ngIf="linkText">{{ linkText }}</ng-container>
  <app-icon icon="chevron-right"></app-icon>
</a>
