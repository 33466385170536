<div class="content-tooltip {{ type }}">
  <button app-button type="button" [icon]="iconClass" styling="link" class="content-tooltip__button {{ type }}" [ngbPopover]="id" [popoverTitle]="popTitle">
    {{ label }}
  </button>

  <ng-template #id>
    <ng-template [ngIf]="closeBtn">
      <div class="content-tooltip__area-close">
        <app-icon icon="close-thick"></app-icon>
      </div>
    </ng-template>

    <p class="content-tooltip__paragraph">
      <ng-content></ng-content>
    </p>
  </ng-template>
</div>
