<div class="app-navbar" *ngIf="userProfile">
  <div class="container-fluid">
    <div class="app-navbar__wrapper">
      <!-- Menú General -->
      <ul class="main-navigation" role="navigation" *ngIf="currentUser.userProfile !== env.userProfile.junaeb && currentUser.userProfile !== env.userProfile.alimentacion">
        <li *ngFor="let menu of serviceMenu.menuItems">
          <a href="{{ menu.externalUrl }}" target="_blank" rel="noopener" class="main-navigation__item"
            *ngIf="menu.show  && menu.externalUrl != null">
            {{ menu.title }}
          </a>
          <a routerLink="{{ menu.url }}"
            [ngClass]="{ 'main-navigation__item--active' : isActive(menu), 'main-navigation__item--with-submodules' : hasSubmodules(menu) }"
            class="main-navigation__item" *ngIf="menu.show && menu.externalUrl == null">
            {{ menu.title }}
            <app-icon *ngIf="hasSubmodules(menu)" icon="chevron-down"></app-icon>
            <span class="quotations-cart-size"
              *ngIf="menu.url.includes('carro-de-cotizacion') && quotationsCartSize != 0">
              {{ quotationsCartSize }}
            </span>
          </a>
          <ul *ngIf="menu.childrens" class="main-navigation__submenu">
            <li *ngFor="let submenu of menu.childrens">
              <a routerLink="{{ submenu.url }}" [ngClass]="isActive(submenu) ? 'main-navigation__item--active' : ''">
                {{ submenu.title }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <!-- Menú Junaeb  y Alimentación-->
      <ul class="main-navigation" role="navigation" *ngIf="currentUser.userProfile === env.userProfile.junaeb || currentUser.userProfile === env.userProfile.alimentacion">
        <li *ngFor="let menu of modules">
          <a routerLink="/{{ menu.path }}"
            [ngClass]="{ 'main-navigation__item--active' : isActive(menu), 'main-navigation__item--with-submodules' : hasSubmodules(menu) }"
            class="main-navigation__item">
            {{ menu.name }}
            <app-icon *ngIf="hasSubmodules(menu)" icon="chevron-down"></app-icon>
          </a>
          <ul *ngIf="hasSubmodules(menu)" class="main-navigation__submenu">
            <li *ngFor="let submenu of menu.subModules">
              <a routerLink="/{{ submenu.path }}" [ngClass]="{ 'main-navigation__item--active': isActive(menu) }">
                {{submenu.name}}
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <!-- Switcher -->
      <div class="user-switch" *ngIf="currentUser.userProfile !== env.userProfile.junaeb && currentUser.userProfile !== 'mipluxee'">
        <div class="form-inline">
          <div class="form-group">
            {{currentUser.name}} {{currentUser.lastName}}
            <select *ngIf="currentUser.companies.length > 0" class="custom-select custom-select-sm" id="slcCompany"
              (change)="changeCompany($event.target.value)">
              <option [value]="company | json" [selected]="company.fiscalId === companySelected.fiscalId"
                *ngFor="let company of currentUser.companies">{{ company.fantasyName }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
