import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/services/data.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CompanyService } from 'src/app/core/services/company/company.service';
import { ExecutiveService } from 'src/app/core/services/executive/executive.service';

@Component({
  selector: 'app-header-dashboard',
  templateUrl: './header-dashboard.component.html'
})
export class HeaderDashboardComponent implements OnInit {
  currentUser: any;
  company: any;
  executive: any = null;
  isNotSercotec: boolean;
  hasExecutive = true;
  env = environment;
  helpCenterEnabled: boolean;
  isAlimentacion: boolean;
  isJunaeb: boolean;

  constructor(
    private router: Router,
    private dataService: DataService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private companyService: CompanyService,
    private executiveService: ExecutiveService) {
  }

  ngOnInit() {
    this.currentUser = this.dataService.getCurrentUser();
    this.company = this.dataService.getCompanySelected();

    // if (this.company !== null && this.company !== undefined && this.company.agreements) {
    //   this.isNotSercotec = this.company.agreements.some(a => a.serviceId !== this.env.serviceIds.sercotec.serviceId
    //     && a.serviceId !== this.env.serviceIds.alimentacion.serviceId);
    //   this.isAlimentacion = this.company.agreements.some(a => a.serviceId === this.env.serviceIds.alimentacion.serviceId);
    //   this.isJunaeb = this.company.agreements.some(a => a.serviceId === this.env.serviceIds.junaeb.serviceId);
    //   if (this.isNotSercotec || this.isAlimentacion || this.isJunaeb) {
    //     this.hasExecutive = true;
    //     this.getExecutive(this.company.fiscalId);
    //   }
    // }

    // this.executiveService.executive$.subscribe((newValue)=>{
    //   this.executive=newValue;
    // })
    // this.helpCenterEnabled = this.currentUser && (this.currentUser.userProfile === this.env.userProfile.beneficios
    //   || this.currentUser.userProfile === this.env.userProfile.alimentacion);
  }

  goHome() {
    this.router.navigateByUrl('');
  }

  logout() {
    this.spinner.show();
    this.authService.logout();
  }

  getExecutive(fiscalId: string) {
    const executive = this.dataService.getExecutive();
    if (executive !== null && executive.fiscalId === fiscalId) {
      this.executive = {
        fullname: executive.fullname,
        email: executive.email,
        phone: executive.phone,
        fiscalId: executive.fiscalId
      };
      this.executiveService.updateExecute(this.executive);
      return;
    }

    this.companyService.getExecutive(fiscalId).subscribe((data: any[]) => {
      if (data !== null && data.length > 0) {
        const phone = data[0].people[0].phone.toString().replace(/ /g, '').replace(/-/g, '');
        this.executive = {
          fullname: `${data[0].people[0].name} ${data[0].people[0].lastName}`,
          email: data[0].people[0].email,
          phone: phone.length === 8 ? '9' + phone : phone,
          fiscalId
        };
        this.executiveService.updateExecute(this.executive);

        this.dataService.setExecutive(this.executive);
      }
    }, error => {
      console.log('[ERROR] getExecutive => code: ' + error.status + ' - message: ' + error.message);
    });


  }

  redirectToProfile() {
    window.open(this.env.ciam.baseUrl + this.env.ciam.account, '_blank');
  }

  clickHeader(url) {
    this.router.navigateByUrl(url);
  }

}
