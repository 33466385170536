// tslint:disable: max-line-length
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DataService } from 'src/app/core/services/data.service';
import { Companies } from 'src/app/core/model/company.model';
import { AttachmentDTO } from 'src/app/core/model/sendMailing';
import { VideoTutorial } from 'src/app/core/model/videotutorial.model';

import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { VideoModalComponent } from '../../components/video-modal/video-modal.component';

import Swal from 'sweetalert2';
import { environment as env } from 'src/environments/environment';
import { CaseSalesForce } from 'src/app/core/model/salesForce/case.model';
import { SalesForceService } from 'src/app/core/services/salesforces/salesforces.service';
import { WrapperResponse } from 'src/app/core/model/wrapper.response.model';

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss']
})
export class HelpCenterComponent implements OnInit {

  currentUser: any;
  image: string;
  activeQuestions = [];
  contactFormSubmitted = false;
  contactForm: any;
  contactFormSubmited = false;
  contactFormComplete = false;
  categories: any;
  categoriesFiltered: any;
  caseSalesForce: CaseSalesForce = new CaseSalesForce();
  attachmentDTO: AttachmentDTO = new AttachmentDTO();
  company: Companies[];
  videoTutoriales: VideoTutorial[];
  // Variable creada para esconder campos del formulario de Centro de ayuda
  hiddenInputs = false;
  fileSeleccted: File;
  private maxValueFile = 5000;
  companyrefresh: any;
  companyNameRefresh: string;
  loading = false;
  showVideoTutorial = false;
  isValid = true;

  categoriesAlimentacion = [
    {
      id: 1,
      name: 'Preguntas Frecuentes Beneficio',
      questions: [
        {
          id: 1,
          selected: false,
          question: '¿Cómo creo, modifico o elimino un beneficiario?',
          answer: '<p>Para crear, modificar o eliminar a un beneficiario, debes ingresar a tu plataforma de pedidos e ir directamente a la planilla de carga masiva, o bien uno a uno a través de la carga manual. Al crear un nuevo beneficiario en tus pedidos, cuando recibas tu tarjeta, podrás registrarte en la web o APP con tu correo electrónico.</p>'
        },
        {
          id: 2,
          selected: false,
          question: '¿Dónde puedo ocupar mi beneficio?',
          answer: '<p>Sodexo Tarjeta se puede utilizar en más de 13.000 comercios, como restaurantes, cadenas, supermercados, mini markets, cafeterías y ferias. Puedes buscar comercios y promociones en Sodexo App o en www.sodexo.cl/misodexo.</p>'
        },
        {
          id: 3,
          selected: false,
          question: '¿Cómo pago con mi beneficio?',
          answer: '<p>Puedes pagar con tu tarjeta deslizándola por la máquina de pago e ingresando tu contraseña, o desde tu smartphone, descargando Sodexo App, con la cual podrás pagar en supermercados y comercios asociados.</p>'
        },
        {
          id: 4,
          selected: false,
          question: 'Consejos de Seguridad para el Beneficiario',
          answer: '<p>Recuerda que tu contraseña y PIN de compra son personales y no debes compartirlos. Actualiza tus datos y modifica tu contraseña y PIN de compra cada 6 meses para una mayor seguridad.</p>'
        },
        {
          id: 5,
          selected: false,
          question: '¿Dónde administro el beneficio?',
          answer: '<p>El beneficio se administra en nuestra plataforma de pedidos www.sodexo.cl/Tarjetasodexo, donde podrás incorporar o eliminar usuarios, administrar sucursales y centros de costos, montos a entregar, y otras funciones más.</p>'
        }
      ]
    }, {
      id: 2,
      name: 'Preguntas Frecuentes Pedidos',
      questions: [
        {
          id: 1,
          selected: false,
          question: '¿Cómo realizo un pedido por primera vez?',
          answer: '<p>Para realizar un pedido por primera vez, ingresa a <a href=\'http://www.sodexo.cl\'>sodexo.cl</a> y haz click en ....... Ya en la plataforma, podrás elegir entre dos formas para realizar tu pedido:</p>' +
            '<ol><li><strong>Carga masiva:</strong>\ndescarga la planilla haciendo click en \'descargar planilla\' y rellena los campos correspondientes. Una vez rellenado el documento, haz click en \'carga masiva\'. Puedes arrastrar el archivo a la nueva ventana que se desplegará o hacer click en \'subir archivo\' para buscarlo en tu computador. Una vez cargada, presiona \'guardar y continuar\' y se mostrará un resumen de la carga realizada. Posteriormente podrás seleccionar la fecha de entrega y de liberación de saldos.</li>' +
            '<li><strong>Carga manual:</strong>\nPresiona el botón de \'carga manual\' y configura el uso de las tarjetas. Deberás ingresar mes de uso, fecha de liberación y monto diario. Luego selecciona las sucursales donde se entregarán las tarjetas y sus respectivos centros de costos. Para finalizar, selecciona la fecha de entrega.</li></ol>'
        },
        {
          id: 2,
          selected: false,
          question: '¿Con qué medio de pago puedo hacer mi pedido?',
          answer: '<p>Puedes pagar tu pedido mediante tarjeta de débito, crédito o transferencia bancaria.</p>'
        }
      ]
    }, {
      id: 3,
      name: 'Preguntas Frecuentes Tarjetas',
      questions: [
        {
          id: 1,
          selected: false,
          question: '¿Cómo es el proceso de entrega de tarjetas?',
          answer: '<p>Las tarjetas se entregarán en las sucursales que indiques y en un plazo de tiempo que entrega el sistema dependiendo de la comuna. Si deseas que la entrega sea posterior a la que determina el sistema, puedes seleccionarlo en la página. Cuando tus beneficiarios reciban las tarjetas, deberán activarlas en su portal o app Sodexo.</p>'
        },
        {
          id: 2,
          selected: false,
          question: '¿Cómo activo una tarjeta?',
          answer: '<p>La activación de la tarjeta la realiza cada usuario, siguiendo las instrucciones impresas en el contenedor. La activación se realiza en la página web de usuarios www.sodexo.cl/misodexo o bien, en la aplicación móvil disponible para Android y iOS.</p>'

        }
      ]
    }, {
      id: 4,
      name: 'Preguntas Frecuentes Generales',
      questions: [
        {
          id: 1,
          selected: false,
          question: '¿Qué es Sodexo App y Mi Sodexo?',
          answer: '<p>Sodexo app es nuestra aplicación móvil, en la cual los usuarios podrán ver su saldo y últimos movimientos, buscar comercios cercanos, pagar en supermercados y restaurantes, y actualizar sus datos personales. Mi Sodexo es nuestra plataforma que cumple la misma función que Sodexo App para beneficiarios que no cuenten con un smartphone. El ingreso a ambas plataformas es a través de un correo electrónico de activación.</p>'
        },
        {
          id: 2,
          selected: false,
          question: '¿Qué es la liberación de saldo?',
          answer: '<p>La liberación de saldo es la fecha que tú decides para depositar el saldo a cada beneficiario.</p>'
        },
        {
          id: 3,
          selected: false,
          question: '¿Cómo creo, modifico o elimino un administrador?',
          answer: '<p>Para crear, modificar o eliminar un administrador del beneficio, debes contactar al Centro de Ayuda a través del formulario web (link) o llamando al 22 487 0200. Solo puede modificar un administrador el Super Administrador, quien tiene todas las atribuciones de la plataforma.</p>'
        },
        {
          id: 4,
          selected: false,
          question: 'Consejos de Seguridad para la administración de este sitio',
          answer: '<p>No compartas tu usuario ni contraseña de acceso a la plataforma, recuerda que el acceso de los perfiles es jerarquizado.</p>'
        }
      ]
    }
  ];
  categoriesBeneficios = [
    {
      id: 1,
      name: 'Convenio Marco 2239-2-LR21',
      questions: [
        {
          id: 1,
          selected: false,
          question: '¿Desde cuándo comienza a operar el nuevo convenio Marco?',
          answer: '<p>El nuevo convenio marco para la Administración y entrega de beneficios de alimentación y tarjetas gift card comenzó a operar el 1 de agosto. Si necesitas más información haz <a href=\'https://f.hubspotusercontent30.net/hubfs/2715431/Nuevo_Convenio_Marco_V7%20(003).pdf\'>clic aquí.</a></p>'
        },
        {
          id: 2,
          selected: false,
          question: '¿Cómo reviso el proceso de mi pedido?',
          answer: '<p>En la plataforma de Sodexo podrás revisar el estado de tu pedido. Ingresar con tu usuario y clave, luego debes indicar el número de la orden de compra y completar los siguientes pasos. Te invitamos a revisar el procedimiento en el Manual Plataforma Clientes. (<a href=\'https://f.hubspotusercontent30.net/hubfs/2715431/Manual_Plataforma_Clientes_V2.pdf\'>descargar el instructivo</a>)</p>'
        },
        {
          id: 3,
          selected: false,
          question: '¿Qué me permite comprar este nuevo Convenio Marco?',
          answer: '<p>Este Convenio Marco tiene las siguientes categorías de compra:</p>' +
            '<ul>' +
              '<li>' +
                '<h3>Alimentación <small class=\'text-muted\'>(ID 182900)</small></h3>' +
                '<p>Se refiere a la administración de beneficios de alimentación en locales que entregan <strong>alimentación preparada y consumo inmediato en locales como Casinos</strong>, entendiendo este último concepto como establecimientos grandes, que pueden estar ubicados en instalaciones gubernamentales o en espacios privados y <strong>que se dedique a la entrega de alimentación completa; por lo general son establecimientos donde existe servicio a la mesa y los alimentos deben ser consumidos en las instalaciones</strong>.</p>' +
              '</li>' +
              '<li>' +
                '<h3>Comida Rápida <small class=\'text-muted\'>(ID 1829001)</small></h3>' +
                '<p>Se refiere a la administración de beneficios de <strong>alimentación preparada y consumo inmediato en locales de comida rápida (comida rápida)</strong>, entendiendo este último concepto como establecimientos pequeños, donde se prepara, se sirve y se entrega <strong>alimentación de manera rápida para consumir en el momento</strong>, por lo general son establecimientos donde no existe el servicio de camarería ni servicio a la mesa, aunque puede existir personal dedicado a la limpieza de las mismas.</p>' +
              '</li>' +
              '<li>' +
                '<h3>Vestuario y Calzado <small class=\'text-muted\'>(ID 1829003)</small></h3>' +
                '<p>Este tipo de beneficio podrá ser canjeado en los comercios adheridos que tendrá el proveedor adjudicado y <strong>sólo permitirá el canje para productos confeccionados</strong>.</p>' +
              '</li>' +
              '<li>' +
                '<h3>Supermercado <small class=\'text-muted\'>(ID 1829002)</small></h3>' +
                '<p>Este tipo de beneficio solo podrá ser canjeado en locales de venta al por menor de tipo <strong>supermercado, almacenes, minimarket</strong>. Los productos para comercializar por estos comercios deberán ser en relación con <strong>productos alimenticios y de aseo general y personal</strong>.</p>' +
              '</li>' +
              '<li>' +
                '<h3>Hogar y Construcción <small class=\'text-muted\'>(ID 1829004)</small></h3>' +
                '<p>Este tipo de beneficio apunta a productos en secciones de hogar y construcción ya sea en casas comerciales y/o comercios habilitados para venta de este tipo de productos. El organismo comprador requirente DEBERÁ definir al momento de la adquisición la configuración de la tarjeta para la compra de producto según el uso requerido, por ejemplo, tarjeta canjeable solo para productos como cemento, maderas y techumbre.</p>' +
              '</li>' +
            '</ul>'
        },
        {
          id: 4,
          selected: false,
          question: '¿Cuáles son las restricciones de la compra?',
          answer: '<p>Las restricciones para comprar a través de este convenio son las siguientes:</p>' +
            '<ul>' +
              '<li>' +
                '<h3>Categoría Supermercados</h3>' +
                '<p>Se prohíbe estrictamente el canje de productos de bebidas alcohólicas y relacionados a la sección de tabaquería, como cigarrillos (incluye electrónicos), habanos, puros etc.</p>' +
              '</li>' +
              '<li>' +
                '<h3>Categoría Vestuario y Calzado</h3>' +
                '<p>Queda prohibido la adquisición de: </p>' +
                '<ol>' +
                    '<li><strong>Servicios de confección a medida</strong>. Se entiende por confección el proceso de producción de prendas a partir de requerimientos específicos como: tipos de tela, medidas, colores específicos, etc.</li>' +
                    '<li><strong>Vestuario clínico</strong>, vestuarios utilizados por el área de salud y que requiere especificaciones técnicas que permiten el correcto funcionamiento del personal, se incluye la prohibición para prendas como mascarillas, guantes, gorros, vestuario desechable, uniformes médicos, enfermeros o auxiliares compuestos de parte superior e inferior, entre otros no permitidos. </li>' +
                    '<li><strong>Calzado técnico y/o institucional</strong>, corresponde al calzado con especificaciones técnicas establecidas y que generalmente no se encuentran con stock disponible, debido a los requerimientos específicos de tela, material y confección. Se incluyen la prohibición para Fuerzas Armadas, de Orden y Seguridad Pública y Gendarmería.</li>' +
                    '<li><strong>Vestuario técnico y/o institucional</strong>, corresponde al vestuario o uniformes con especificaciones técnicas establecidas y que generalmente no se encuentran con stock disponible, debido a los requerimientos específicos de tela, material y confección. Se incluyen la prohibición para Fuerzas Armadas, de Orden y Seguridad Pública y Gendarmería y salud.</li>' +
                '</ol>' +
              '</li>' +
            '</ul>'
        },
        {
          id: 5,
          selected: false,
          question: '¿Debo subir la cotización a mi orden de compra?',
          answer: '<p>Sí, cada vez que realices una orden de compra en el portal debes subir la cotización del proveedor al que le comprarás los productos. En el caso de solicitar gift card de retail debes ingresar en las observaciones el nombre del retail, nombre de la tienda, si las tarjetas son personalizadas o al portador y si existe alguna restricción sobre esa compra.</p>'
        },
        {
          id: 6,
          selected: false,
          question: '¿Cuándo se acepta mi orden de compra?',
          answer: '<p>En el portal de Chile Compra, Sodexo aceptará la orden de compra cuando el proveedor que elegiste acepte la orden de trabajo enviada. Este proceso demora hasta 48 horas. Una vez que es aceptada por el asociado, Sodexo acepta la orden en el portal.</p>'
        },
        {
          id: 7,
          selected: false,
          question: '¿Qué es un E-voucher?',
          answer: '<p>Es un medio de pago electrónico utilizado por Sodexo para compras a través de Convenio Marco.</p>'
        },
        {
          id: 8,
          selected: false,
          question: '¿Cuál es la diferencia de E-voucher y tarjeta de beneficios?',
          answer: '<p>Los grandes retails utilizan tarjeta para comprar en sus diferentes tiendas, por tanto, cuando quieras comprar alguna de las categorías en el retail, el medio para realizar esa compra será una tarjeta física y/o electrónica. En el caso que decidas comprar en otro asociado, tu medio de pago será un E-voucher.</p>'
        },
        {
          id: 9,
          selected: false,
          question: '¿Cuándo se factura la orden de compra?',
          answer: '<p>La orden de compra puede ser facturada de forma inmediata o contra entrega de los productos. Tú puedes elegir la forma de facturación indicándolo en las observaciones de la orden de compra.</p>'
        },
        {
          id: 10,
          selected: false,
          question: '¿Por qué recibo una factura de Sodexo y una del comercio que entrega los productos?',
          answer: '<p>Al momento de generar la Orden de compra Sodexo emite una factura exenta por la compra. Esta factura exenta es que debe ser registrada en su contabilidad y pagada a Sodexo. Al momento de la entrega de los productos el comercio emite una factura afecta, que solo es válido como comprobante de entrega y para uso de garantía de los productos, esta factura no debe ser contabilizada ni pagada al comercio.</p>'
        },
        {
          id: 11,
          selected: false,
          question: '¿Cuándo se le pagará al proveedor (Merchant)?',
          answer: '<p>Al proveedor se le pagará una vez que apruebes la recepción conforme de tu pedido.</p>'
        },
        {
          id: 12,
          selected: false,
          question: '¿Dónde puedo revisar el saldo disponible de las tarjetas solicitadas?',
          answer: '<p>Para revisar el saldo disponible de las tarjetas de beneficios solicitadas debes ingresar a la plataforma con tu sesión y buscar el pedido u orden de compra asociada a ese pedido. Haz clic y verás el detalle del saldo y el consumo realizado por cada una de las tarjetas.</p>'
        },
        {
          id: 13,
          selected: false,
          question: '¿Cómo puedo obtener el detalle de las tarjetas de mi pedido?',
          answer: '<p>Para ver el detalle de las tarjetas solicitadas debes ingresar a la plataforma con tu sesión y buscar el pedido u orden de compra asociada a ese pedido. Haz clic y verás el detalle de cada una de las tarjetas entregadas en el pedido. Si las tarjetas son personalizadas los datos que podrás revisar es el rut, nombre completo, monto de la carga de la tarjeta, el número de la tarjeta asignada y su consumo. En el caso de las tarjetas no personalizadas podrás revisar monto de la carga de la tarjeta, el número de la tarjeta asignada y su consumo.</p>'
        },
        {
          id: 14,
          selected: false,
          question: 'Necesito contactarme con mi ejecutivo',
          answer: '<p>Para más información sobre su ejecutivo puede llamar a nuestro equipo de Servicio al Cliente al 22 487 02 00 opción 1.</p>'
        }
      ]
    }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private salesforceService: SalesForceService,
    private modalService: BsModalService) { }

  ngOnInit() {
    this.currentUser = this.dataService.getCurrentUser();
    this.getCompanyAndFiscalId();
    this.activeQuestions = [];
    this.contactForm = this.formBuilder.group({
      subject: ['consulta', Validators.required],
      message: ['', Validators.required],
      document: ['']
    });
    const company = this.dataService.getCompanySelected();
    if (company.agreements.some(a => a.serviceId === env.serviceIds.alimentacion.serviceId)) {
        this.categories = this.categoriesAlimentacion;
        this.showVideoTutorial = true;
    } else if (company.agreements.some(a => a.serviceId === env.serviceIds.beneficios.service.serviceId)) {
        this.categories = this.categoriesBeneficios;
    }
    this.categoriesFiltered = this.categories;

    this.videoTutoriales = env.videoTutoriales;
  }

  get f() { return this.contactForm.controls; }

  searchQuestions(txt) {
    if (txt !== '' && txt.length > 2) {
      let questions;
      const search = txt.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      this.categoriesFiltered = [];
      this.categories.forEach(category => {
        questions = category.questions.filter(q => {
          return q.question.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(search)
          || q.answer.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(search);
        });
        questions.forEach((q, i) => {
          this.activeQuestions.push('ngb-panel-' + q.id);
        });

        if (questions.length > 0) {
          this.categoriesFiltered.push({ id: category.id, name: category.name, questions });
        }
      });
    } else {
      this.categoriesFiltered = this.categories;
      this.activeQuestions = [];
    }
  }

  onSubmitContactForm() {
    if (this.contactForm.valid) {
      this.loading = true;
      this.isValid = false;
      this.getDetailsFromCompanyAndUser();
      this.salesforceService.createCase(this.caseSalesForce).subscribe(dataResponse => {
        const response: WrapperResponse = new WrapperResponse(dataResponse);
        if (response.ok) {
          this.loading = false;
          this.contactFormSubmited = true;
          this.contactFormComplete = true;
          this.isValid = true;
        } else {
          this.loading = false;
          this.isValid = true;
          Swal.fire('Error al enviar formulario', 'Contactese por otro medio de comunición.', 'error');
        }
      }, error => {
        this.loading = false;
        this.isValid = true;
        Swal.fire('Error al enviar formulario', 'Comunícate por otro medio de comunicación.', 'error');
        console.error('Error: ', error);
      });
    }
  }

  getDetailsFromCompanyAndUser() {
    const company = this.dataService.getCompanySelected();
    const userData: any = JSON.parse(localStorage.getItem('currentUser'));
    this.caseSalesForce.type = this.contactForm.controls.subject.value;
    this.caseSalesForce.suppliedName = userData.name.toString() + ' ' + userData.lastName.toString();
    this.caseSalesForce.companyName = company.legalName;
    const fiscalIdWithOutDv = company.fiscalId.slice(0, -1);
    const dv = company.fiscalId.slice(-1).toUpperCase();
    this.caseSalesForce.companyFiscalId = fiscalIdWithOutDv + '-' + dv;
    this.caseSalesForce.description = this.contactForm.controls.message.value.toString();
    this.caseSalesForce.suppliedEmail = userData.email;
    this.caseSalesForce.sourceUrl = window.location.origin + '/clientes';
    this.caseSalesForce.subject = 'Portal clientes web - ' + this.getServiceName();
    this.caseSalesForce.suppliedPhone = (this.dataService.getCurrentUser().phone === undefined
      || this.dataService.getCurrentUser().phone === null) ? null :
        '+' + this.dataService.getCurrentUser().countryDial + '' + this.dataService.getCurrentUser().phone;
    this.caseSalesForce.webBrowser = this.getWebBrowser();
    if (this.fileSeleccted) {
      this.attachmentDTO.filename = this.fileSeleccted.name;
      this.attachmentDTO.base64 = this.attachmentDTO.base64.substring(this.attachmentDTO.base64.indexOf(',') + 1,
        this.attachmentDTO.base64.length);
      this.caseSalesForce.attachment = this.attachmentDTO;
    }
  }

  getServiceName() {
    const serviceName = env.services
      .filter(service => service.serviceId === this.dataService.getCompanySelected().agreements[0].serviceId)
      .map(service => service.serviceName)[0];
    return serviceName;
  }

  selecctedFile(file: any) {
    this.fileSeleccted = file.target.files[0];
    const fileName: string = this.fileSeleccted.name;
    const fileExtension: string = fileName.split('.').pop();
    const allowedExtensions: string[] = ['JPG', 'PNG', 'PDF', 'DOCX'];
    if (!allowedExtensions.includes(fileExtension.toUpperCase())) {
      Swal.fire('Error en la carga del archivo', 'Archivo con extensión no permitida', 'error');
      this.fileSeleccted = undefined;
      file.target.value = '';
    } else {
      const sizeByte: number = file.srcElement.files[0].size;
      const sizeKilobyte: number = sizeByte / 1024;
      if (sizeKilobyte >= this.maxValueFile) {
        Swal.fire('Error en la carga del archivo', 'Archivo excede peso máximo', 'error');
        this.fileSeleccted = undefined;
        file.target.value = '';
      } else {
        const myReader: FileReader = new FileReader();
        myReader.onloadend = (e) => {
          this.attachmentDTO.base64 = myReader.result.toString();
        };
        myReader.readAsDataURL(this.fileSeleccted);
      }
    }
  }

  getCompanyAndFiscalId() {
    const company = this.dataService.getCompanySelected();
    this.companyNameRefresh = company.fantasyName;
    this.companyrefresh = company.fiscalId;
  }

  openVideo(video) {
    this.modalService.show(
      VideoModalComponent, { class: 'modal-xl',
      initialState: { video }
    });
  }

  getWebBrowser() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 || agent.indexOf('opera') > -1:
        return 'opera';
      case agent.indexOf('chrome') > -1:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

}
