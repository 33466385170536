<form #form="ngForm" class="row" (ngSubmit)="onSubmit(form.value)">
  <ng-container *ngIf="editMode; else newForm">
    <div class="col-md-6 summary-item mb-4">
      <app-summary-item label="Nombre" value="{{ beneficiary.fullName }}"></app-summary-item>
    </div>
    <div class="col-md-6 summary-item mb-4">
        <app-summary-item label="Rut" value="{{ beneficiary.legalId | rut }}"></app-summary-item>
    </div>
  </ng-container>
  <ng-template #newForm>
    <div class="col-md-6 form-group mb-5" [ngClass]="{'invalid-form': name.invalid && name.dirty}">
      <label for="name">Nombre <span class="text-danger">*</span></label>
      <input type="text" class="form-control" id="name" placeholder="Ingresa el nombre del usuario" maxlength="15"
        [ngModel]="model.name" name="name" #name="ngModel" required>
      <div *ngIf="name.invalid && name.dirty && name.value != ''" class="error-message">
        <app-icon icon="close"></app-icon>
        <span>Ingresa sólo letras</span>
      </div>
    </div>
    <div class="col-md-6 form-group mb-5" [ngClass]="{'invalid-form': lastName.invalid && lastName.dirty}">
      <label for="lastName">Apellido Paterno <span class="text-danger">*</span></label>
      <input type="text" class="form-control" id="lastName" placeholder="Ingresa el apellido paterno del beneficiario" maxlength="15"
        [ngModel]="model.lastName" name="lastName" #lastName="ngModel" required>
      <div *ngIf="lastName.invalid && lastName.dirty && lastName.value != ''" class="error-message">
        <app-icon icon="close"></app-icon>
        <span>El campo es obligatorio</span>
      </div>
    </div>
    <div class="col-md-6 form-group mb-5" [ngClass]="{'invalid-form': secondLastName.invalid && secondLastName.value != ''}">
      <label for="secondLastName">Apellido Materno</label>
      <input type="text" class="form-control" id="secondLastName" placeholder="Ingresa el apellido materno del beneficiario"
        [ngModel]="model.secondLastName" name="secondLastName" #secondLastName="ngModel" maxlength="15">
      <div *ngIf="secondLastName.invalid && secondLastName.value != ''" class="error-message">
        <app-icon icon="close"></app-icon>
        <span>El campo es obligatorio</span>
      </div>
    </div>
    <div class="col-md-6 form-group mb-5" [ngClass]="{'invalid-form': (legalId.invalid && legalId.dirty && legalId.value != '') || !isUnique}">
      <label for="legalId">Rut <span class="text-danger">*</span></label>
      <input type="text" class="form-control" id="legalId" placeholder="Ingresa el rut del usuario" maxlength="15"
        [ngModel]="model.legalId" name="legalId" #legalId="ngModel" (input)="checkUnique($event.target.value)" required validateRut formatRut>
      <div *ngIf="legalId.invalid && legalId.dirty && legalId.value != ''" class="error-message">
        <app-icon icon="close"></app-icon>
        <span>Rut inválido</span>
      </div>
      <div *ngIf="!isUnique" class="error-message">
        <app-icon icon="close"></app-icon>
        <span>Rut ya existe</span>
      </div>
    </div>
  </ng-template>
  <div class="col-md-6 form-group mb-5" *ngIf="branchOffices">
    <ng-container *ngIf="branchOffices.length > 1; else readonlyBranchOffice">
      <label for="branchOffice">Sucursal <span class="text-danger">*</span></label>
      <select class="form-control custom-select" id="branchOffice" name="branchOffice"
        [ngModel]="model.branchOffice" #branchOffice="ngModel" (change)="selectBranchOffice($event.target.value)" required>
        <option value="null" selected disabled *ngIf="!editMode">Seleccione una sucursal</option>
        <option [value]="bo.address" *ngFor="let bo of branchOffices" [selected]="bo.address == branchOffice.value">{{ bo.address }}</option>
      </select>
    </ng-container>
    <ng-template #readonlyBranchOffice>
      <app-summary-item label="Sucursal" value="{{ model.branchOffice }}"></app-summary-item>
    </ng-template>
  </div>
  <div class="col-md-6 form-group mb-5" *ngIf="costCenters">
    <ng-container *ngIf="costCenters.length > 1; else readonlyCostCenter">
      <label>Centro de costo <span class="text-danger">*</span></label>
      <select class="form-control custom-select" id="costCenter" name="costCenter"
        [ngModel]="model.costCenter" #costCenter="ngModel" required>
        <option value="null" selected disabled *ngIf="!editMode">Selecciona un centro de costo</option>
        <option [value]="cc.name" *ngFor="let cc of costCenters" [selected]="cc.name == costCenter.value">{{ cc.name }}</option>
      </select>
    </ng-container>
    <ng-template #readonlyCostCenter>
      <app-summary-item label="Centro de costo" value="{{ model.costCenter }}"></app-summary-item>
    </ng-template>
  </div>
  <div class="col-12 text-center">
    <button app-button size="lg" type="submit" [disabled]="form.invalid">Guardar</button>
  </div>
</form>
