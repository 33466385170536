import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export abstract class AuthService {

  abstract login(email: string, password: string): Observable<any>;

  abstract getToken(token: any): string;

  abstract refreshToken(): Observable<any>;

  abstract logout(redirect?: any): void;

}
