<div class="form-footer">
  <span class="form-footer__previous">
    <button app-button size="lg" icon="chevron-left" styling="link" type="button" (click)="handleClickPrevBtn()">{{ prevBtnTxt }}</button>
  </span>
  <span class="form-footer__next">
    <ng-template [ngIf]="nextSpanTxt">
      <span class="form-footer__label" [ngClass]="{'form-footer__label--disabled': !nextIsEnabled}">{{nextSpanTxt}}</span>
    </ng-template>

    <ng-template [ngIf]="hasCheckbox">
      <label class="form-check form-check-inline pr-4">
        <input type="checkbox" class="form-check-input" [checked]="nextIsEnabled" (click)="handleCheck()">
        <span class="form-check-label">{{ checkboxTxt }}</span>
      </label>
    </ng-template>

    <button app-button size="lg" type="submit"
      [disabled]="!nextIsEnabled"
      (click)="handleClickNextBtn()"
    >{{nextBtnTxt}}</button>
  </span>
</div>
