import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../services/auth/auth.service';
import { Observable, throwError } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { DataService } from '../services/data.service';
import { catchError, switchMap, finalize } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private dataService: DataService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.dataService.getToken();
    if (token) {
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return this.handle401Error(request, next);
      } else {
        return throwError(error);
      }
    }));
  }

  addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`,
      Pragma: 'no-cache'
    });

    if ((env.multipartUrl.some(u => request.url.includes(u)))) {
      headers = headers.delete('Content-Type');
    }

    return request.clone({ headers });
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Nueva solicitud con token actualizado
    return this.authService.refreshToken().pipe(
      switchMap((data: any) => {
        if (data && data.status === 200 && data.body && data.body.ok && data.body.data) {
          this.dataService.setToken(data.body.data);
          return next.handle(this.addToken(req, data.body.data));
        }

        // Cerrar sesión: Si no se refresca el token
        this.authService.logout();
        return throwError('Error al actualizar el token.');
      }),
      catchError(error => {
        // Cerrar sesión: Si el servicio que refresca el token falla
        this.authService.logout();
        return throwError(error);
      }));
  }

}
