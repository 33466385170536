import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/core/services/data.service';
import { LoginService } from 'src/app/core/services/auth/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CiamService } from 'src/app/core/services/ciam/ciam.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { environment as env } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import dataBeneficios from 'src/app/core/fixtures/beneficios-login-data.json'
import dataAlimentacion from 'src/app/core/fixtures/alimentacion-login-data.json'
import dataMiPluxee from 'src/app/core/fixtures/mipluxee-login-data.json'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  token: any;
  userData: any;
  loginForm: any;
  error: boolean = false;
  logged: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private loginService: LoginService,
    private authService: AuthService,
    private ciamService: CiamService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    if (this.route.snapshot.queryParams.servicio !== undefined) {
      this.dataService.setService(this.route.snapshot.queryParams.servicio);
    }

    this.token = this.authService.getToken(this.route.snapshot.queryParams.token);
    if (this.ciamService.isValidToken(this.token)) {
      this.dataService.setToken(this.token);
      this.loginService.login(this.token);
    }

    this.loginForm = this.formBuilder.group({
      email: ['', { validators: [ Validators.required, Validators.email ], updateOn: 'change' }],
      password: ['', { validators: [ Validators.required ], updateOn: 'change' }]
    });
  }

  login() {
    window.location.href = env.ciam.baseUrl + env.ciam.login;
  }

  fakeLogin() {
    this.error = true;
    if ( this.loginForm.valid ) {
      if(this.loginForm.controls.email.value === 'conveniomarco.beneficios@pluxeebeneficiospublicos.cl' && this.loginForm.controls.password.value === 'Sodexo2024' ) {
        this.error = false;
        Object.keys(dataBeneficios).forEach((key) => {
          localStorage.setItem(key, JSON.stringify(dataBeneficios[key]));
        })
        this.router.navigateByUrl('/beneficios/pedidos');
      }
      if(this.loginForm.controls.email.value === 'conveniomarco.alimentacion@pluxeebeneficiospublicos.cl' && this.loginForm.controls.password.value === 'Sodexo2024' ) {
        this.error = false;
        Object.keys(dataAlimentacion).forEach((key) => {
          localStorage.setItem(key, JSON.stringify(dataAlimentacion[key]));
        })
        this.router.navigateByUrl('/alimentacion/pedidos');
      }
      if(this.loginForm.controls.email.value === 'conveniomarco.usuario@pluxeebeneficiospublicos.cl' && this.loginForm.controls.password.value === 'Sodexo2024' ) {
        this.error = false;
        Object.keys(dataMiPluxee).forEach((key) => {
          localStorage.setItem(key, JSON.stringify(dataMiPluxee[key]));
        })
        this.router.navigateByUrl('/mipluxee/mi-tarjeta');
      }
    }
  }

  signUp() {
    window.location.href = env.ciam.signUp;
  }

}
