import { AttachmentDTO } from '../sendMailing';

export class CaseSalesForce{
  suppliedName: string;
  suppliedEmail: string;
  suppliedPhone: string;
  subject: string;
  description: string;
  companyFiscalId: string;
  companyName: string;
  sourceUrl: string;
  type: string;
  webBrowser: string;
  attachment: AttachmentDTO;
}
