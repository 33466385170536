import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from '../data.service';
import { CookieService } from 'ngx-cookie-service';
import { CiamService } from '../ciam/ciam.service';
import { environment as env } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthCiamService implements AuthService {

  constructor(
    private spinner: NgxSpinnerService,
    private dataService: DataService,
    private cookie: CookieService,
    private ciamService: CiamService) { }

  login(email: string, password: string): Observable<any> {
    throw new Error('Método no implementado.');
  }

  getToken(token: any): string {
    let tokenResponse: string = null;
    if (this.ciamService.isValidToken(this.cookie.get('ciamToken'))) { // Token en cookie
      tokenResponse = this.cookie.get('ciamToken');
      return tokenResponse;
    } else if (this.ciamService.isValidToken(this.dataService.getToken())) { // Token en local storage
      tokenResponse = this.dataService.getToken();
      return tokenResponse;
    } else if (!env.production && token && token.trim() !== '') { // Token por URL - Activo solo para DEV
      if (this.ciamService.isValidToken(token)) {
        tokenResponse = token;
        return tokenResponse;
      }
    }
    return tokenResponse;
  }

  refreshToken(): Observable<any> {
    const token = this.dataService.getToken();
    return this.ciamService.refreshToken(token);
  }

  logout(redirect: any = true) {
    localStorage.clear();
    this.spinner.hide();
    this.cookie.deleteAll('/');
    if (redirect) {
      window.location.href = "/";
    }
  }

}
