<div class="select-multiple-search select-search select-search--expanded" [ngClass]="{ withConfirm : 'select-search--with-confirm' }">
  <div class="input-group input-group-select">
    <input type="text" class="select-search__input" [id]="searchInputId" [placeholder]="searchInputPlaceholder"
      (input)="search($event.target.value)" (focus)="handleOpenSearchBody()">
    <div *ngIf="expandable" class="input-group-append" (click)="toggleBody()">
      <span>
        <app-icon *ngIf="!showBody" icon="chevron-down"></app-icon>
        <app-icon *ngIf="showBody" icon="chevron-up"></app-icon>
      </span>
    </div>
  </div>
  <div class="select-search__body" *ngIf="showBody">
    <label class="form-check">
      <input type="checkbox" class="form-check-input" [(ngModel)]="allChecked" (click)="toggleCheckAll($event.target.checked)">
      <span class="form-check-label">{{ allSelectorLabel }}</span>
    </label>
    <ul class="select-search__options">
      <li *ngFor="let option of optionsFiltered; let i = index;">
        <label class="form-check">
          <input type="checkbox" class="form-check-input" [id]="selectButtonId + '-' + option.id" [value]="option.id"
            [checked]="outputSelected.indexOf(option.id) > -1" (change)="selectOption($event.target.value)">
          <span class="form-check-label">{{ option.value | titlecase }}</span>
        </label>
      </li>
    </ul>
    <div *ngIf="withConfirm" class="text-center">
      <button type="submit" app-button size="lg" [id]="selectButtonId" [disabled]="!isDisabled()" (click)="handleSubmit()">
        Seleccionar
      </button>
    </div>
  </div>
</div>
