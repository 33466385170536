import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { VideoTutorial } from 'src/app/core/model/videotutorial.model';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})

export class VideoModalComponent implements OnInit {

  safeVideoUrl: any;
  @Input() public video: VideoTutorial;

  constructor(private sanitizer: DomSanitizer, public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.safeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.video.url);
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
