<div class="card card--link" [ngClass]="{'card--draft': isDraft}" (click)="handleClickReadBtn()">
  <header class="card-header">
    <span class="card-status {{ statusClass }}">{{ statusName }}</span>
  </header>
  <main class="card-body">
    <div class="card-data card-data--lg" *ngIf="type">
      <span class="card-data__type">{{ type }}</span>
    </div>
    <div class="card-data card-data--lg">
      <span class="card-data__label">{{ title }}</span>
      <span class="card-data__value">{{ value }}</span>
    </div>
    <div class="card-data card-data--lg subtitle" *ngIf="subtitle">
      <span class="card-data__small2">{{ subtitle }}</span>
    </div>
    <!-- DATA -->
    <div class="card-data" *ngFor="let item of data">
      <span class="card-data__label">{{ item.key }}</span>
      <span class="card-data__value">{{ item.value }}</span>
    </div>

    <div class="card-data" *ngIf="date">
      <span class="card-data__value--date">{{ date }}</span>
    </div>

    <div class="card-data card-data--expires" *ngIf="expDate">
      <span class="text-muted">Expira </span>
      <span class="card-data__small">{{ expDate }}</span>
    </div>
  </main>
  <footer class="card-footer" [ngClass]="{'card-draft__actions': isDraft}">
    <ng-template [ngIf]="!isDraft" [ngIfElse]="draftActions">
      <span class="card-footer__label" *ngIf="amountTitle">{{ amountTitle }}</span>
      <span class="card-footer__value">{{ amount }}</span>
    </ng-template>
    <ng-template #draftActions>
      <div class="card-draft__action" *ngIf="this.continueOrder">
        <button app-button styling="link" size="sm" class="card-draft__continue" (click)="handleClickEditBtn($event)">Continuar pedido</button> 
      </div>
      <div class="card-draft__action" *ngIf="this.cancelOrder">
        <button app-button styling="link" size="sm" class="card-draft__cancel" (click)="handleClickCancelBtn($event)">
          Anular
        </button>
      </div>
    </ng-template>
  </footer>
</div>
