// tslint:disable: max-line-length
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng9RutModule } from 'ng9-rut';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './features/home/home.module';
import { DashboardModule } from './features/dashboard/dashboard.module';
import { HelpCenterComponent } from './features/dashboard/alimentacion/help-center/help-center.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// LOCALE
import { registerLocaleData } from '@angular/common';
import localeEsCL from '@angular/common/locales/es-CL';

// INTERCEPTORS
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { HttpsInterceptor } from './core/interceptors/https.interceptor';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { HeaderInterceptor } from './core/interceptors/header.interceptor';

// OTRAS DEPENDENCIAS
import { SvgSpriteComponent } from './core/components/svg-sprite/svg-sprite.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UserIdleModule } from 'angular-user-idle';
import { BsModalService } from 'ngx-bootstrap';
import { ConfirmExitGuard } from './core/guards/confirmExit.guard';

// DIRECTIVES
import { RequestInterceptor } from './core/interceptors/request.interceptor';

// ENVIRONMENT
import { environment as env } from 'src/environments/environment';

// SERVICES
import { CookieService } from 'ngx-cookie-service';
import { ExcelService } from './core/services/util/xlsx.service';
import { AuthService } from './core/services/auth/auth.service';
import { AuthCiamService } from './core/services/auth/auth-ciam.service';
import { LoginService } from './core/services/auth/login.service';
import { LoginCiamService } from './core/services/auth/login-ciam.service';
import {SlimLoadingBarModule} from '@cime/ngx-slim-loading-bar';
registerLocaleData(localeEsCL, 'es-CL');

@NgModule({
  declarations: [
    AppComponent,
    HelpCenterComponent,
    SvgSpriteComponent
  ],
  imports: [
    DashboardModule,
    HomeModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    Ng9RutModule,
    SlimLoadingBarModule.forRoot(),
    //  Opcionalmente, puede establecer el tiempo para 'idle', 'timeout' y 'ping' en segundos.
    //  Valores predeterminados: 'idle' es 60 (1 minuto), 'timeout' es 10 segundos y 'ping' es 120 (2 minutos).
    UserIdleModule.forRoot({ idle: env.userIdle.idle, timeout: env.userIdle.timeout, ping: env.userIdle.ping })
  ],

  exports: [BrowserModule, SlimLoadingBarModule],
  providers: [
    BsModalService,
    CookieService,
    ExcelService,
    ConfirmExitGuard,
    { provide: LOCALE_ID, useValue: 'es-CL' },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: AuthService, useClass: AuthCiamService },
    { provide: LoginService, useClass: LoginCiamService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
