import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class ExecutiveService{
    private executiveSubject = new BehaviorSubject<any>(null);
    public executive$= this.executiveSubject.asObservable();

    updateExecute(newValue:any){
        this.executiveSubject.next(newValue);
    }
}