import { Pipe, PipeTransform } from '@angular/core';

export function isUndefined(value: any) {
  return typeof value === 'undefined';
}

export function isNull(value: any) {
  return value === null;
}

export function isNumber(value: any) {
  return typeof value === 'number';
}

export function isString(value: any) {
  return typeof value === 'string';
}

export function isBoolean(value: any) {
  return typeof value === 'boolean';
}

export function isObject(value: any) {
  return value !== null && typeof value === 'object';
}

export function isNumberFinite(value: any) {
  return isNumber(value) && isFinite(value);
}

export function extractDeepPropertyByMapKey(obj: any, map: string): any {
  const keys = map.split('.');
  const head = keys.shift();

  return keys.reduce((prop: any, key: string) => {
    return !isUndefined(prop) && !isNull(prop) && !isUndefined(prop[key]) ? prop[key] : undefined;
  }, obj[head || '']);
}

export function extractDeepPropertyByParentMapKey(obj: any, map: string): any {
  const keys = map.split('.');
  const tail = keys.pop();
  const props = extractDeepPropertyByMapKey(obj, keys.join('.'));

  return { props, tail };
}

export function getKeysTwoObjects(obj: any, other: any): any {
  return [...Object.keys(obj), ...Object.keys(other)].filter((key, index, array) => array.indexOf(key) === index);
}

export function isDeepEqual(obj: any, other: any): any {
  if (!isObject(obj) || !isObject(other)) {
    return obj === other;
  }

  return getKeysTwoObjects(obj, other).every(
    (key: any): boolean => {
      if (!isObject(obj[key]) && !isObject(other[key])) {
        return obj[key] === other[key];
      }
      if (!isObject(obj[key]) || !isObject(other[key])) {
        return false;
      }

      return isDeepEqual(obj[key], other[key]);
    }
  );
}

// tslint:disable no-bitwise
@Pipe({ name: 'filterByImpure', pure: false })
export class FilterByImpurePipe implements PipeTransform {
  transform<T>(input: T, props: Array<string>, search?: any, strict?: boolean): T;
  transform(input: any[], props: Array<string>, search?: any, strict?: boolean): any[];
  transform(input: any, props: Array<string>, search: any = '', strict: boolean = false): any {
    if (
      !Array.isArray(input) ||
      (!Array.isArray(search) && !isString(search) && !isNumberFinite(search) && !isBoolean(search))
    ) {
      return input;
    }

    const terms = String(search)
      .toLowerCase()
      .split(',');

    return input.filter(obj => {
      return props.some(prop => {
        return terms.some(term => {
          const value = extractDeepPropertyByMapKey(obj, prop);
          const compareTerm = term.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          /* tslint:disable */
          const { props, tail } = extractDeepPropertyByParentMapKey(obj, prop);

          if (isUndefined(value) && !isUndefined(props) && Array.isArray(props)) {
            return props.some(parent => {
              const str = String(parent[tail]).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

              return strict ? str === compareTerm : !!~str.indexOf(compareTerm);
            });
          }

          if (isUndefined(value)) {
            return false;
          }

          const strValue: string = String(value).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '');

          return strict ? compareTerm === strValue : !!~strValue.indexOf(compareTerm);
        });
      });
    });
  }
}
