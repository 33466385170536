import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpsInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // clona los requests y los fuerza a que siempre sea HTTPS
    const httpsReq = req.clone({
      //url: req.url.replace('http://', 'https://')
    });

    return next.handle(httpsReq);
  }
}
