import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss']
})
export class CheckboxListComponent implements OnInit {

  @Input() options: any;
  @Input() selected: any[] = [];
  @Output() selectedOptions = new EventEmitter();

  selectedOptionsData = [];

  constructor() { }

  ngOnInit() {
    this.selectedOptionsData = this.selected;
  }

  clickOption(event, children, parent, siblings) {
    const currentOptionId = event.target.value;
    const currentOptionCheck = event.target.checked;
    this.validateOption(currentOptionId);

    if (parent) {
      this.checkParentOption(parent, siblings, currentOptionCheck);
    }

    if (typeof children !== 'undefined') {
      this.checkChildrenOptions(children, currentOptionCheck);
    }

    this.selectedOptions.emit({
      selectedOptions: this.selectedOptionsData,
      currentOptionId,
    });
  }

  checkParentOption(parent, siblings, isCurrentOptionChecked) {
    const parentIndex = this.getIndex(parent.toString());

    if (isCurrentOptionChecked) {
      const siblingsIds = siblings.map(sibling => sibling.id.toString());

      // if every sibling is checked, must be check their parent
      if (this.uncheckedSiblings(siblingsIds) === 0) {
        this.checkOption(parent.toString());
      }
    } else {
      if (parentIndex > -1) {
        this.uncheckOption(parentIndex);
      }
    }
  }

  checkChildrenOptions(children, isCurrentOptionChecked) {
    children.forEach(child => {
      const index = this.getIndex(child.id.toString());
      if (isCurrentOptionChecked) {
        if (index === -1) {
          this.checkOption(child.id);
        }
      } else {
        if (index > -1) {
          this.uncheckOption(index);
        }
      }
    });
  }

  uncheckedSiblings(siblings) {
    return siblings.filter(sibling => this.getIndex(sibling) === -1).length;
  }

  validateOption(option) {
    const index = this.getIndex(option);

    if (index === -1) {
      this.checkOption(option);
    } else {
      if (index > -1) {
        this.uncheckOption(index);
      }
    }
  }

  checkOption(option) {
    this.selectedOptionsData.push(option.toString());
  }

  uncheckOption(optionIndex) {
    this.selectedOptionsData.splice(optionIndex, 1);
  }

  getIndex(option) {
    return this.selectedOptionsData.indexOf(option);
  }
}
