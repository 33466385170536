import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpParams } from '@angular/common/http';
import { DataService } from '../data.service';
import { EmissionLoadOrder } from '../../model/emissionloadorder';
import { EmissionOrderDeliveryDate } from '../../model/EmissionOrderDeliveryDate';
import { CreateMealOrderModel } from '../../model/createMealOrder.model';
import { UpdateOrderStatus } from '../../model/updateOrderStatus.model';
import { FilterModel } from '../../model/filter.model';

const routes = {
  orders: '/orders',
  order: (id: number) => `/order/${id}`,
  downloadProductInvoice: (id: number, productId: number) => `/order/${id}/${productId}/productInvoice`,
  downloadPayOrder: (id: number) => `/order/${id}/productInvoice`,
  orderNew: '/orders',
  orderNewCargaSaldo: '/orders/carga-saldo',
  orderNewRescateSaldo: '/orders/rescate-saldo',
  orderAos: '/orders/aos-home',
  orderReport: '/orders/report',
  orderDetailAos: (orderId: number) => `/orders/${orderId}/created`,
  getEntryOrder: (orderId: number) => `/orders/entry-order/${orderId}`,
  getBeneficiaryOrdersNotEnded: (fiscalId: number, type: string) => `/orders/list-not-ended/${fiscalId}?type=${type}`,
  getBeneficiaryOrdersEnded2p: '/orders/list-not-ended2/',
  getBeneficiaryOrdersEnded: (fiscalId: number) => `/orders/list-ended/${fiscalId}`,
  getBeneficiaryOrdersEnded2: '/orders/list-ended2/',
  changeStatus: '/orders/order-datails',
  urlPurchaseSummaryDocumentByOrderId: '/orders/document',
  getDetailFichaPedido: (orderId: number) => `/orders/details/${orderId}`,
  orderRequestActivateGiftCard: '/orders/giftcard',
  urlOderandIncrementId: '/orders/parentId',
  getGiftcardDetails: (orderId: number) => `/orders/giftcard/${orderId}`,
  getGiftCards: (fiscalId: string, active: boolean, categoryId: number) => `/orders-giftcard/${fiscalId}?active=${active}&categoryId=${categoryId}`,
  getBeneficiaryOrderIncreaseNotEnded: (fiscalId: number) => `/orders/increase-list-not-ended/${fiscalId}`,
  getOrderByOrderIdToShow: (orderId: number) => `/orders/to-show-user/${orderId}`,
  uploadExcelFileToMealOrder: (fiscalId: string,userProfile: number,isPublic:boolean) => `/orders/meal/uploadExcel?fiscalId=${fiscalId}&userProfile=${userProfile}&isPublic=${isPublic}`,
  branchesByFiscalId: () => `/orders/branchesByFiscalId`,
  defaultBalanceReleaseDate: () => '/orders/get-default-balance-release-date',
  defaultBalanceExpirationDate: () => '/orders/get-default-balance-expiration-date',
  addCardsToOrder: (orderId: number, userId: number) => `/orders/meal/cards/${orderId}/${userId}/card-items/customized`,
  createNewMealOrder: () => `/orders/meal/cards`,
  processCardOrder: (orderId: number, userId: number) => `/orders/meal/cards/process-order/${orderId}/${userId}/customized`,
  mealOrders: (companyFiscalId: string) => `/orders/meal-orders?companyFiscalId=${companyFiscalId}`,
  downloadMealExcel: () => `/orders/meal/downloadExcel`,
  orderDeliveryDate: () => `/orders/deliveryDate`,
  holidayNotWorkingDays: (year: number, month: number, startDay: number) =>
    `/holiday/get-working-days-of-month?year=${year}&month=${month}&startDay=${startDay}`,
  downloadPdfFiles: () => `/orders/meal/downloadOrderPdf`,
  orderDetail: (orderId: number) => `/orders/meal/cards/order-detail/${orderId}`,
  billingData: () => `/orders/billingData`,
  downloadMealExcelWithBeneficiaries: () => `/orders/meal/download-excel/beneficiary`,
  uploadExcelMassiveBeneficiaries: (fiscalId: string, affiliatedName: string) =>
    `/orders/massive/giftcard/validateExcel?fiscalId=${fiscalId}&affiliatedName=${affiliatedName}`,
  uploadExcelMassiveBeneficiariesRecharge: (fiscalId: string, affiliatedName: string) =>
    `/orders/massive/giftcard/rechargeValidateExcel?fiscalId=${fiscalId}&affiliatedName=${affiliatedName}`,
  cardParameterByName: (parameterName: string) => `/orders/getCardParameterByName?parameterName=${parameterName}`,
  validityBalance: (fiscalId: string, serviceId: number) =>
    `/company-card/get-validity-balance?fiscalId=${fiscalId}&serviceId=${serviceId}`,
  getDraftOrderWithDetail: () => '/orders/meal-orders/detail',
  ordersQuantityByStatus: () => `/orders/quantity/by-status`,
  cancelMealOrder: () => `/orders/cancel`,
  updateMealOrder: (orderId: number) => `/orders/update-order?orderId=${orderId}`,
  updateMealStatusOrder: () => `/orders/meal/update-status`,
  validateAccountDocument: () => `/orders/validate/account-document`,
  disabledDocumentReference: (id: number) => `/orders/document-reference/${id}`,
  getCutoffDate: () => '/orders/client-billing-parameters/get-cutoff-date',
  updateDocumentReference: (orderId: number) => `/orders/meal-orders/${orderId}/document-reference`,
  getBeneficiariesByOrderAndBranch: () => `/orders/order-detail/beneficiaries`,
  getNewCardsAndUpdateOrder: () => `/orders/meal/get-quantity-cards-order`,
  getMealBeneficiaryDetail: () => `/orders/beneficiary/detail`,
  getListOfOrdersFromOdooForTracking: (fiscalId: number) => `/orders/listOfOrdersFromOdooForTracking/${fiscalId}`,
  downloadSummaryOrderPdf: (orderId: number, fiscalId: string) => `/orders/meal/downloadSummaryOrderPdf?orderId=${orderId}&fiscalId=${fiscalId}`,
  sendMailConfirmMultipleOrder: () => `/emails/send/emails-confirm-multiple-order`,
  mealBranchesByFiscalId: () => `/orders/meal-branchesByFiscalId`,
  orderInProgress: (fiscalId: string, serviceId: number, type: number[]) => `/orders/orderInProgress?fiscalId=${fiscalId}&serviceId=${serviceId}&type=${type}`,
};

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(private apiService: ApiService, private dataService: DataService) { }

  /**
   * Obtiene la lista de todas las ordenes desde odoo por fiscalId.
   * @param fiscalId: Identificador (RUT) del fiscal.
   */
   getListOfOrdersFromOdooForTracking(fiscalId: number) {
    return this.apiService.get(routes.getListOfOrdersFromOdooForTracking(fiscalId));
  }

  getExcelFileWithBeneficiaries(fiscalId: string, userProfile: number) {

    let params = new HttpParams();

    if (fiscalId && fiscalId !== null) {
      params = params.set('fiscalId', fiscalId.toString());
    }
    if (userProfile && userProfile !== null) {
      params = params.set('userProfile', userProfile.toString());
    }

    return this.apiService.download(routes.downloadMealExcelWithBeneficiaries(), params);
  }

  getUrlReport(orderId: any, operatorAgentFiscalId: any, orderDateFrom: any, orderDateTo: any) {
    let params = new HttpParams();

    if (orderId && orderId !== null) {
      params = params.set('orderId', orderId.toString());
    }

    if (operatorAgentFiscalId && operatorAgentFiscalId !== null) {
      params = params.set('operatorAgentFiscalId', operatorAgentFiscalId.toString());
    }

    if (orderDateFrom && orderDateFrom !== null) {
      params = params.set('orderDateFrom', orderDateFrom.toString());
    }

    if (orderDateTo && orderDateTo !== null) {
      params = params.set('orderDateTo', orderDateTo.toString());
    }

    return this.apiService.download(routes.orderReport, params);
  }

  getOrdersPerCustomer() {
    return this.apiService.get(routes.orders);
  }

  getOderandIncrementId(orderId: number, fiscalId: number) {
    let params = new HttpParams();

    if (orderId && orderId !== null && fiscalId && fiscalId !== null) {
      params = params.set('orderId', orderId.toString());
      params = params.set('fiscalId', fiscalId.toString());
    }

    return this.apiService.get(routes.urlOderandIncrementId, params);
  }

  getOrder(id: number) {
    return this.apiService.get(routes.order(id));
  }

  downloadPayOrder(id: number) {
    return this.apiService.get(routes.order(id));
  }

  downloadProductInvoice(id: number) {
    return this.apiService.get(routes.order(id));
  }

  newOrder(data: any) {
    return this.apiService.post(routes.orderNew, data);
  }

  newOrderCargaSaldo(data: any) {
    return this.apiService.post(routes.orderNewCargaSaldo, data);
  }

  newOrderRescateSaldo(data: any) {
    return this.apiService.post(routes.orderNewRescateSaldo, data);
  }

  getOrderAos(customerFiscalId: string, howManyMerchants: number, howManyBeneficiaries: number) {
    let params = new HttpParams();

    if (customerFiscalId && customerFiscalId !== null) {
      params = params.set('customerFiscalId', customerFiscalId);
    }

    if (howManyMerchants && howManyMerchants !== null) {
      params = params.set('howManyMerchants', howManyMerchants.toString());
    }

    if (howManyBeneficiaries && howManyBeneficiaries !== null) {
      params = params.set('howManyBeneficiaries', howManyBeneficiaries.toString());
    }

    return this.apiService.get(routes.orderAos, params);
  }

  getOrderDetailAOS(orderId: number) {
    return this.apiService.get(routes.orderDetailAos(orderId));
  }

  getEntryOrder(orderId: number) {
    return this.apiService.get(routes.getEntryOrder(orderId));
  }

  getBeneficiaryOrdersNotEnded(fiscalId: number, type: string) {
    return this.apiService.get(
      routes.getBeneficiaryOrdersNotEnded(fiscalId, type));
  }

  /**
   * Se comenzaremos a crear servicios con paginacion y que optimicen las consultas a la BD
   * se llamara igual pero con un 2 al final, la idea es ir desacoplando la versiones no optimizadas
   * y posteriormente eliminarlas
   * @param fiscalId
   * @param type
   * @param page
   * @param size
   * @returns
   */
  getBeneficiaryOrdersNotEnded2(filtersDTO: FilterModel) {
    return this.apiService.post(routes.getBeneficiaryOrdersEnded2p, filtersDTO);
  }

  getBeneficiaryOrdersEnded(fiscalId: number) {
    return this.apiService.get(
      routes.getBeneficiaryOrdersEnded(fiscalId));
  }

  getBeneficiaryOrdersEnded2(filtersDTO: FilterModel) {
    return this.apiService.post(routes.getBeneficiaryOrdersEnded2, filtersDTO);
  }

  changeStatus(data: any) {
    return this.apiService.put(
      routes.changeStatus, data);
  }

  getUrlPurchaseSummaryDocumentByOrderId(orderId: number) {
    let params = new HttpParams();

    if (orderId && orderId !== null) {
      params = params.set('orderId', orderId.toString());
    }

    return this.apiService.get(routes.urlPurchaseSummaryDocumentByOrderId, params);
  }

  getOrderDetailFichaPedido(orderId: number) {
    return this.apiService.get(routes.getDetailFichaPedido(orderId));
  }

  putOrderRequestActivateGiftCard(data: any) {
    return this.apiService.put(routes.orderRequestActivateGiftCard, data);
  }

  getGiftcardDetails(orderId: number) {
    return this.apiService.get(routes.getGiftcardDetails(orderId));
  }

  getGiftCards(fiscalId: string, active: boolean, categoryId: number) {
    return this.apiService.get(routes.getGiftCards(fiscalId, active, categoryId));
  }

  getBeneficiaryOrderIncreaseNotEnded(customerFiscalId: number) {
    return this.apiService.get(routes.getBeneficiaryOrderIncreaseNotEnded(customerFiscalId));
  }

  getOrderByOrderIdToShow(orderId: number) {
    return this.apiService.get(routes.getOrderByOrderIdToShow(orderId));
  }

  /**
   * Subir archivo Excel a una order de Alimentacion
   * @param file: Archivo excel subido.
   */
  uploadExcelToMealOrder(file: any, fiscalId: any, userProfile:number,branchOffice: any = [], isPublic: boolean) {
    const body = new FormData();
    // Add file content to prepare the request
    body.append('file', file);
    body.append('branchsId',branchOffice);

    return this.apiService.postMultipartFile(routes.uploadExcelFileToMealOrder(fiscalId,userProfile,isPublic), body);
  }

  /**
   * Genera un archivo excel del template de descarga con los datos.
   * del usuario inyectados.
   * @param beneficiaries: Listado de beneficiarios a inyectar en excel.
   * @param branches: Sucursales a inyectar en excel
   * @param costCenters: Listado de centros de costos a inyectar.
   */
  downloadMasiveOrderMealExcel(beneficiaries: any, branches: any, costCenters: any) {

    const data = {
      beneficiaries,
      branches,
      costCenters,
    };
    return this.apiService.put(routes.downloadMealExcel(), data);
  }

  downloadPdfFiles(orderPdf: any) {
    return this.apiService.post(routes.downloadPdfFiles(), orderPdf);
  }

  getOrderDeliveryDate(admissionDate: string, newCardsQuantity: number, deliveryPointDTO: any) {
    const data = {
      admissionDate,
      newCardsQuantity,
      deliveryPointDTO,
    };
    return this.apiService.put(routes.orderDeliveryDate(), data);
  }

  getBranchesByFiscalId(fiscalId: string, countCard: boolean) {
    let params = new HttpParams();

    if (fiscalId && fiscalId !== null) {
      params = params.set('fiscalId', fiscalId);
    }

    if (countCard !== null) {
      params = params.set('countCard', countCard.toString());
    }

    return this.apiService.get(routes.branchesByFiscalId(), params);
  }

  getDefaultBalanceReleaseDate(isOnline: boolean, validityBalanceId: number, orderYear: number, orderMonth: number) {
    let params = new HttpParams();

    if (isOnline !== null) {
      params = params.set('isOnline', isOnline.toString());
    }

    if (validityBalanceId !== null) {
      params = params.set('validityBalanceId', validityBalanceId.toString());
    }

    if (orderYear !== null) {
      params = params.set('orderYear', orderYear.toString());
    }

    if (orderMonth !== null) {
      params = params.set('orderMonth', orderMonth.toString());
    }

    return this.apiService.get(routes.defaultBalanceReleaseDate(), params);
  }

  getDefaultBalanceExpirationDate(validityBalanceId: number, balanceReleaseDate: string) {
    let params = new HttpParams();

    if (validityBalanceId !== null) {
      params = params.set('validityBalanceId', validityBalanceId.toString());
    }

    if (balanceReleaseDate && balanceReleaseDate !== null) {
      params = params.set('balanceReleaseDate', balanceReleaseDate.toString());
    }

    return this.apiService.get(routes.defaultBalanceExpirationDate(), params);
  }

  /**
   * Añadir tarjetas a un pedido.
   * @param orderId: Identificador de la orden
   * @param userId: Identificador del usuario
   * @param newEmissionLoadOrderList: Informacion de las tarjetas a agregar
   */
  addCardsToOrder(orderId: number, userId: number, newEmissionLoadOrderList: EmissionLoadOrder[]) {
    return this.apiService.post(routes.addCardsToOrder(orderId, userId), newEmissionLoadOrderList);
  }
  /**
   * Crea cabecera de un pedido en el sistema de tarjeta.
   * @param createOrderinfo: Request Body de la peticion
   */
  createNewCardsOrder(createOrderinfo: any) {
    return this.apiService.post(routes.createNewMealOrder(), createOrderinfo);
  }

  /**
   * Procesar pedido.
   * @param orderId: Identificador de la orden
   * @param userId: Identificador del usuario (Default 12 para esta plataforma)
   * @param emissionOrderDeliveryDateDTOList: Informacion de fecha de entrega por sucursal.
   */
  processCardOrder(orderId: number, userId: number, emissionOrderDeliveryDateDTOList: EmissionOrderDeliveryDate[]) {
    return this.apiService.post(routes.processCardOrder(orderId, userId), emissionOrderDeliveryDateDTOList);
  }

  /**
   * Método retorna todas las ordenes de Alimentacion para un usuario
   * @param fiscalId: identificador del usuario
   */
  getMealOrders(companyFiscalId: string) {
    return this.apiService.get(routes.mealOrders(companyFiscalId));
  }

  getNotWorkingDays(year: number, month: number, startDay: number) {
    return this.apiService.get(routes.holidayNotWorkingDays(year, month, startDay));
  }
  getOrderDetail(orderId: number) {
    return this.apiService.get(routes.orderDetail(orderId));
  }

  getBillingData(
    newCardsQuantity: number, branchQuantitySantiago: number, branchQuantityRegion: number, fiscalId: string, chargedAmount: number) {
    let params = new HttpParams();

    if (newCardsQuantity !== null) {
      params = params.set('newCardsQuantity', newCardsQuantity.toString());
    }

    if (branchQuantitySantiago !== null) {
      params = params.set('branchQuantitySantiago', branchQuantitySantiago.toString());
    }

    if (branchQuantityRegion !== null) {
      params = params.set('branchQuantityRegion', branchQuantityRegion.toString());
    }

    if (fiscalId && fiscalId !== null) {
      params = params.set('fiscalId', fiscalId.toString());
    }

    if (chargedAmount !== null) {
      params = params.set('chargedAmount', chargedAmount.toString());
    }

    return this.apiService.get(routes.billingData(), params);
  }

  uploadExcelMassiveBeneficiaries(file: any, fiscalId: string, affiliatedName: string) {
    const body = new FormData();
    // Add file content to prepare the request
    body.append('giftcardFile', file);
    return this.apiService.postMultipartFile(routes.uploadExcelMassiveBeneficiaries(fiscalId, affiliatedName), body);
  }

  /**
   * uploadExcelMassiveBeneficiariesRecharge
   * @param file
   * @param fiscalId
   * @param affiliatedName
   * @returns
   */
  uploadExcelMassiveBeneficiariesRecharge(file: any, fiscalId: string, affiliatedName: string) {
    const body = new FormData();
    // Add file content to prepare the request
    body.append('giftcardFile', file);
    return this.apiService.postMultipartFile(routes.uploadExcelMassiveBeneficiariesRecharge(fiscalId, affiliatedName), body);
  }

  getCardParameterByName(parameterName) {
    return this.apiService.get(routes.cardParameterByName(parameterName));
  }

  getValidityBalance(fiscalId, serviceId) {
    return this.apiService.get(routes.validityBalance(fiscalId, serviceId));
  }

  getDraftOrderWithDetail(companyFiscalId: string, orderRequestId: number) {
    let params = new HttpParams();

    if (companyFiscalId && companyFiscalId !== null) {
      params = params.set('companyFiscalId', companyFiscalId.toString());
    }

    if (orderRequestId && orderRequestId !== null) {
      params = params.set('orderRequestId', orderRequestId.toString());
    }

    return this.apiService.get(routes.getDraftOrderWithDetail(), params);
  }

  getOrderQuantityByStatus(serviceId: number, fiscalId: string, orderTypeList: number[], statusList: number[]) {
    let params = new HttpParams();

    if (serviceId && serviceId !== null) {
      params = params.set('serviceId', serviceId.toString());
    }

    if (fiscalId && fiscalId !== null) {
      params = params.set('fiscalId', fiscalId);
    }

    if (orderTypeList && orderTypeList !== null) {
      params = params.set('orderTypeList', orderTypeList.toString());
    }

    if (statusList && statusList !== null) {
      params = params.set('statusList', statusList.toString());
    }

    return this.apiService.get(routes.ordersQuantityByStatus(), params);
  }

  cancelMealOrder(companyFiscalId: string, orderRequestId: number) {
    const data = {
      companyFiscalId,
      orderRequestId
    };
    return this.apiService.put(routes.cancelMealOrder(), data);
  }

  updateMealOrder(updateOrderData: any, orderId: number) {
    return this.apiService.put(routes.updateMealOrder(orderId), updateOrderData);
  }

  validateAccountDocument(orderId: number, documentStateId: number, companyFiscalId: string) {
    let params = new HttpParams();

    if (orderId && orderId !== null) {
      params = params.set('orderId', orderId.toString());
    }

    if (documentStateId && documentStateId !== null) {
      params = params.set('documentStateId', documentStateId.toString());
    }

    if (companyFiscalId && companyFiscalId !== null) {
      params = params.set('companyFiscalId', companyFiscalId.toString());
    }

    return this.apiService.get(routes.validateAccountDocument(), params);
  }

  getCutoffDate(serviceId: number, companyFiscalId: string, orderTypeId: number, statusDate: string) {
    let params = new HttpParams();

    if (serviceId && serviceId !== null) {
      params = params.set('serviceId', serviceId.toString());
    }

    if (companyFiscalId && companyFiscalId !== null) {
      params = params.set('companyFiscalId', companyFiscalId);
    }

    if (orderTypeId && orderTypeId !== null) {
      params = params.set('orderTypeId', orderTypeId.toString());
    }

    if (statusDate && statusDate !== null) {
      params = params.set('statusDate', statusDate);
    }
    return this.apiService.get(routes.getCutoffDate(), params);
  }

  disabledDocumentReference(id: number) {
    return this.apiService.delete(routes.disabledDocumentReference(id));
  }

  updateDocumentReference(orderId: number, data: CreateMealOrderModel) {
    return this.apiService.put(routes.updateDocumentReference(orderId), data);
  }

  getBeneficiriesByOrderAndBranch(companyFiscalId: string, orderRequestId: number, branchId: number) {
      let params = new HttpParams();

      if (companyFiscalId && companyFiscalId !== null) {
        params = params.set('companyFiscalId', companyFiscalId);
      }

      if (orderRequestId && orderRequestId !== null) {
          params = params.set('orderRequestId', orderRequestId.toString());
        }

      if (branchId && branchId !== null) {
        params = params.set('branchId', branchId.toString());
      }

      return this.apiService.get(routes.getBeneficiariesByOrderAndBranch(), params);
  }

  updateMealStatusOrder(updateOrderStatus: UpdateOrderStatus) {
    return this.apiService.put(routes.updateMealStatusOrder(), updateOrderStatus);
  }

  getNewCardsAndUpdateOrder(orderId: number, companyFiscalId: string) {
    let params = new HttpParams();

    if (orderId && orderId !== null) {
      params = params.set('orderId', orderId.toString());
    }

    if (companyFiscalId && companyFiscalId !== null) {
      params = params.set('fiscalId', companyFiscalId);
    }
    return this.apiService.get(routes.getNewCardsAndUpdateOrder(), params);
  }

  getMealBeneficiaryDetail(beneficiaryFiscalId: string, companyFiscalId: string, serviceId: number) {
    let params = new HttpParams();

    if (beneficiaryFiscalId && beneficiaryFiscalId !== null) {
      params = params.set('beneficiaryFiscalId', beneficiaryFiscalId.toString());
    }

    if (companyFiscalId && companyFiscalId !== null) {
      params = params.set('companyFiscalId', companyFiscalId.toString());
    }

    if (serviceId && serviceId !== null) {
      params = params.set('serviceId', serviceId.toString());
    }
    return this.apiService.get(routes.getMealBeneficiaryDetail(), params);
  }

  getMealBranchesByFiscalId(fiscalId: string, countCard: boolean, userProfile: number, available: boolean = null) {
    let params = new HttpParams();

    if (fiscalId && fiscalId !== null) {
      params = params.set('fiscalId', fiscalId);
    }

    if (countCard !== null) {
      params = params.set('countCard', countCard.toString());
    }

    if (userProfile && userProfile !== null) {
      params = params.set('userProfile', userProfile.toString());
    }
    if (available && available !== null) {
      params = params.set('available', available.toString());
    }

    return this.apiService.get(routes.mealBranchesByFiscalId(), params);
  }
 
  /**
   * Genera y descarga los pdf asociados a las ordenes cuando es un pedido mixto.
   * @param orderInfo: Request Body de la peticion
   */
  downloadSummaryOrderPdf(orderId: number, fiscalId: string) {
    return this.apiService.post(routes.downloadSummaryOrderPdf(orderId, fiscalId));
  }

  /**
   * Envia por correo los pdf asociados a las ordenes cuando es un pedido mixto.
   * @param orderInfo: Request Body de la peticion
   */
   sendMailConfirmMultipleOrder(orderInfo: any) {
    return this.apiService.post(routes.sendMailConfirmMultipleOrder(), orderInfo);
  }

  orderInProgress(fiscalId: string,serviceId:number,type:number[]){
    return this.apiService.get(routes.orderInProgress(fiscalId,serviceId,type));
  }
}
