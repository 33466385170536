import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { isNumber } from 'util';
import { environment as env } from 'src/environments/environment';

const routes = {
  currentDate: '/utils/current-date'
};

@Injectable({
  providedIn: 'root'
})

export class UtilitiesService {

  getCurrentDate(): Observable<any> {
    return this.apiService.get(routes.currentDate);
  }
  constructor(private apiService: ApiService) { }

  getFormatedDate(Date: NgbDate) {
    let stringDate = '';
    stringDate += Date.year + '-';
    stringDate += isNumber(Date.month) ? `0${Date.month}`.slice(-2) + '-' : '';
    stringDate += isNumber(Date.day) ? `0${Date.day}`.slice(-2) : '';
    return stringDate;
  }

  orderCompanyByAgreement(companies) {
    return companies.find(c => {
      const hasAgreement = c.agreements.filter(a => a.serviceId === env.serviceIds.beneficios.service.serviceId
        || a.serviceId === env.serviceIds.sercotec.serviceId || a.serviceId === env.serviceIds.junaeb.serviceId
        || a.serviceId === env.serviceIds.alimentacion.serviceId);
      return (hasAgreement.length > 0) ? c : null;
    });
  }

  orderCompaniesByAgreementJust(companies) {
    return companies.filter(c => c.agreements.filter(a => a.serviceId === env.serviceIds.beneficios.service.serviceId
      || a.serviceId === env.serviceIds.sercotec.serviceId || a.serviceId === env.serviceIds.junaeb.serviceId
      || a.serviceId === env.serviceIds.alimentacion.serviceId).length > 0);
  }
}
