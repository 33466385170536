export class SendMailing{
    reason:string;
    userName:string;
    userFiscalId:string;
    companyName:string;
    companyFiscalId:string;
    message:string;
    userEmail:string;
    attachmentDTO:AttachmentDTO;
}

export class AttachmentDTO{
    filename:string;
    base64:string;
}




