import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable, Subject } from 'rxjs';
import { environment as env } from 'src/environments/environment';

const routes = {
  businessPlan: (id: number) => `/business-plans/customer/by-customer/${id}`,
  allCategories: '/business-plans/customer-categories',
  categoriesPerCategories: '/business-plans/customer-categories-categories',
  postCustomerBusinessPlan: '/business-plans/customer',
  productCategoriesbyCustomerId: (customerId: number) => `/business-plans/customer-categories/${customerId}`,
  allProductCategories: '/business-plans/customer-categories/',
  putBusinessPlant: '/business-plans',
  product: (id: number) => `/products/by-category/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class BusinessPlanService {

  private productCategory = new Subject();
  private productCategories = new Subject();
  private customerCategory = new Subject();
  private productsBrowser = new Subject();
  private productSelected = new Subject();
  private businessplan = new Subject();
  private currentMenu = new Subject();
  private quotedProducts = new Subject();
  private showBreadcrumb = new Subject();

  getShowBreadcrumb() {
    return this.showBreadcrumb.asObservable();
  }
  setShowBreadcrumb(value: any) {
    this.showBreadcrumb.next(value);
  }
  setBeneficiaryQuotations(quotedProducts: any) {
    this.quotedProducts.next(quotedProducts);
  }
  getBeneficiaryQuotations() {
    return this.quotedProducts.asObservable();
  }
  setCurrentMenu(currentMenu: string) {
    this.currentMenu.next(currentMenu);
  }
  getCurrentMenu() {
    return this.currentMenu.asObservable();
  }
  getMenuBeneficiaryDefault() {
    return env.menuBeneficiaryDefault;
  }
  setBusinessPlan(businessplan: any) {
    this.businessplan.next(businessplan);
  }
  getBusinessPlan() {
    return this.businessplan.asObservable();
  }
  getProductCategoriesFromAllCategories(categories: any) {
    if (categories === null || categories === undefined || categories.length === 0) {
      return [];
    }
    const response = [];
    categories.forEach(customerCategory => {
      if (customerCategory !== null
        && customerCategory !== undefined
        && customerCategory.length !== 0) {
        this.processCustomerCategory(customerCategory, response);
      }

    });

    return response;
  }

  processCustomerCategory(customerCategory: any, response: any[]): any {
    if (customerCategory === null || customerCategory === undefined) {
      return null;
    }
    if (customerCategory.categories === null
      || customerCategory.categories === undefined
      || customerCategory.categories.length === 0) {
      return null;
    }
    customerCategory.categories.forEach(category => {
      this.processProductCategories(category, response);
    });
  }

  processProductCategories(customerCategory: any, response: any[]) {
    if (customerCategory === null || customerCategory === undefined) {
      return null;
    }
    if (customerCategory.productCategories === null
      || customerCategory.productCategories === undefined
      || customerCategory.productCategories.length === 0) {
      return null;
    }
    customerCategory.productCategories.forEach(productCategory => {
      if (productCategory !== null
        && productCategory !== undefined
        && productCategory.categories !== null
        && productCategory.categories !== undefined
        && productCategory.categories.length !== 0) {

        productCategory.categories = productCategory.categories.filter(
          i => (i.products !== null && i.products !== undefined && i.products.length !== 0));
        if (productCategory.categories !== null
          && productCategory.categories !== undefined
          && productCategory.categories.length !== 0) {
          response.push(productCategory);
        }
      }
    });
  }
  setProductSelected(product: any) {
    this.productSelected.next(product);
  }

  getProductSelected() {
    return this.productSelected.asObservable();
  }

  setProductsBrowser(products: any) {
    this.productsBrowser.next(products);
  }

  getProductsBrowser() {
    return this.productsBrowser.asObservable();
  }

  setCustomerCategory(customerCategory: any) {
    this.customerCategory.next(customerCategory);
  }

  getCustomerCategory() {
    return this.customerCategory.asObservable();
  }

  setProductCategories(productCategories: any) {
    this.productCategories.next(productCategories);
  }
  getProductCategories() {
    return this.productCategories.asObservable();
  }

  getProductCategory(): Observable<any> {
    return this.productCategory.asObservable();
  }

  setProductCategory(productCategory: any) {
    this.productCategory.next(productCategory);
  }

  getCustomerCategoryByDefault() {
    return {
      id: null,
      name: '',
      amount: 0.0,
      amountUsed: 0.0,
      amountUsedOrigin: 0.0,
      categories: null,
      productCategories: null,
      customerCategory: null
    };
  }
  getProductCategoryByDefault(productCategories: any[]): any {
    if (productCategories === null
      || productCategories === undefined
      || productCategories.length === 0) {
      return null;
    }
    productCategories[0].categories[0].customerCategory = this.getCustomerCategoryByDefault();
    return productCategories[0].categories[0];
  }
  hasBusinessPlan(businessplan: any): any {
    if (businessplan === null || businessplan === undefined) {
      return false;
    }
    if (businessplan.categories === null || businessplan.categories === undefined || businessplan.categories.length === 0) {
      return false;
    }
    return true;
  }

  constructor(private apiService: ApiService) { }

  saveCustomerBusinessPlan(customerBusinessPlan: any): Observable<any> {
    return this.apiService.post(routes.postCustomerBusinessPlan, customerBusinessPlan);
  }

  getProductCategoriesByCustomerId(customerId: any): Observable<any> {
    return this.apiService.get(routes.productCategoriesbyCustomerId(customerId));
  }

  getAllProductCategories(): Observable<any> {
    return this.apiService.get(routes.allProductCategories);
  }

  getCategoriesPerCategories(): Observable<any> {
    return this.apiService.get(routes.categoriesPerCategories);
  }

  getAllCategories(): Observable<any> {
    return this.apiService.get(routes.allCategories);
  }

  getBusinessPlanByCustomerId(id: number): Observable<any> {
    return this.apiService.get(routes.businessPlan(id));
  }

  putBusinessPlant(data: any) {
    return this.apiService.put(routes.putBusinessPlant, data);
  }

  getProductByCategory(idCategory: number) {
    return this.apiService.get(routes.product(idCategory));
  }

}
