<form #form="ngForm" class="row" (ngSubmit)="onSubmit(form.value)">
  <ng-container *ngIf="editMode; else newForm">
    <div class="col-md-6 summary-item mb-4">
      <app-summary-item label="Nombre" value="{{ beneficiary.fullName }}"></app-summary-item>
    </div>
    <div class="col-md-6 summary-item mb-4">
      <app-summary-item label="Rut" value="{{ beneficiary.fiscalId | rut }}"></app-summary-item>
    </div>
  </ng-container>
  <ng-template #newForm>
    <div class="col-md-6 form-group mb-5" [ngClass]="{'invalid-form': name.invalid && name.dirty}">
      <label for="name">Nombre <span class="text-danger">*</span></label>
      <input type="text" class="form-control" id="name" placeholder="Ingresa el nombre del beneficiario" maxlength="15"
        [ngModel]="model.name" name="name" #name="ngModel" required>
      <div *ngIf="name.invalid && name.dirty && name.value != ''" class="error-message">
        <app-icon icon="close"></app-icon>
        <span>Ingresa sólo letras</span>
      </div>
    </div>
    <div class="col-md-6 form-group mb-5" [ngClass]="{'invalid-form': lastName.invalid && lastName.dirty}">
      <label for="lastName">Apellido Paterno <span class="text-danger">*</span></label>
      <input type="text" class="form-control" id="lastName" placeholder="Ingresa el apellido paterno del beneficiario" maxlength="15"
        [ngModel]="model.lastName" name="lastName" #lastName="ngModel" required>
      <div *ngIf="lastName.invalid && lastName.dirty && lastName.value != ''" class="error-message">
        <app-icon icon="close"></app-icon>
        <span>El campo es obligatorio</span>
      </div>
    </div>
    <div class="col-md-6 form-group mb-5" [ngClass]="{'invalid-form': secondLastName.invalid && secondLastName.value != ''}">
      <label for="secondLastName">Apellido Materno</label>
      <input type="text" class="form-control" id="secondLastName" placeholder="Ingresa el apellido materno del beneficiario"
        [ngModel]="model.secondLastName" name="secondLastName" #secondLastName="ngModel" maxlength="15">
      <div *ngIf="secondLastName.invalid && secondLastName.value != ''" class="error-message">
        <app-icon icon="close"></app-icon>
        <span>El campo es obligatorio</span>
      </div>
    </div>
    <div class="col-md-6 form-group mb-5" [ngClass]="{'invalid-form': (fiscalId.invalid && fiscalId.dirty && fiscalId.value != '') || !isUnique}">
      <label for="fiscalId">Rut <span class="text-danger">*</span></label>
      <input type="text" class="form-control" id="fiscalId" placeholder="Ingresa el rut del usuario" maxlength="15"
        [ngModel]="model.fiscalId" name="fiscalId" #fiscalId="ngModel" (input)="checkUnique($event.target.value)" required validateRut formatRut>
      <div *ngIf="fiscalId.invalid && fiscalId.dirty && fiscalId.value != ''" class="error-message">
        <app-icon icon="close"></app-icon>
        <span>Rut inválido</span>
      </div>
      <div *ngIf="!isUnique" class="error-message">
        <app-icon icon="close"></app-icon>
        <span>Rut ya existe</span>
      </div>
    </div>
    <div class="col-md-6 form-group mb-5" [ngClass]="{'invalid-form': phoneNumber.invalid && phoneNumber.dirty}">
      <label for="phoneNumber">Teléfono <span class="text-danger">*</span></label> {{ countryCode }}
      <div class="phone-group">
        <select id="f-new-user-phone-code" class="custom-select country-dial" name="countryDial" id="countryDial" (change)="setCountryCode($event.target.value)">
          <option *ngFor="let country of countries" [value]="country.phone" [selected]="country.phone == 56">{{ country.emoji }} {{ country.name }} (+{{ country.phone }})</option>
        </select>
        <input type="text" class="form-control" id="phoneNumber" placeholder="Ingresa el número de teléfono del beneficiario" maxlength="9"
          [ngModel]="model.phoneNumber" name="phoneNumber" #phoneNumber="ngModel" required digitOnly>
        <div *ngIf="phoneNumber.invalid && phoneNumber.dirty && phoneNumber.value != ''" class="error-message">
          <app-icon icon="close"></app-icon>
          <span>El campo es obligatorio</span>
        </div>
      </div>
    </div>
    <div class="col-md-6 form-group mb-5" [ngClass]="{'invalid-form': (email.invalid && email.dirty) || !isUniqueEmail}">
      <label for="email">Correo <span class="text-danger">*</span></label>
      <input type="text" class="form-control" id="email" placeholder="Ingresa el correo del beneficiario" maxlength="60"
        [ngModel]="model.email" name="email" #email="ngModel" (input)="checKUniqueEmail($event.target.value)" required email>
      <div *ngIf="email.invalid && email.dirty && email.value != ''" class="error-message">
        <app-icon icon="close"></app-icon>
        <span>Correo inválido</span>
      </div>
       <div *ngIf="!isUniqueEmail" class="error-message">
        <app-icon icon="close"></app-icon>
        <span>Correo ya existe</span>
      </div>
    </div>
  </ng-template>
  <div class="col-12 text-center">
    <button app-button size="lg" type="submit" [disabled]="form.invalid || !isUnique || !isUniqueEmail">Guardar</button>
  </div>
</form>
