import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { Tracking } from 'src/app/core/model/tracking';
import { TrackingStep } from 'src/app/core/model/trackingStep';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {
  @Input() tracking?: Tracking;
  @Input() class = '';
  trackingSteps: TrackingStep[] = [
    {
      step: 1,
      name: 'En validación'
    },
    {
      step: 2,
      name: 'En preparación'
    },
    {
      step: 3,
      name: 'En camino'
    },
    {
      step: 4,
      name: 'Entregado'
    }
  ];
  constructor() { }

  ngOnInit(): void {
  }

  @HostBinding('class')
  get hostClasses(): string {
    return [
      this.class, // include our new one
      'tracking'
    ].join(' ');
  }
}
