<ngb-datepicker #dp (select)="handleSelectDate($event)" [dayTemplate]="isRanged === true ? t : null" displayMonths="{{ displayMonths }}" navigation="arrows" outsideDays="{{ outsideDays }}" [markDisabled]="markDisabled" [minDate]="minDate"></ngb-datepicker>

<ng-template #t let-date let-focused="focused" let-disabled="disabled">
  <ng-template [ngIf]="isRanged === true">
    <span class="custom-day"
      [class.first]="isFirst(date)"
      [class.last]="isLast(date)"
      [class.focused]="focused"
      [class.range]="isRange(date)"
      [class.faded]="isHovered(date) || isInside(date)"
      (mouseenter)="hoveredDate = date"
      [class.text-disabled]="disabled"
      (mouseleave)="hoveredDate = null">
      {{ date.day }}
    </span>
  </ng-template>
  <ng-template [ngIf]="isRanged === false">
    <span class="custom-day"
      [class.focused]="focused"
      [class.faded]="isHovered(date) || isInside(date)"
      (mouseenter)="hoveredDate = date"
      [class.text-disabled]="disabled"
      (mouseleave)="hoveredDate = null">
      {{ date.day }}
    </span>
  </ng-template>
</ng-template>
