import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { AlimentacionOrdersNewComponent } from './../../features/dashboard/alimentacion/orders/new/new.component';

import { Subject } from 'rxjs';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

import { ConfirmLeaveComponent } from '../../features/dashboard/alimentacion/orders/new/components/confirm-leave/confirm-leave.component';
import { SSF } from 'xlsx/types';


@Injectable()
export class ConfirmExitGuard implements CanDeactivate<AlimentacionOrdersNewComponent> {
    constructor(private modalService: BsModalService) {}

    canDeactivate(target: AlimentacionOrdersNewComponent) {
        // const form = target.hasChanges();
        // if (form.modal) {
        //     const subject = new Subject<boolean>();
        //     const modal = this.modalService.show(ConfirmLeaveComponent, {
        //         class: 'modal-dialog-centered modal-md',
        //         initialState: {
        //             totalDraftOrders: form.totalDraftOrders,
        //             currentStep: form.currentStep,
        //             formComponent: form.formComponent
        //         }
        //     });
        //     modal.content.subject = subject;
        //     return subject.asObservable();
        // }
        return true;
    }
}
