import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';
import { environment as env } from 'src/environments/environment';

const BASE_URL = env.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) { }

  public get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient
      .get(BASE_URL + path, { params })
      .pipe(timeout(300000), catchError(this.formatErrors));
  }

  download(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Access-Control-Expose-Headers', 'Content-Disposition');
    return this.httpClient.get(BASE_URL + path, { headers, responseType: 'blob', params })
      .pipe(timeout(300000), catchError(this.formatErrors));
  }

  public put(path: string, body: object = {}): Observable<any> {
    return this.httpClient
      .put(BASE_URL + path, JSON.stringify(body))
      .pipe(timeout(300000), catchError(this.formatErrors));
  }

  public post(path: string, body: object = {}): Observable<any> {
    return this.httpClient
      .post<any>(BASE_URL + path, JSON.stringify(body), {
        observe: 'response'
      })
      .pipe(timeout(300000), catchError(this.formatErrors));
  }


  public delete(path: string): Observable<any> {
    return this.httpClient.delete(BASE_URL + path).pipe(timeout(300000), catchError(this.formatErrors));
  }

  public formatErrors(error: any): Observable<any> {
    return throwError(error);
  }

  public postMultipartFile(path: string, body: any): Observable<any> {
    const headers = new HttpHeaders();
    /** In Angular 5, including the header Content-Type can invalidate your request */
    headers.set('Content-Type', 'multipart/form-data');
    headers.set('Accept', 'application/json');
    return this.httpClient
      .post<any>(BASE_URL + path, body, {
        headers,
        observe: 'response'
      })
      .pipe(timeout(300000), catchError(this.formatErrors));
  }

}
