<ol class="breadcrumb" role="navigation">
  <li class="breadcrumb-item" *ngFor="let item of breadcrumbs; index as i;">
    <ng-container *ngIf="item.url">
      <a
        routerLink="{{ item.url }}"
        class="breadcrumb-link"
        [ngClass]="item.isActive ? 'breadcrumb-active' : 'breadcrumb-desactive'"
        (click)="stepClicked(i)"
        *ngIf="item.displayed"
      >
        <app-icon *ngIf="item.svgIcon" [icon]="item.svgIcon"></app-icon>
        <span>{{ item.name }}</span>
      </a>
    </ng-container>
    <ng-container *ngIf="!item.url">
      <a
        class="breadcrumb-link"
        [ngClass]="item.isActive ? 'breadcrumb-active' : 'breadcrumb-desactive'"
        (click)="stepClicked(i)"
        *ngIf="item.displayed"
      >
        <app-icon *ngIf="item.svgIcon" [icon]="item.svgIcon"></app-icon>
        <span>{{ item.name }}</span>
      </a>
    </ng-container>
  </li>
</ol>
