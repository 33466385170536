import { Injectable } from '@angular/core';
import { Subject,Observable  } from 'rxjs';
import {Module} from 'src/app/core/model/module';

@Injectable({
  providedIn: 'root'
})
export class NavigationActionService {
  constructor() { }
  private listModule$ = new Subject<Module[]>();

  setListModule(modules: Module[]) {
    this.listModule$.next(modules);
  }

  getListModule$(): Observable<Module[]> {
    return this.listModule$.asObservable();
  }
}
