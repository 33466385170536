import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-footer-dashboard',
  templateUrl: './footer-dashboard.component.html',
  styleUrls: ['./footer-dashboard.component.scss']
})
export class FooterDashboardComponent implements OnInit {
  currentUser: any;
  env = environment;

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.currentUser = this.dataService.getCurrentUser();
  }

}
