import { Component, OnInit } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { environment as env } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { DataService } from 'src/app/core/services/data.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard',
  providers: [],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  timerInterval: any;
  interval;
  time = 0;

  constructor(
    private userIdle: UserIdleService,
    private authService: AuthService,
    private dataService: DataService,
    private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    // this.SessionTimer();
    this.userIdleInit();
  }

  userIdleInit() {
    // Comienza a observar la inactividad del usuario.
    this.userIdle.startWatching();

    // Comienza a mirar cuando el usuario está inactivo.
    this.userIdle.onTimerStart().subscribe(count => {
      this.showInactivityMessage(count);
    });

    // Comienza a mirar cuando se acabe el tiempo.
    this.userIdle.onTimeout().subscribe(() => {
      console.log('¡Sesión expirada!');
      Swal.close();
      this.logout();
    });
  }

  SessionTimer() {
    if (this.dataService.getLoginDate() !== undefined && this.dataService.getLoginDate() !== null) {
      this.time = Math.round(
        (new Date().getTime() - new Date(this.dataService.getLoginDate()).getTime()) / 1000);
    }
    if (this.time < 0 || this.time > env.session.expire) {
      this.showMessageOfClosingSession(true);
    }
    this.interval = setInterval(() => {
      this.time++;
      if (env.session.expire === this.time) {
        this.showMessageOfClosingSession(false);
      }
    }, 1000);
  }

  showMessageOfClosingSession(activeTimer) {
    // clearInterval(this.interval);
    // this.spinner.hide();
    // Swal.close();
    // if (activeTimer) {
    //   Swal.fire(
    //     {
    //       type: 'info',
    //       timer: 1000 * 60,
    //       title: 'Lo sentimos',
    //       text: 'Tu sesión expiró, por favor ingresa nuevamente',
    //       onBeforeOpen: () => {
    //         this.timerInterval = setInterval(() => {
    //         }, 1000);
    //       },
    //       onClose: () => {
    //         clearInterval(this.timerInterval);
    //       }
    //     }
    //   ).then((result) => {
    //     this.logout();
    //   });
    // } else {
    //   Swal.fire('Lo sentimos', 'Tu sesión expiró, por favor ingresa nuevamente', 'info')
    //     .then((result) => {
    //     this.logout();
    //   });
    // }
  }

  showInactivityMessage(count) {
    if (count === 1) {
      Swal.fire({
        html: '<strong style="font-size: 160%;">Tu sesión expira en <b></b> segundos.</strong>',
        timer: env.userIdle.timeout * 1000,
        type: 'warning',
        confirmButtonText: 'Continuar sesión',
        cancelButtonText: 'Cerrar sesión',
        cancelButtonColor: '#d33',
        showCancelButton: true,
        showCloseButton: false,
        allowOutsideClick: false,
        onBeforeOpen: () => {
          this.timerInterval = setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector('b');
              if (b) {
                b.textContent = Math.trunc(Swal.getTimerLeft() / 1000).toString();
              }
            }
          }, 100);
        },
        onClose: () => {
          clearInterval(this.timerInterval);
        }
      }).then((result) => {
        if (result.value) {
          this.userIdle.resetTimer();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.logout();
        }
      });
    }
  }

  logout() {
    this.userIdle.stopWatching();
    this.authService.logout();
  }

}
