import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

  @Input() errors: any[] = [];
  @Input() multiple = false;
  @Input() accept = '.xls, .xlsx';
  @Input() status: 'empty' | 'loading' | 'error' | null = null;
  @Input() buttonText = 'Subir archivo';
  @Input() buttonRetryText = 'Subir archivo nuevamente';
  @Input() downloadFileErrors = 'Descarga archivo de errores';
  @Output() outputFile = new EventEmitter();
  @Output() download = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  importFile( files ) {
    if ( this.multiple === true ) {
      this.outputFile.emit( files );
    } else {
      this.outputFile.emit( files[0] );
    }
  }

  downloadFile() {
    this.download.emit();
  }

}
