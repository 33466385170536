import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-group-button',
  templateUrl: './group-button.component.html',
  styleUrls: ['./group-button.component.scss']
})
export class GroupButtonComponent implements OnInit {
  @Input() id: string | undefined;
  @Input() options: any;
  @Input() selected: any | null = null;
  @Input() type = 'radio';
  @Input() styling = '';
  @Output() clickOption = new EventEmitter();

  selectedOptionsData = [];

  constructor() { }

  ngOnInit() {
    this.id = this.id === undefined ? 'group-btn-option-' + Math.floor(Math.random() * 10000) : this.id;

    if (this.type === 'checkbox') {
      this.selectedOptionsData = this.selected;
    }
  }

  changeOption(value) {
    if (this.type === 'radio') {
      this.clickOption.emit( value );
    } else if (this.type === 'checkbox') {
      if (this.selectedOptionsData.indexOf(value) === -1) {
        this.selectedOptionsData.push(value);
      } else {
        const index = this.selectedOptionsData.indexOf(value);

        if (index > -1) {
          this.selectedOptionsData.splice(index, 1);
        }
      }

      this.clickOption.emit( this.selectedOptionsData );
    }
  }
}
