import { Component, AfterViewInit } from '@angular/core';
// import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
// import { Subscription } from 'rxjs/internal/Subscription';
import { DataService } from './core/services/data.service';

// declare function instanaMonitoring(reportingUrl: string, apiKey: string): any;
// declare function ineum(command: string, ...data: Array<any>): void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewInit {

  // private subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dataService: DataService) { }

  ngAfterViewInit(): void {
    // this.loadInstanaMonitoringScript();
    // this.loadInstanaMonitoringRouterEvents();
  }

  // private loadInstanaMonitoringScript(): void {
  //   if (environment.configs.trackingUi.enable) {
  //     instanaMonitoring(
  //       environment.configs.trackingUi.reportingUrl,
  //       environment.configs.trackingUi.apiKey
  //     );
  //   }
  // }

  // private loadInstanaMonitoringRouterEvents(): void {
  //   if (environment.configs.trackingUi.enable) {
  //     this.subscriptions.push(this.router.events.subscribe(event => {
  //       const property = 'name';
  //       const componentName = this.activatedRoute.component !== null ? this.activatedRoute.component[property] : 'default';

  //       if (event instanceof NavigationStart) {
  //         ineum('page', componentName);
  //         ineum('startSpaPageTransition');
  //       } else if (event instanceof NavigationEnd) {
  //         ineum('meta', 'component', componentName);
  //         this.identifyingUser();
  //         ineum('endSpaPageTransition', { status: 'completed' });
  //       } else if (event instanceof NavigationError) {
  //         this.identifyingUser();
  //         ineum('endSpaPageTransition', {
  //           status: 'error',
  //           url: window.location.href,
  //           explanation: event.error.toString()
  //         });
  //       } else if (event instanceof NavigationCancel) {
  //         ineum('endSpaPageTransition', {
  //           status: 'aborted',
  //           url: window.location.href,
  //           explanation: event.reason
  //         });
  //       }
  //     }));
  //   }
  // }

  // private identifyingUser() {
  //   const user = this.dataService.getCurrentUser();
  //   if (user) {
  //     ineum('user', user.fiscalId, user.name + ' ' + user.lastName, user.email);
  //   }
  // }
}
