import { Injectable } from '@angular/core';

@Injectable()
export abstract class LoginService {

  abstract login(user: any): void;

  abstract loadCompany(user: any, companySelected: any): Promise<any>;

  abstract loadUserProfile(data: any[], user: any): Promise<void> ;

}
