
<button [id]="id" type="button" class="list-group-item" [ngClass]="classes" [class.active]="isActive">
  <ng-template [ngIf]="isAPlace" [ngIfElse]="default">
    <span class="list-group-place__name">{{ title }}</span>
    <span class="list-group-place__address">{{ description }}</span>
  </ng-template>

  <ng-template #default>
    {{ title }}
  </ng-template>
</button>