<div class="radio-group" [ngClass]="styling">
  <div class="radio-group__item" *ngFor="let item of options">
    <label
      class="radio-group-button"
      [ngClass]="[
        (selected === item.value ? 'radio-group-button--checked' : '' ),
        (type === 'radio' && selected === item.value && item.hasOwnProperty('checkedCustomClass') && item.checkedCustomClass !== '' ? item.checkedCustomClass : '' )
      ]"
    >
      <input
        [type]="type"
        class="radio-group-button__input"
        [id]="id + '-' + item.id"
        [value]="item.value"
        [checked]="type === 'radio' ? selected === item.value : selected.indexOf(item.value) > -1"
        (change)="changeOption($event.target.value);"
      />
      <div class="radio-group-button__label">
        <span [ngClass]="{ 'radio-group-button__label-title' : type === 'checkbox' }">{{ item.label }}</span>
        <span *ngIf="item.description" class="text-muted">{{ item.description }}</span>
      </div>
    </label>
  </div>
</div>
