import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

function stringToSlug(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();
  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

@Component({
  selector: 'app-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectFilterComponent),
    multi: true
  }]
})
export class SelectFilterComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder = 'Elegir filtro';
  @Input() disabled: boolean = false;
  @Input() options: any;
  @Input() value: any = null;
  @Input() id: string | undefined;
  @Output() changeFilter = new EventEmitter();

  selected: any = null;
  isDisabled: boolean;
  onChange = (_: any) => { };
  onTouch = () => { };

  constructor() { }

  ngOnInit() {
    this.id = this.id === undefined ?
      'selectFilter' + Math.floor(Math.random() * 10000) :
      this.id;
    if ( this.value && this.options && this.options.indexOf( this.value ) !== -1 ) {
      this.selected = this.value;
    }
    this.isDisabled = this.disabled;
  }

  clean() {
    this.selected = null;
    this.changeFilter.emit( this.selected );
  }

  selectOption(option) {
    this.selected = option;
    this.changeFilter.emit( this.selected );
  }

  onInput(value: string) {
    this.selected = (value === '' ? null : value);
    this.onTouch();
    this.onChange(this.selected);
  }

  writeValue(value: any): void {
    if (value) {
      this.selected = (value === '' ? null : value) || null;
    } else {
      this.selected = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  getOptionId(option: any) {
    if (typeof option === 'number') {
      option = option.toString();
    }
    if (typeof option === 'undefined' || option === null) {
      option = '';
    }
    return this.id + stringToSlug(option);
  }

}
