import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js/min';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(phoneValue: number | string, country: string): any {
    try {
      const phoneNumber = parsePhoneNumber(phoneValue + '', country as CountryCode);
      if ( country === 'CL' && phoneNumber.isValid() ) {
        return phoneNumber.formatInternational();
      }
      return phoneNumber.formatNational();
      // return new AsYouType(country as CountryCode).input(phoneValue);
    } catch (error) {
      return phoneValue;
    }
  }

}
