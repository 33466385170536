import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

const BASE_URL = env.baseUrl;

const routes = {
  index: '/index'
};

@Injectable({
  providedIn: 'root'
})
export class IndexService {

  constructor(private http: HttpClient) { }

  accessControl(token: any, service: string): Promise<any> {
    const tokenCiam = {
      token,
      service
    };
    return this.http.post(BASE_URL + routes.index, tokenCiam).toPromise();
  }

}
