import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { DataService } from 'src/app/core/services/data.service';
import { OrderService } from '../../../../../../../core/services/orders/order.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MealOrderService } from 'src/app/core/services/meal-order/meal-order.service';

@Component({
  selector: 'app-confirm-leave',
  templateUrl: './confirm-leave.component.html',
  styleUrls: ['./confirm-leave.component.scss']
})
export class ConfirmLeaveComponent {

  subject: Subject<boolean>;
  @Input() public totalDraftOrders: any;
  @Input() public currentStep: number;
  @Input() public formComponent: any;

  constructor(
    public bsModalRef: BsModalRef,
    public dataService: DataService,
    public orderService: OrderService,
    private spinner: NgxSpinnerService,
    private mealOrderService: MealOrderService,
    private router: Router) { }

  saveDrafOrder() {
    if (this.formComponent.currentStep === 3 || this.formComponent.currentStep === 4 || this.formComponent.currentStep === 5) {
      if (this.formComponent.subOrder.branchOfficesSelected !== undefined
        && this.formComponent.subOrder.branchOfficesSelected.length !== 0) {
        this.formComponent.setDeliveryDates(false);
      }
    }
    this.bsModalRef.hide();
    this.subject.next(true);
    this.subject.complete();
  }


  discardOrder() {
    this.spinner.show();
    this.bsModalRef.hide();
    this.mealOrderService.cancelMealOrder(
      this.dataService.getFiscalIdSelected(),
      this.dataService.getOrderId()).subscribe(data => {
        if (data) {
          // aqui deberia ir algun tipo de notificación al usuario
          console.log('pedido anulado correctamente ', data);
          this.subject.next(true);
          this.subject.complete();
        }
      }, error => {
        console.error('Error al anular pedido: ', error);
        Swal.fire('Ha ocurrido un error en el proceso', 'Por favor intenta nuevamente.', 'error')
          .then((result) => {
            this.router.navigateByUrl('/alimentacion/pedidos');
          });
        this.spinner.hide();
        this.subject.next(true);
        this.subject.complete();
      });
  }

  cancelOrder() {
    this.bsModalRef.hide();
    this.subject.next(false);
    this.subject.complete();
  }

}
