import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { DataService } from '../data.service';
import { HttpParams } from '@angular/common/http';
import { CompanyHasUser } from 'src/app/core/model/profiles/companyHasUser.model';

const routes = {
  getRolesByCompanyFiscalId: '/profiles/company',
  getRolesByServiceIdAndPlatformId: '/profiles/roles',
  getModulesByFiscalIdAndCompanyFiscalId: '/profiles/modules',
  profile: '/profiles',
  dynamicMenu: '/profiles/modules',
  moduleValidate: '/profiles/validate/modules',
  updateCiam: '/profiles/ciam-update',
  createProfile: () =>'/profiles',
};

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {

  constructor(private apiService: ApiService, private dataService: DataService) { }

  getUserProfile(companyFiscalId, beneficiaryFiscalId, serviceId, platformId = 1) {
    let params = new HttpParams();

    if (companyFiscalId && companyFiscalId !== null) {
      params = params.set('companyFiscalId', companyFiscalId.toString());
    }

    if (beneficiaryFiscalId && beneficiaryFiscalId !== null) {
      params = params.set('beneficiaryFiscalId', beneficiaryFiscalId.toString());
    }

    if (serviceId && serviceId !== null) {
      params = params.set('serviceId', serviceId.toString());
    }

    if (platformId && platformId !== null) {
      params = params.set('platformId', platformId.toString());
    }

    return this.apiService.get(routes.getRolesByCompanyFiscalId, params);
  }

  getRolesByServiceIdAndPlatformId(serviceId, platformId = 1) {
    let params = new HttpParams();

    if (serviceId && serviceId !== null) {
      params = params.set('serviceId', serviceId.toString());
    }

    if (platformId && platformId !== null) {
      params = params.set('platformId', platformId.toString());
    }

    return this.apiService.get(routes.getRolesByServiceIdAndPlatformId, params);
  }

  getModulesByFiscalIdAndCompanyFiscalId(fiscalId, companyFiscalId) {
    let params = new HttpParams();

    if (fiscalId && fiscalId !== null) {
      params = params.set('fiscalId', fiscalId.toString());
    }

    if (companyFiscalId && companyFiscalId !== null) {
      params = params.set('companyFiscalId', companyFiscalId.toString());
    }

    return this.apiService.get(routes.getModulesByFiscalIdAndCompanyFiscalId, params);
  }

  updateRole(data: any) {
    return this.apiService.put(routes.profile, data);
  }

  getDynamicMenu(fiscalId: string , companyFiscalId: string) {
    let params = new HttpParams();

    if (fiscalId && fiscalId !== null) {
      params = params.set('fiscalId', fiscalId.toString());
    }

    if (companyFiscalId && companyFiscalId !== null) {
      params = params.set('companyFiscalId', companyFiscalId.toString());
    }

    return this.apiService.get(routes.dynamicMenu, params);
  }

  getModuleValidate(roleId: string , path: string) {

    let params = new HttpParams();

    if (roleId && roleId !== null) {
      params = params.set('roleId', roleId.toString());
    }

    if (path && path !== null) {
      params = params.set('path', path.toString());
    }

    return this.apiService.get(routes.moduleValidate, params);

  }


  updateCiam() {
    return this.apiService.put(routes.updateCiam);
  }
  
  createProfile(companyHasUser: CompanyHasUser) {
    return this.apiService.post(routes.createProfile(), companyHasUser);
}
}
