<nav class="navbar navbar-expand-lg navbar-light  nav-dash ">

    <div class="navbar-brand nav-logo nopadding">
        <img src="assets/images/sodexo_white.png" class=" nav-logo-img" alt="Sodexo">
    </div>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="nav-menu collapse navbar-collapse" id="navbarSupportedContent">
        <div class="col-12 col-md-8 nav-executive" >
            <div class="nav-field">Tu Ejecutivo: José Sanhueza </div>
            <div class="nav-field">+56 9 4433829281</div>
            <div class="nav-field">jsanhueza@sodexo.com </div>
        </div>

        <div class="col-12 col-md-4 nav-links ">
            <div class="nav-field nav-links-a">Centro de ayuda</div>
            <div class="nav-field nav-links-a"><a href="#" class="nav-field__logout">Cerrar sesión</a></div>
        </div>
    </div>
</nav>
