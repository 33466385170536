<!-- <div *ngIf="!token" class="app-home">
  <app-header (ngOnLogin)="login()" (ngOnSignUp)="signUp()"><meta name="description"></app-header>
  <section class="hero-home">
    <div class="container-fluid">
      <h1 class="hero-home__subtitle">Bienvenido a la plataforma de Clientes Sodexo</h1>
      <h2 class="hero-home__title">Servicios que mejoran tu día a día.</h2>
      <ul class="hero-home__links">
        <li><a href="javascript:void(0)" class="btn btn-lg btn-home-primary" (click)="login()">Iniciar sesión</a></li>
        <li><a href="javascript:void(0)" class="btn btn-lg btn-home-outline-primary" (click)="signUp()">Regístrate</a>
        </li>
      </ul>
    </div>
  </section>
  <section class="discover-home">
    <div class="container-fluid">
      <a href="#services-home" class="discover-home__link">
        <span class="icon-home-chevron-down"></span>
        <span class="sr-only">Continuar leyendo</span>
      </a>
      <h2 class="discover-home__title">Tu calidad de vida nos importa</h2>
      <p class="discover-home__text">Descubre los servicios que tenemos para ofrecer</p>
    </div>
  </section>
  <section class="services-home" id="services-home">
    <section class="service-home service-home--tarjeta-restaurant">
      <div class="div service-home__body">
        <h2 class="service-home__title">Tarjeta Restaurant</h2>
        <p class="service-home__description">Una solución moderna para la entrega del beneficio de alimentación de sus
          colaboradores que le permite ahorrar hasta un 38,6%</p>
        <a href="https://www.sodexo.cl/nuestros-productos/alimentacion/cheque-restaurant-tarjeta/"
          class="service-home__link">Conoce más de Tarjeta Restaurant</a>
      </div>
    </section>
    <section class="service-home service-home--tarjeta-junaeb">
      <div class="div service-home__body">
        <h2 class="service-home__title">Tarjeta JUNAEB</h2>
        <p class="service-home__description">La Beca de Alimentación para la Educación Superior consiste en un subsidio
          entregado por JUNAEB y operado por Sodexo.</p>
        <a href="https://www.sodexo.cl/nuestros-productos/instituciones-publicas/beca-junaeb/"
          class="service-home__link">Conoce más de Tarjeta JUNAEB</a>
      </div>
    </section>
    <section class="service-home service-home--beneficios">
      <div class="div service-home__body">
        <h2 class="service-home__title">Plataforma de Beneficios</h2>
        <p class="service-home__description">Plataforma de autogestión para la compra de productos a través del Convenio Marco de Alimentación y beneficios.</p>
        <a href="https://www.sodexo.cl/incentivos-reconocimiento/" class="service-home__link">Conoce más de
          Beneficios</a>
      </div>
    </section>
    <section class="service-home service-home--sercotec">
      <div class="div service-home__body">
        <h2 class="service-home__title">Marketplace Sercotec</h2>
        <p class="service-home__description">Marketplace para compra de productos y servicios para los beneficiarios de los proyectos Sercotec.</p>
        <a href="https://www.sodexo.cl/nuestros-productos/instituciones-publicas/sercotec/" class="service-home__link">Conoce más de este Marketplace.</a>
      </div>
    </section>
  </section>
  <section class="contact-home">
    <div class="container-fluid">
      <h2 class="contact-home__title">¿Quieres más información?</h2>
      <a href="https://www.sodexo.cl/escribanos/" class="btn btn-lg btn-home-primary">Comunícate con nosotros</a>
    </div>
  </section>
  <div class="home-illustration"></div>
  <app-footer></app-footer>
</div> -->
<div class="app-home">
  <div class="container-fluid">
    <div class="row" style="height: 100vh">
      <div class="col col-md-7 col-lg-6 col-xl-5 m-auto">
        <h1 class="h3 mb-4"><span class="h4 font-weight-light d-block mb-0">Bienvenidos a la plataforma</span>Pluxee Beneficios Públicos</h1>
        <form *ngIf="loginForm" (submit)="fakeLogin()" [formGroup]="loginForm" class="pt-1">
          <p *ngIf="error" class="text-danger mb-4">
            Los datos son incorrectos
          </p>
          <div class="form-group">
            <label for="loginEmail">Correo electrónico</label>
            <input type="email" class="form-control" id="loginEmail" aria-describedby="loginEmail" formControlName="email">
          </div>
          <div class="form-group pb-1">
            <label for="loginPassword">Contraseña</label>
            <input type="password" class="form-control" id="loginPassword" formControlName="password">
          </div>
          <div class="text-center my-4">
            <button type="submit" class="btn btn-primary">Ingresar</button>
          </div>
          <!-- <hr> -->
          <!-- <a href="javascript:void()" (click)="login()">Ingresar con API</a> -->
        </form>
      </div>
    </div>
  </div>
</div>
