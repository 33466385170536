import { Injectable } from '@angular/core';
import { CaseSalesForce } from '../../model/salesForce/case.model';
import { ApiService } from '../api/api.service';

const routes = {
  createCase: '/sales-force/case',
};

@Injectable({
  providedIn: 'root'
})
export class SalesForceService {

  constructor(private apiService: ApiService) { }

  createCase(data: CaseSalesForce) {
    return this.apiService.post(routes.createCase, data);
  }
}
