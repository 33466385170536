export class Beneficiary {
  legalId: string;
  name: string;
  lastName: string;
  secondLastName: string;
  state: number;
  branchOffice: string;
  costCenter: string;
  email: string;
  // Nuevo pedido
  dailyAmount: number;
  daysOfUse: number;
  selected: boolean;
  totalLoaded: number;
  // Beneficiarios
  cardNumber: string;
  cardStatus: string;
  cardAmount: number;
  cardLockTypeId: number;
  distribute: number;
  isNewBeneficiary: boolean;
  desactive: boolean;
  cardStateId: number;
  fullNameJunaeb: string;
  branchId : number;

  public constructor(init?: Partial<Beneficiary>) {
    Object.assign(this, init);
  }

  get fullName() {
    return [this.name, this.lastName, this.secondLastName].join(' ');
  }
}
