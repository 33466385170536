<div class="app-dashboard">
  <!-- Header -->
  <app-header-dashboard></app-header-dashboard>

  <!-- Navegación -->
  <app-navigation-menu></app-navigation-menu>

  <!-- Contenido -->
  <main class="app-main" role="main">
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>

  <!-- Footer -->
  <app-footer-dashboard></app-footer-dashboard>
</div>
