<div ngbDropdown class="dropdown-date" autoClose="outside" placement="placement" display="static">
	<button ngbDropdownToggle type="button" class="btn btn-outline-secondary" [ngClass]="{'dropdown-toggle--filled': fromDate || toDate}" aria-haspopup="true" aria-expanded="false">
		<span *ngIf="!fromDate && !toDate">
			Fecha <app-icon icon="chevron-down"></app-icon>
		</span>
		<span *ngIf="fromDate && !toDate">
			{{ fromDate.day }}/{{ fromDate.month }}/{{ fromDate.year }} -
		</span>
		<span *ngIf="fromDate && toDate">
			{{ fromDate.day }}/{{ fromDate.month }}/{{ fromDate.year }} -
			{{ toDate.day }}/{{ toDate.month }}/{{ toDate.year }}
		</span>
		<span class="dropdown__reset" (click)="resetDate()" *ngIf="fromDate || toDate">
			<span class="sr-only">Limpiar fechas</span>
			<app-icon icon="close"></app-icon>
		</span>
	</button>
	<div ngbDropdownMenu>
		<app-select-date
			displayMonths="2"
			outsideDays="hidden"
			(selectDateOnClick)="onDateSelect($event)"
			[isRanged]="true"
			[clean]="cleanSubject.asObservable()"
		></app-select-date>
	</div>
</div>
