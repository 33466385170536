<header class="app-header navbar fixed-top navbar-light navbar-expand-md">
  <div class="container-fluid">
    <span class="navbar-logo">
      <img src="assets/images/plx-transitionLogo.svg" class="logo-transition" alt="Sodexo > Pluxee, Beneficios e Incentivos">
    </span>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#toggler" aria-controls="toggler"
      aria-expanded="false" aria-label="Desplegar menú">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="toggler">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" (click)="login()">Iniciar sesión</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" (click)="signUp()" tabindex="-1"
            aria-disabled="true">Regístrate</a>
        </li>
      </ul>
    </div>
  </div>
</header>
